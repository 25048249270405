import { Colors, DataType, EnergyTypes } from "src/system/constants";

export const getColors = (type: DataType): string => {
  if (type === DataType.ENERGY || type === DataType.VOL) {
    return Colors.skyBlue;
  }
  return "";
};

export const getLineVisibility = (type: DataType): string => {
  if (type === DataType.ENERGY || type === DataType.VOL) {
    return EnergyTypes.VOL;
  }
  return "";
};

export const getYAxisVisibility = (type: DataType): string => {
  if (type === DataType.ENERGY || type === DataType.VOL) {
    return EnergyTypes.VOL;
  }
  return "";
};
