import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material";
import HeaderBeta from "./HeaderBeta";
import HeaderSelectionIndicator from "../components/HeaderSelectionIndicator";
import { Colors } from "src/system/constants";

interface HeaderNavigationItemProps {
  to: string;
  isEnabled?: boolean;
  inBeta?: boolean;
  children?: React.ReactNode;
}

const HeaderNavigationItem: React.FC<HeaderNavigationItemProps> = ({
  to,
  isEnabled,
  inBeta,
  children,
}) => {
  const beta = inBeta ?? false;
  const enabled = beta ? false : (isEnabled ?? true);

  return (
    <StyledNavLink to={to} enabled={enabled.toString()}>
      {children}
      <HeaderBeta inBeta={beta} />
      <HeaderSelectionIndicator />
    </StyledNavLink>
  );
};

const StyledNavLink = styled(NavLink)<{ enabled: string }>(({ enabled }) => ({
  display: "flex",
  padding: "9px 0px 8px 25px",
  margin: 0,
  alignItems: "center",
  color: enabled === "true" ? Colors.dimGray : Colors.silver,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "18.75px",
  pointerEvents: enabled === "true" ? "all" : "none",
  "svg *": {
    stroke: enabled === "true" ? Colors.dimGray : Colors.silver,
  },
  "&:hover, &.active": {
    color: Colors.skyBlue,
    background:
      "linear-gradient(90deg, rgba(133, 199, 222, 0.2) 0%, rgba(244, 248, 255, 0) 132.08%)",
    ".MuiBox-root": {
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
  "&.active": {
    "svg *": {
      stroke: Colors.skyBlue,
      strokeWidth: 1.5,
    },
  },
  span: {
    lineHeight: 0,
    marginLeft: 20,
    marginRight: 20,
  },
}));

export default HeaderNavigationItem;
