import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import ConnectedStationTitle from "./ConnectedStationTitle";
import StationsCharts from "./StationsChart";
import {DataType, TIMESTAMP_ONE_MINUTE} from "src/system/constants";

const ConnectedStationsVoltage = () => {
  const [date] = React.useState<Dayjs | null>(dayjs);

  const [updateKey, setUpdateKey] = useState(0);
  useEffect(() => {
    const interval = setInterval(
      () => setUpdateKey((x) => x + 1),
      TIMESTAMP_ONE_MINUTE
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ConnectedStationTitle name="Voltage" />
      <StationsCharts date={date} key={updateKey} stations={DataType.VOL} />
    </>
  );
};

export default ConnectedStationsVoltage;
