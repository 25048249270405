import EcoFlowChargerBattery100 from "./EcoFlowChargerBattery100";
import EcoFlowChargerBattery0 from "./EcoFlowChargerBattery0";
import EcoFlowChargerBattery60 from "./EcoFlowChargerBattery60";
import EcoFlowChargerBattery30 from "./EcoFlowChargerBattery30";
import {
  BATTERY_AVERAGE_LEVEL_MAX_VALUE,
  BATTERY_AVERAGE_LEVEL_MIN_VALUE,
  BATTERY_HIGH_LEVEL_MAX_VALUE,
  BATTERY_HIGH_LEVEL_MIN_VALUE,
  BATTERY_LOW_LEVEL_MAX_VALUE,
  BATTERY_LOW_LEVEL_MIN_VALUE,
} from "../../../system/constants";

interface OpenBatteryProps {
  lastTimestampEnergy?: number | null;
}

const OpenBattery: React.FC<OpenBatteryProps> = ({ lastTimestampEnergy }) => {
  if (
    !!lastTimestampEnergy &&
    lastTimestampEnergy <= BATTERY_HIGH_LEVEL_MAX_VALUE &&
    lastTimestampEnergy >= BATTERY_HIGH_LEVEL_MIN_VALUE
  ) {
    return <EcoFlowChargerBattery100 />;
  } else if (
    !!lastTimestampEnergy &&
    lastTimestampEnergy < BATTERY_AVERAGE_LEVEL_MAX_VALUE &&
    lastTimestampEnergy >= BATTERY_AVERAGE_LEVEL_MIN_VALUE
  ) {
    return <EcoFlowChargerBattery60 />;
  } else if (
    !!lastTimestampEnergy &&
    lastTimestampEnergy < BATTERY_LOW_LEVEL_MAX_VALUE &&
    lastTimestampEnergy >= BATTERY_LOW_LEVEL_MIN_VALUE
  ) {
    return <EcoFlowChargerBattery30 />;
  }
  return <EcoFlowChargerBattery0 />;
};
export default OpenBattery;
