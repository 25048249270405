import { Box, styled } from "@mui/material";
import celsiusToFahrenheit from "../../../utils/CelcToFahr";
import ZoneResultDataItem from "../../Dashboard/components/ZoneResultDataItem";
import { NO_DATA_VALUE, TooltipTitleMessages } from "../../../system/constants";
import { DataTypeEnum } from "../../../types/dashboardTypes";

interface ZoneDataValueProps {
  type?: string;
  humidityData: string | number;
  dataVoltage: string;
  temperatureData: string | number;
  pressureData: string;
  radonData: string | number;
}

const ZoneDataValue: React.FC<ZoneDataValueProps> = ({
  type,
  humidityData,
  dataVoltage,
  temperatureData,
  pressureData,
  radonData,
}) => {
  if (type === DataTypeEnum.HUMIDITY) {
    return (
      <ZoneResultDataItem
        value={humidityData}
        title={TooltipTitleMessages.HUMIDITY_TOOLTIP_TITLE}
        suffix="%"
        testid="humidity-data"
      />
    );
  } else if (type === DataTypeEnum.ENERGY) {
    return (
      <ZoneResultDataItem
        value={dataVoltage}
        title={TooltipTitleMessages.HUMIDITY_TOOLTIP_TITLE}
        suffix="W"
        testid="energy-data"
      />
    );
  } else if (type === DataTypeEnum.TEMPERATURE) {
    const celsiusValue = !isNaN(+temperatureData)
      ? `${temperatureData} °C`
      : `${NO_DATA_VALUE} °C`;

    const fahrenheitValue = !isNaN(+temperatureData)
      ? `${celsiusToFahrenheit(Number(temperatureData))} °F`
      : `${NO_DATA_VALUE} °F`;

    return (
      <>
        <ZoneResultDataItem
          value={celsiusValue}
          title={TooltipTitleMessages.CELSIUS_TEMP_TOOLTIP_TITLE}
          suffix=""
          testid="temperature-data-celsius"
        />
        <ZoneResultDataItem
          value={fahrenheitValue}
          title={TooltipTitleMessages.FAHRENHEIT_TEMP_TOOLTIP_TITLE}
          suffix=""
          testid="temperature-data-fahrenheit"
        />
      </>
    );
  } else if (type === DataTypeEnum.PRESSURE) {
    return (
      <ZoneResultDataItem
        value={pressureData}
        title={TooltipTitleMessages.PRESSURE_TOOLTIP_TITLE}
        suffix="mbar"
        testid="pressure-data"
      />
    );
  } else if (type === DataTypeEnum.RADON) {
    return (
      <ZoneResultDataItem
        value={radonData}
        title={TooltipTitleMessages.RADON_TOOLTIP_TITLE}
        suffix="Bq/m³"
        testid="radon-data"
      />
    );
  } else {
    return null;
  }
};

const ZoneResultData = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 8,
  position: "relative",
  span: {
    position: "absolute",
    top: -10,
    right: -25,
    cursor: "pointer",
    svg: {
      fontSize: 16,
      path: {
        fill: "white !important",
      },
    },
  },
});

export default ZoneDataValue;
