import React, { useMemo, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import "react-calendar/dist/Calendar.css";
import { Box, styled } from "@mui/material";
import {
  Colors,
  DEFAULT_FORMAT_DAYS,
  DEFAULT_FORMAT_DAY_CALENDAR_FORMAT,
  DEFAULT_FORMAT_MONTH,
  DEFAULT_FORMAT_WEEEK,
  DEFAULT_FORMAT_DAY_SHORT,
  DEFAULT_FORMAT_YEAR,
} from "src/system/constants";
import DateRangePicker, {
  CalendarMode,
} from "../../../components/DateRangePicker";
import useOnClickOutside from "../../../utils/hooks/useOnClickOutside";
import CalendarResult from "./CalendarResult";

dayjs.Ls.en.weekStart = 1;

const CalendarData: React.FC<{
  date: Dayjs | null;
  setDate: (
    value: Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => void;
  mode?: CalendarMode;
}> = ({ date, setDate, mode }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);
  const switchPrev = () => {
    if (date) {
      setDate(date.subtract(1, mode ?? DEFAULT_FORMAT_DAYS));
    }
  };
  const switchNext = () => {
    if (date) {
      setDate(date.add(1, mode ?? DEFAULT_FORMAT_DAYS));
    }
  };

  const handleChangeDate = (
    _value: [Date | null | undefined, Date | null | undefined] | null
  ) => {
    setShow(false);
    if (_value) {
      if (mode === CalendarMode.DAY) {
        setDate(dayjs(_value[0]));
      } else if (mode === CalendarMode.WEEK) {
        setDate(dayjs(_value[0]).startOf("week"));
      } else if (mode === CalendarMode.MONTH) {
        setDate(dayjs(_value[0]).startOf("month"));
      }
    }
  };

  const values = useMemo(():
    | [Date | null | undefined, Date | null | undefined]
    | null => {
    if (mode === CalendarMode.DAY) {
      return [date?.toDate(), null];
    } else if (mode === CalendarMode.WEEK) {
      return [date?.startOf("week").toDate(), date?.endOf("week").toDate()];
    } else if (mode === CalendarMode.MONTH) {
      return [date?.startOf("month").toDate(), date?.endOf("month").toDate()];
    }
    return null;
  }, [mode, date]);

  useOnClickOutside(ref, () => setShow(false));

  const dateStr = mode === CalendarMode.DAY
  ? date?.format(DEFAULT_FORMAT_DAY_CALENDAR_FORMAT)
  : mode === CalendarMode.WEEK
    ? `${
        date?.format(DEFAULT_FORMAT_MONTH)
      } ${
        date?.startOf(DEFAULT_FORMAT_WEEEK).format(DEFAULT_FORMAT_DAY_SHORT)
      } - ${
        date?.endOf(DEFAULT_FORMAT_WEEEK).format(DEFAULT_FORMAT_DAY_SHORT)
      }, ${
        date?.format(DEFAULT_FORMAT_YEAR)
      }`
    : date?.format("MMMM YYYY");

  return (
    <CalendarDayStyled>
      <Box>
        <Box className="wrapCalendar">
          <CalendarResult
            previousAction={switchPrev}
            nextAction={switchNext}
            clickAction={() => setShow(true)}
            dateStr={dateStr}
          />
        </Box>
      </Box>
      {show && (
        <CalendarWrap ref={ref}>
          <DateRangePicker
            onChange={handleChangeDate}
            value={values}
            isSingularCalendar
            locale={"en-GB"}
            mode={mode}
            disabledFeature
          />
        </CalendarWrap>
      )}
    </CalendarDayStyled>
  );
};
const CalendarWrap = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  width: "max-content",
  zIndex: 2,
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
  borderRadius: "8px",
});

const CalendarDayStyled = styled(Box)({
  position: "relative",
  ".react-calendar": {
    margin: "0 auto 15px",
    position: "relative",
    zIndex: 10,
    border: "none",
  },
  ".react-calendar__viewContainer": { display: "none" },
  ".react-calendar__navigation": {
    margin: "0",
    justifyContent: "center",
    alignItems: "center",
  },
  ".react-calendar__navigation__label": {
    fontSize: "18px",
    lineHeight: "20px",
    color: Colors.dimGray,
    fontWeight: 500,
    fontFamily: '"Roboto", Helvetica, sans-serif',
    background: "none !important",
  },
  ".react-calendar__navigation button": {
    minWidth: "auto",
    flexGrow: "0 !important",
    padding: "0 25px 0 25px",
  },
  ".react-calendar__navigation__prev2-button": { display: "none" },
  ".react-calendar__navigation__next2-button": { display: "none" },
  ".react-calendar__navigation__prev-button": {
    fontSize: "30px",
    lineHeight: "32px",
    fontWeight: "normal",
    color: Colors.grayDark,
    display: "flex",
    alignItems: "center",
    height: "20px",
    margin: "-5px 0 0 0 !important",
    background: "none !important",
  },
  ".react-calendar__navigation__next-button": {
    fontSize: "30px",
    lineHeight: "32px",
    fontWeight: "normal",
    color: Colors.grayDark,
    display: "flex",
    alignItems: "center",
    height: "20px",
    margin: "-5px 0 0 0 !important",
    background: "none !important",
  },
  ".wrapCalendar": {
    width: "300px",
    margin: "20px auto",
    backgroundColor: Colors.white,
  },
  ".wrapCalendar .MuiOutlinedInput-root input": {
    padding: "10px",
    borderRadius: "0",
  },
  ".wrapCalendar .MuiOutlinedInput-root:hover": { borderColor: Colors.skyBlue },
  ".calendarDay": {
    margin: "0",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: Colors.dimGray,
    minWidth: "184px",
    textAlign: "center",
  },
});

export default CalendarData;
