import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { Colors, RangeScaleAlignment } from "../system/constants";

const DEFAULT_BAR_WIDTH = 14;
const DEFAULT_BAR_HEIGHT = 135;
const DEFAULT_BOX_HEIGHT = 0;
const DEFAULT_VALUE = 0;

interface ProgressBarProps {
  height?: number;
  width?: number;
  value: number | null;
  alignment: RangeScaleAlignment;
  units: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  width,
  height,
  value,
  alignment,
  units
}) => (
  <ScaleBox width={width ?? DEFAULT_BAR_WIDTH} height={height ?? DEFAULT_BAR_HEIGHT}>
    <FillBox value={value ?? DEFAULT_BOX_HEIGHT} width={width ?? DEFAULT_BAR_WIDTH}>
      <StyledTypography alignment={alignment} hasValue={!!value}>
        {value ?? DEFAULT_VALUE}{units}
      </StyledTypography>
    </FillBox>
  </ScaleBox>
);

const ScaleBox = styled(Box)<{
  width: number,
  height: number
}>(({
  width,
  height
}) => ({
  width: `${width}px`,
  height: `${height}px`,
  background: Colors.lightWhite,
  borderRadius: "4px",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",
}));

const FillBox = styled(Box)<{
  width: number,
  value: number
}>(({
  width,
  value
}) => ({
  position: "relative",
  width: `${width}px`,
  height: `${value}%`,
  borderRadius: "4px",
  background: "linear-gradient(0deg, #69AEC6 0%, #A0C4E2 100%)",
}));

const StyledTypography = styled(Typography)<{
  alignment: RangeScaleAlignment,
  hasValue: boolean
}>(({
  alignment,
  hasValue
}) => ({
  color: hasValue ? Colors.lightBlue2 : Colors.silver,
  textAlign: alignment === RangeScaleAlignment.LEFT ? "right" : "left",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  position: "absolute",
  top: "-6px",
  right: alignment === RangeScaleAlignment.LEFT ? "20px" : "unset",
  left: alignment === RangeScaleAlignment.RIGHT ? "20px" : "unset",
}));

export default ProgressBar;
