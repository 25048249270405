import useGetStatusData from "src/store/useGetStatusData";
import { Box, styled, List, ListItem, Typography } from "@mui/material";
import {
  Colors,
  DeviceStatus,
  SensorStatus,
  DEVICE_IDS,
  DEFAULT_DEVICE_ID,
  HOURS_TO_SUBTRACT,
  HOUR,
  MINUTE,
} from "src/system/constants";
import {
  Datatype,
  useGetDataEcoflowQuery,
  useGetProducedConsumedEnergyQuery,
} from "../../../state/reducers/notificationsApi";
import dayjs from "dayjs";
import { getEnergyParameters } from "../../../utils/getEnergyParameters";

const EnergySource = () => {
  const { data: ProducedConsumedEnergy } = useGetProducedConsumedEnergyQuery({
    device_id: DEFAULT_DEVICE_ID,
  });
  const { data: dataEcoflowInWatts } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.MaximumPowerPointTracking,
    lower_bound: dayjs()
      .subtract(HOURS_TO_SUBTRACT, HOUR)
      .startOf(MINUTE)
      .unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const producedPower = Number(
    (ProducedConsumedEnergy?.produced_power ?? 0).toFixed(2)
  );

  const lastWattsData = dataEcoflowInWatts?.[dataEcoflowInWatts?.length - 1];
  const inWatts = Number((lastWattsData?.in_watts ?? 0).toFixed(2));

  const inVol = Number((lastWattsData?.in_vol ?? 0).toFixed(2));

  const allDevices = useGetStatusData();

  const currentDevice = allDevices?.data?.device?.find(
    (deviceToFind: { id: string }) => deviceToFind.id === DEVICE_IDS.SOLAR_PANEL
  );

  const status =
    allDevices.data.device?.[2]?.device_status === DeviceStatus.ON &&
    !!dataEcoflowInWatts?.length;
  const launchDate = currentDevice?.connection_date;
  const model = currentDevice?.model;

  const energyParameters = getEnergyParameters(
    status,
    inWatts,
    inVol,
    launchDate,
    producedPower
  );

  return (
    <EnergySourceBLock className="device-block">
      <EnergySourceTitle>Energy Source</EnergySourceTitle>
      <EnergyModel>Model: {model}</EnergyModel>
      <EnergySourceList>
        <List className="energy_parametrs">
          {energyParameters.map((item) => (
            <StyledListItem key={item.id}>
              <EnergySourceListItemName>{item.name}</EnergySourceListItemName>
              <ItemValueTypography style={{ color: item.color }}>
                {item.value}
              </ItemValueTypography>
              {item.picture}
            </StyledListItem>
          ))}
        </List>
      </EnergySourceList>
    </EnergySourceBLock>
  );
};

const StyledListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "space-between !important",
});
const ItemValueTypography = styled(Typography)({
  textAlign: "right",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});
const EnergySourceBLock = styled(Box)(({ theme }) => ({
  boxShadow: `0 0 3px ${Colors.lightGray}`,
  [theme.breakpoints.down("lg")]: {
    padding: "25px 15px 15px 15px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const EnergySourceTitle = styled(Box)(({ theme }) => ({
  height: 21,
  color: Colors.lightBlack,
  fontWeight: 500,
  fontSize: 18,
}));

const EnergyModel = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: Colors.dimGray,
  margin: "8px 0 31px 0",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
}));

const EnergySourceList = styled(Box)(({ theme }) => ({
  paddingLeft: "0",
  padding: "0",
  margin: "0",
  listStyle: "none",
  [theme.breakpoints.down("lg")]: {
    "& ul.energy_energy_parametrs li": {
      fontSize: "12px",
    },
    "& ul.energy_energy_parametrs li b": {
      maxWidth: "60%",
    },
  },
  ".energy_energy_parametrs": {
    padding: "0",
  },
  ul: {
    padding: "0",
  },
  li: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: Colors.dimGray,
    display: "flex",
    paddingBottom: "16px",
    paddingTop: "0",
    paddingLeft: "0",
    paddingRight: "0",
    "& .MuiTypography-root": {
      "&::first-letter": {
        textTransform: "uppercase",
      },
    },
  },
  "li:last-child": { paddingBottom: "0" },
}));

const EnergySourceListItemName = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "50%",
  display: "block",
  margin: "0 10px 0 0",
  fontWeight: 500,
  [theme.breakpoints.down("md")]: {
    margin: "0",
  },
}));

export default EnergySource;
