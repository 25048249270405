import Calendar from "./CalendarRadon";
import { TabProps } from "../components/Switches";
import { Box, styled } from "@mui/material";
import Zone from "./Zone";
import { RadonPicIcon } from "src/assets/icons";

const MotionSensor: React.FC<TabProps> = (props: TabProps) => {
  return (
    <Box>
      <MotionBlockStyled>
        <Zone
          tab={props.tab}
          setTab={props.setTab}
          name="Radon"
          image={<RadonPicIcon />}
          type="radon"
        />
        <Calendar />
      </MotionBlockStyled>
    </Box>
  );
};

const MotionBlockStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export default MotionSensor;
