import { ParamTooltipTypes, SensorType } from "src/system/constants";
import { FunctionComponent, SVGProps } from "react";

export interface DeviceDataType {
  [key: string]: {
    link: string;
    image: string;
    dataTestId: string;
  };
}

export interface Co2Type {
  co2: number;
  voc: number;
}

export interface HumidityType {
  humidity: number;
}

export interface PressureType {
  pressure: number;
}

export interface RadonType {
  radon_st_avg: number;
}

export interface TemperatureType {
  temperature: number;
}

export interface ConnectedChartTooltipProps {
  payload?: {
    payload: {
      amp: number;
      car_watts: number;
      cfg_ac_enabled: number;
      cycles: number;
      input_watts: number;
      max_charge_soc: number;
      output_watts: number;
      panel_efficiency: number;
      remain_time: number;
      soc: number;
      temp: number;
      timestamp: number;
      type_c_watts: number;
      usb_1_watts: number;
      usb_2_watts: number;
      usb_3_watts: number;
      vol: number;
      watts_in_sum: number;
      in_watts: number;
      pressure: number;
      co2: number;
      voc: number;
      radon_st_avg: number;
      temperature: number;
      humidity: number;
      watts_out_sum: number;
      in_vol: number;
    };
  }[];
  tooltipColor: string;
  paramNameTooltip?:
    | "co2"
    | "voc"
    | "humidity"
    | "pressure"
    | "radon_st_avg"
    | "temperature"
    | "watts_in_sum"
    | "watts_out_sum"
    | "input_watts"
    | "in_watts"
    | "in_vol";
  unitsOfMeasurement?: string;
}

export interface HighestLowestValue {
  humidity?: number;
  co2?: number;
  temperature?: number;
  voc?: number;
  pressure?: number;
  radon_st_avg?: number;
}

export interface InternalDataType {
  highest_value: HighestLowestValue;
  lowest_value: HighestLowestValue;
}

export interface InternalDataTypeRoseMonth {
  first_week: {
    highest_value: HighestLowestValue;
    lowest_value: HighestLowestValue;
  };
  second_week: {
    highest_value: HighestLowestValue;
    lowest_value: HighestLowestValue;
  };
  third_week: {
    highest_value: HighestLowestValue;
    lowest_value: HighestLowestValue;
  };
  fourth_week: {
    highest_value: HighestLowestValue;
    lowest_value: HighestLowestValue;
  };
  fifth_week: {
    highest_value: HighestLowestValue;
    lowest_value: HighestLowestValue;
  };
}

export type RadarData = {
  chartType: string;
  timestamp?: number;
  co2?: number;
  temperature?: number;
  voc?: number;
  pressure?: number;
  radon_st_avg?: number;
  humidity?: number;
  value?: number;
};

export enum WeekKeys {
  FIRST_WEEK = "first_week",
  SECOND_WEEK = "second_week",
  THIRD_WEEK = "third_week",
  FOURTH_WEEK = "fourth_week",
  FIFTH_WEEK = "fifth_week",
}

export type RadarWeeklyData = {
  chartType: string;
  [WeekKeys.FIRST_WEEK]: number;
  [WeekKeys.SECOND_WEEK]: number;
  [WeekKeys.THIRD_WEEK]: number;
  [WeekKeys.FOURTH_WEEK]: number;
  [WeekKeys.FIFTH_WEEK]: number;
};

export interface AverageChartItem {
  name: ParamTooltipTypes;
  unit: string;
  value?: string;
}

export type ChartDataItemType = {
  imgComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
  sensor: SensorType;
  value: number;
  unit: string;
  key?: string;
};

export type Bounds = {
  greenBound: number;
  redBound: number;
  secondGreenBound?: number;
};
