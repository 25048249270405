import React from "react";
import { ChartProps } from "src/routes/ConnectedSensors/components/ConnectedPage";
import { Box, styled, Typography } from "@mui/material";
import { Colors, DEFAULT_Y_AXIS_DOMAIN_TEMPERATURE, ParamTooltipTypes, units, SensorChartGraphHeight } from "src/system/constants";
import ConnectedChart from "./ConnectedChart";

const ConnectedTemperature: React.FC<ChartProps> = ({ data }) => {
  return (
    <>
      <ChartBox>
        <Typography>
          Temperature <ChartBoxText>°C</ChartBoxText>
        </Typography>
      </ChartBox>
      <div className="connectedTemperature__block">
        <ConnectedChart
          averageChartItems = {[
            {name: ParamTooltipTypes.TEMPERATURE, unit: units.Temp}
          ]}
          chartType = {ParamTooltipTypes.TEMPERATURE}
          chartUnit = {units.Temp}
          chartDomain = {DEFAULT_Y_AXIS_DOMAIN_TEMPERATURE}
          graphHeight = {SensorChartGraphHeight.NORMAL}
          strokeColor = {Colors.brightGreen}
          areaColor = {Colors.brightGreen}
          grid
          yAxis
          spaceBeforeUnit = {false}
        />
      </div>
    </>
  );
};

const ChartBox = styled(Box)({
  padding: "0",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "& p": {
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: "20px",
  },
});

const ChartBoxText = styled(Box)({
  padding: "0 0 0 0",
  display: "inline-block",
  color: Colors.gray,
});

export default ConnectedTemperature;
