import { MyObject } from "../types/appTypes";

export const getLastExistingValueInArrayofObjects = (
  arr: MyObject[],
  fieldName: string
): number | null => {
  for (let i = arr?.length - 1; i >= 0; i--) {
    //@ts-ignore
    if (arr[i][fieldName] !== null) {
      //@ts-ignore
      return arr[i][fieldName];
    }
  }
  return null; // Return null if no existing value is found
};
