import { Box, styled } from "@mui/material";
import { Colors } from "src/system/constants";

interface DeviceItemColumnProps {
    title: string;
    value: string;
    color?: string
}

const DeviceItemColumn: React.FC<DeviceItemColumnProps> = ({
    title,
    value,
    color
}) => (
    <DeviceColumn>
        <DeviceTitle>{title}</DeviceTitle>
        <DeviceValue fontColor={color}>{value}</DeviceValue>
    </DeviceColumn>
);

const DeviceColumn = styled(Box)(({ theme }) => ({
    padding: "0",
    width: "20%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
}));

const DeviceTitle = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "14px",
  color: Colors.gray,
  [theme.breakpoints.down("lg")]: {
    minHeight: "28px",
  },
  [theme.breakpoints.down("md")]: {
    minHeight: "auto",
    width: "35%",
    marginRight: "10px",
  },
}));

const DeviceValue = styled(Box)<{ fontColor?: string }>(({ theme, fontColor = Colors.lightBlack }) => ({
    fontSize: "12px",
    lineHeight: "14px",
    color: fontColor,
    fontWeight: 500,
    padding: "17px 0 0 0",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      maxWidth: "100px",
    },
  }));

export default DeviceItemColumn;