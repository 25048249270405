import React from "react";
import { styled } from "@mui/material";
import { TabsListUnstyled, TabsListUnstyledProps } from "@mui/base";
import { Colors } from "src/system/constants";

export default function CustomTabList (props: TabsListUnstyledProps) {
    return <StyledTab {...props}></StyledTab>;
};

const StyledTab = styled(TabsListUnstyled)({
    width: "100%",
    padding: "20px 25px 0 25px",
    background: "none",
    backgroundColor: Colors.transparent,
    border: "none"
});