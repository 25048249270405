import dayjs from "dayjs";

const roundDate = (unix: number, format: string) => {
    const date = dayjs(unix * 1000);
    const mins = date.minute();
    let finalDate;
    if (mins < 30) finalDate = date.startOf("hour");
    else finalDate = date.endOf("hour").add(1, "seconds");

    return finalDate.format(format);
  };

  export default roundDate;