import { useEffect, useCallback } from "react";
import useTokenDataFromCallbackPage from "src/utils/useTokenDataFromCallbackPage";
import * as idTokenUtils from "src/utils/idTokenUtils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import querystring from "querystring";
import {
  DEFAULT_GRANT_TYPE,
  HeadersType,
  systemVariables,
} from "src/system/constants";
import { RoutesPath } from "../types/dashboardTypes";

const CallbackPageHandler = () => {
  const code = useTokenDataFromCallbackPage();
  const navigate = useNavigate();

  const handleCode = useCallback(
    async (code: string) => {
      try {
        const payload = querystring.stringify({
          grant_type: DEFAULT_GRANT_TYPE,
          code: code,
          client_id: systemVariables.cognitoClientId,
          redirect_uri: systemVariables.cognitoRedirectUrl,
        });

        const headers = {
          "Content-Type": HeadersType.APPLICATION_X_FORM,
          Accept: HeadersType.ACCEPT_APPLICATION_JSON,
        };
        const { data } = await axios.post(
          `${systemVariables.cognitoBaseUrl}/oauth2/token`,
          payload,
          {
            headers,
          }
        );

        if (data.id_token) {
          idTokenUtils.storeIdToken(data.id_token);
          if (data.refresh_token)
            idTokenUtils.storeRefreshToken(data.refresh_token);
          navigate(RoutesPath.DASHBOARD);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (code) {
      handleCode(code);
    }
  }, [code, handleCode]);

  return <>Callback code: {code}</>;
};

export default CallbackPageHandler;
