import React, { useState } from "react";
import {
  AreaChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
} from "recharts";
import roundDate from "src/utils/roundDate";
import dayjs, { Dayjs } from "dayjs";
import { Box, styled } from "@mui/material";
import SystemLoader from "../../../components/SystemLoader";
import {
  Colors,
  DEFAULT_TICK_FORMAT,
  DEFAULT_CONNECTED_STATIONS_POWER_TICK_COUNT,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN,
  DEFAULT_DEVICE_ID,
  DEFAULT_LOWER_BOUND,
  HOUR,
  MINUTE,
  HOURS_TO_SUBTRACT,
  UNIT_TYPE_DAY,
  MEGAWATT_DIVISOR,
  DEFAULT_CONNECTED_STATIONS_SOURCE_OF_CONSUMPTION_DOMAIN,
  TICK_INTERVAL_THREE_HOURS,
  DEFAULT_CONNECTED_STATIONS_WATT_DOMAIN,
  DEFAULT_CONNECTED_STATIONS_POWER_DOMAIN,
} from "src/system/constants";
import CustomTooltip from "./CustomTooltipPowerCharts";
import NoData from "../../../components/NoData";
import {
  Datatype,
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import { lineVisibilityOptions } from "../../../types/connectedStationType";
import getTicksOfInterval from "../../../utils/getTicksOfInterval";

const ConnectedStationsPowerCharts: React.FC<{
  date: Dayjs | null;
}> = ({ date }) => {
  const { data: DataPower, isLoading } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.Inverter,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const [lineVisibilityOptions] = useState<lineVisibilityOptions>({
    amp: true,
    Line2: true,
  });

  const dataForChart = DataPower?.map((dataItem) => ({
    inv_out_vol: dataItem.inv_out_vol,
    timestamp: dataItem.timestamp,
  })).filter((dataItem) => dataItem.inv_out_vol);

  const dataCount = dataForChart?.length;

  const ticks = getTicksOfInterval(
    DataPower?.[0]?.timestamp,
    DataPower?.[DataPower[0] ? DataPower.length - 1 : 0]?.timestamp,
    TICK_INTERVAL_THREE_HOURS
  );

  return (
    <>
      {isLoading && <SystemLoader />}
      {dataCount === 0 && <NoData />}
      {!isLoading && dataCount !== 0 && (
        <CurrentChart>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart
              data={dataForChart ?? []}
              margin={{
                top: 10,
                right: 0,
                left: -12,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorPOW" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={Colors.lightGreen}
                    stopOpacity={0.3}
                  />
                  <stop
                    offset="95%"
                    stopColor={Colors.lightGreen}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid
                stroke={Colors.brightGray}
                vertical={true}
                strokeDasharray="3"
              />
              <XAxis
                dataKey="timestamp"
                type="number"
                domain={["dataMin", "dataMax"]}
                stroke={Colors.lightWhite}
                strokeDasharray="3"
                tickMargin={10}
                tick={{
                  fontSize: 12,
                  fontWeight: 500,
                  fill: Colors.lightBlack,
                }}
                ticks={ticks}
                tickFormatter={(value: number, index: number) =>
                  roundDate(value, DEFAULT_TICK_FORMAT)
                }
              />
              <YAxis
                stroke={Colors.lightWhite}
                domain={DEFAULT_CONNECTED_STATIONS_POWER_DOMAIN}
                axisLine={false}
                tickLine={false}
                tickCount={DEFAULT_CONNECTED_STATIONS_POWER_TICK_COUNT}
                tickMargin={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN}
                tick={{
                  fontSize: 10,
                  fontWeight: 500,
                  fill: Colors.lightBlack,
                }}
                tickFormatter={(number) =>
                  `${(number / MEGAWATT_DIVISOR).toFixed(0)}V`
                }
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="inv_out_vol"
                stroke={
                  lineVisibilityOptions.amp ? Colors.lightGreen : "transparent"
                }
                fill="url(#colorPOW)"
                dot={{ stroke: "transparent" }}
                activeDot={{
                  r: 6,
                  stroke: Colors.lightGreen,
                  fill: "transparent",
                }}
                isAnimationActive={false}
                animationDuration={0}
              />
            </AreaChart>
          </ResponsiveContainer>
        </CurrentChart>
      )}
    </>
  );
};

const CurrentChart = styled(Box)({
  padding: "0",
});

export default ConnectedStationsPowerCharts;
