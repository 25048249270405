import ConnectedStationsPage from "src/routes/ConnectedStation/components/ConnectedStationsPage";

const ConnectedStations = () => {
  return (
    <>
      <div className="connected-sensors">
        <ConnectedStationsPage />
      </div>
    </>
  );
};

export default ConnectedStations;
