import { Typography } from "@mui/material";
import ConnectedPage from "src/routes/ConnectedSensors/components/ConnectedPage";

const ConnectedSensors = () => {
  return (
    <Typography className="connected-sensors">
      <ConnectedPage />
    </Typography>
  );
};

export default ConnectedSensors;
