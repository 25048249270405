import React from "react";
import { ChartItem } from "src/types/customTooltipType";
import dayjs from "dayjs";
import { Box, styled, Typography } from "@mui/material";
import { InOutVolType } from "../../../types/dashboardTypes";
import {
  DECIMAL_PLACES,
  MEGAWATT_DIVISOR,
  MILLISECONDS_TO_SECONDS,
  TIME_FORMAT,
} from "../../../system/constants";

const CustomTooltip: React.FC<ChartItem> = ({ payload }) => {
  if (!payload) {
    return null;
  }

  const timeTooltip = dayjs(
    payload?.[0]?.payload?.timestamp * MILLISECONDS_TO_SECONDS
  ).format(TIME_FORMAT);

  const inv_out_vol = (
    ((payload?.[0]?.payload as InOutVolType)?.inv_out_vol ?? 0) /
    MEGAWATT_DIVISOR
  ).toFixed(DECIMAL_PLACES);

  return (
    <CustomTooltipWrap className="custom-tooltip">
      <Typography className="time-tooltip">{timeTooltip}</Typography>
      <Typography className="param param-1">
        <span className="amp"></span>
        {inv_out_vol} V
      </Typography>
    </CustomTooltipWrap>
  );
};

const CustomTooltipWrap = styled(Box)({
  fontSize: "12px",
  fontWeight: "500",
  "& .param": {
    fontSize: "10px",
    fontWeight: "400",
    display: "flex",
    "& span": {
      width: "10px",
      height: "10px",
      top: "3px",
    },
  },
});

export default CustomTooltip;
