import React from "react";
import Tooltip from "@mui/material/Tooltip";

export interface IotTooltipProps {
  title: string;
  children: React.ReactNode;
}

const IotTooltip: React.FC<IotTooltipProps> = ({ title, children }) => {
  return (
    <Tooltip title={title}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default IotTooltip;
