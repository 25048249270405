import React from "react";
import { Box, styled } from "@mui/material";
import { Colors } from "src/system/constants";

const HeaderSelectionIndicator: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => <StyledBoxSelectionIndicator>{children}</StyledBoxSelectionIndicator>;

const StyledBoxSelectionIndicator = styled(Box)({
  position: "absolute",
  width: 3,
  height: 45,
  right: 0,
  backgroundColor: Colors.skyBlue,
  opacity: 0,
  transition: "opacity 0.2s linear",
});

export default HeaderSelectionIndicator;
