import React from "react";
import HeaderNavigationItem from "./components/HeaderNavigationItem";
import { useLocation } from "react-router-dom";
import { Box, ListItemText, ListItem, styled, Link } from "@mui/material";
import { LogoIcon } from "src/assets/icons";
import { DashboardIcon } from "src/assets/icons";
import { DeviceIcon } from "src/assets/icons";
import { SettingsIcon } from "src/assets/icons";
import { NotificationIcon } from "src/assets/icons";
import { Colors, DINAMICKA_DEVELOPMENT_URL } from "src/system/constants";
import { RoutesPath } from "../../types/dashboardTypes";

const Header: React.FC<{ mobileMode?: boolean; setClose?: () => void }> = ({
  mobileMode,
  setClose,
}) => {
  const location = useLocation();
  const isInsideConnectedSensors = location.pathname.startsWith(
    RoutesPath.CONNECTED_SENSORS
  );
  const isInsideConnectedStations = location.pathname.startsWith(
    RoutesPath.CONNECTED_STATIONS
  );

  const handleClickMenu = () => {
    setClose?.();
  };

  return (
    <HeaderSection>
      <HeaderLogo>
        {!mobileMode && (
          <Link href={RoutesPath.DASHBOARD}>
            <LogoIcon />
          </Link>
        )}
      </HeaderLogo>

      <HeaderList>
        <ListItem data-testid="tab-dashboard">
          <ListItemText onClick={handleClickMenu}>
            <HeaderNavigationItem to={RoutesPath.DASHBOARD}>
              <span>
                <DashboardIcon />
              </span>
              <NavigationItemLink>Dashboard</NavigationItemLink>
            </HeaderNavigationItem>
          </ListItemText>
        </ListItem>
        <ListItem
          className={
            isInsideConnectedSensors || isInsideConnectedStations
              ? "tab-active"
              : ""
          }
          data-testid="tab-devices"
        >
          <ListItemText onClick={handleClickMenu}>
            <HeaderNavigationItem to={RoutesPath.DEVICES}>
              <span>
                <DeviceIcon />
              </span>
              <NavigationItemLink>Devices</NavigationItemLink>
            </HeaderNavigationItem>
          </ListItemText>
        </ListItem>
      </HeaderList>
      <Account>
        <AccountTitle>Account</AccountTitle>
        <AccountList>
          <ListItem>
            <ListItemText onClick={handleClickMenu}>
              <HeaderNavigationItem to={RoutesPath.SETTINGS} inBeta={true}>
                <span>
                  <SettingsIcon />
                </span>
                <NavigationItemLink>Settings</NavigationItemLink>
              </HeaderNavigationItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText onClick={handleClickMenu}>
              <HeaderNavigationItem to={RoutesPath.NOTIFICATIONS}>
                <span>
                  <NotificationIcon />
                </span>
                <NavigationItemLink>Notifications</NavigationItemLink>
              </HeaderNavigationItem>
            </ListItemText>
          </ListItem>
        </AccountList>
      </Account>
      <CreatedBy>
        <Link href={DINAMICKA_DEVELOPMENT_URL} target="_blank">
          Created by
          <span>Dinamicka Development</span>
        </Link>
      </CreatedBy>
    </HeaderSection>
  );
};

const HeaderSection = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "0px",
  left: "0px",
  bottom: "0px",
  width: "240px",
  height: "100%",
  boxShadow: `0 0 3px ${Colors.lightGray}`,
  zIndex: "10",
  fontFamily: "Roboto",
  [theme.breakpoints.down("lg")]: {
    boxShadow: `none`,
    width: "304px",
  },
}));

const HeaderLogo = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "14px",
  textTransform: "uppercase",
  margin: "0 14px 0 14px",
  height: "63px",
  borderBottom: `2px solid ${Colors.lightSkyWhite}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    maxWidth: "100%",
    maxHeight: "64px",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "0 7px 0 7px",
    "& svg": {
      maxWidth: "100%",
    },
  },
}));

const HeaderList = styled(Box)(({ theme }) => ({
  padding: "41px 0 0 0",
  "& li": {
    padding: "0",
  },
  "& li.tab-active a": {
    background:
      "linear-gradient(90deg, rgba(133, 199, 222, 0.2) 0%, rgba(244, 248, 255, 0) 132.08%)",
    color: Colors.blue,
    "& svg *": {
      stroke: Colors.blue,
      strokeWidth: "1.5",
    },
  },
  "& li > .MuiListItemText-root": {
    margin: "0",
  },
  "& li a": {
    padding: "9px 0px 8px 25px",
  },
  "& li b": {
    fontWeight: "500",
  },
}));

const Account = styled(Box)(({ theme }) => ({
  padding: "28px 0 0 0",
}));

const AccountTitle = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "18px",
  color: Colors.dimGray,
  padding: "0 0 0 45px",
  fontWeight: "500",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));

const AccountList = styled(Box)(({ theme }) => ({
  padding: "13px 0 0 0",
  margin: "0",
  listStyle: "none",
  "& li": {
    padding: "0 0 12px 0",
  },
  "& li > .MuiListItemText-root": {
    margin: "0",
  },
  "& li a": {
    padding: "9px 0px 8px 25px",
  },
  "& li b": {
    fontWeight: "500",
  },
  "& span.newDeviceIcon": {
    marginLeft: "25px",
    marginRight: "30px",
  },
  "& .newDeviceText": {
    width: "65px",
  },
}));

const CreatedBy = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "24px",
  left: "50%",
  width: "162px",
  textAlign: "center",
  transform: "translate(-50%, 0)",
  color: Colors.gray,
  fontFamily: "sans-serif",
  "& a": {
    display: "block",
    textDecoration: "none",
    color: Colors.gray,
  },
  "& span": {
    display: "block",
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0 0 0 0",
    display: "none",
    "& span": {
      fontSize: "12px",
    },
  },
}));

const NavigationItemLink = styled(Box)(({ theme }) => ({
  fontWeight: "500",
}));

export default Header;
