export const storeIdToken = (idToken: string) => {
  localStorage.setItem("iot:id-token", idToken);
};

export const storeRefreshToken = (idToken: string) => {
  localStorage.setItem("iot:refresh-token", idToken);
};


export const readIdToken = () => localStorage.getItem("iot:id-token");

