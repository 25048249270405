import React, { useCallback, useState } from "react";
import {
  Box,
  Dialog,
  styled,
  Grid,
  TextField,
  Button,
  Menu,
  List,
  ListItem,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { DeviceEmail, Key, Close, ArrowIcon } from "src/assets/icons";
import { Colors, NAME_OPEN } from "src/system/constants";
import Transition from "./DeviceTransition";

const AUTOSELECT_ID = 0;

const ProfileDocumentsCreateNewPopup: React.FC<{
  open: boolean;
  setOpen: (_open: boolean) => void;
  title: string;
}> = ({ open, setOpen, title }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const [anchorElModel, setAnchorElModel] = React.useState<null | HTMLElement>(
    null
  );
  const [activeModel, setActiveModel] = useState(false);
  const [selectedModel, setSelectedModel] = useState(NAME_OPEN[AUTOSELECT_ID].name);

  const openModel = Boolean(anchorElModel);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElModel(event.currentTarget);
      setActiveModel(true);
    },
    [setActiveModel]
  );

  const handleCloseModel = useCallback(() => {
    setAnchorElModel(null);
    setActiveModel(false);
  }, [setAnchorElModel, setActiveModel]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={dialogContentStyle}>
        <DialogClose onClick={handleClose}>
          <Close />
        </DialogClose>
        <DialogContentText id="alert-dialog-slide-description">
          <DialogTitle>{title}</DialogTitle>
          <DialogBlockItems>
            <Grid container spacing={{ md: 3, xs: 1 }}>
              <Grid item sm={6} md={6} xs={6}>
                <DialogBlockItem>
                  <DialogBlockItemName>Serial Number</DialogBlockItemName>
                  <DialogBlockInput>
                    <TextField
                      sx={textFieldStyle}
                      label=""
                      placeholder="Type here"
                    />
                  </DialogBlockInput>
                </DialogBlockItem>
              </Grid>
              <Grid item sm={6} md={6} xs={6}>
                <DialogBlockItem>
                  <DialogBlockItemName>Model</DialogBlockItemName>
                  <Button
                    id="basic-button"
                    aria-controls={openModel ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Arrow className={activeModel ? "active-cls" : ""}>
                      <ArrowIcon />
                    </Arrow>
                    {selectedModel}
                  </Button>
                  <Menu
                    sx={menuStyles}
                    id="basic-menu"
                    anchorEl={anchorElModel}
                    open={openModel}
                    onClose={handleCloseModel}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <List onClick={handleCloseModel} aria-label="basic-list">
                      {NAME_OPEN?.map((item: { id: number; name: string }) => (
                        <ListItem
                          key={item.id}
                          onClick={() => setSelectedModel?.(item.name)}
                        >
                          {item.name}
                        </ListItem>
                      ))}
                    </List>
                  </Menu>
                </DialogBlockItem>
              </Grid>
              <Grid item sm={6} md={6} xs={6}>
                <DialogBlockItem>
                  <DialogBlockItemName>Email</DialogBlockItemName>
                  <DialogBlockInput>
                    <DialogBlockItemIcon>
                      <DeviceEmail />
                    </DialogBlockItemIcon>
                    <TextField
                      sx={textFieldStyleIcon}
                      label=""
                      placeholder="Email"
                    />
                  </DialogBlockInput>
                </DialogBlockItem>
              </Grid>
              <Grid item sm={6} md={6} xs={6}>
                <DialogBlockItem>
                  <DialogBlockItemName>Password</DialogBlockItemName>
                  <DialogBlockInput>
                    <DialogBlockItemIcon>
                      <Key />
                    </DialogBlockItemIcon>
                    <TextField
                      sx={textFieldStyleIcon}
                      label=""
                      placeholder="Password"
                    />
                  </DialogBlockInput>
                </DialogBlockItem>
              </Grid>
            </Grid>
          </DialogBlockItems>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={dialogActionsStyle}>
        <DialogButtons>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Send
          </Button>
        </DialogButtons>
      </DialogActions>
    </Dialog>
  );
};

const dialogActionsStyle = {
  width: "100%",
};

const textFieldStyleIcon = {
  width: "100%",
  "& input": {
    padding: "5px 10px 5px 32px",
  },
};

const textFieldStyle = {
  width: "100%",
};

const dialogContentStyle = {
  width: "100%",
  maxWidth: "592px !important",
  minWidth: "592px",
  padding: "30px 32px 32px 32px ",
  boxSizing: "border-box",
  borderRadius: "0px !important",
};

const DialogClose = styled(Box)({
  padding: "0",
  position: "absolute",
  top: "16px",
  right: "19px",
  cursor: "pointer",
});

const DialogTitle = styled(Box)({
  fontSize: "22px",
  lineHeight: "26px",
  fontWeight: "600",
  color: Colors.lightBlack,
});

const DialogBlockItems = styled(Box)({
  padding: "20px 0 0 0",
});

const DialogBlockItem = styled(Box)({
  height: "100%",
  margin: "0 0 -10px 0",
  "& .active-cls": {
    transform: "rotate(180deg)",
    top: "7px",
    right: "2px",
  },
  "& button": {
    fontSize: "14px",
    lineHeight: "17px",
    color: Colors.silver,
    justifyContent: "flex-start",
    textTransform: "none",
    padding: "7px 8px 6px 14px",
    borderRadius: "2px",
    width: "100%",
    border: `1px solid ${Colors.lightWhite}`,
    position: "relative",
    background: Colors.white,
  },
});

const DialogBlockItemName = styled(Box)({
  fontSize: "11px",
  lineHeight: "14px",
  fontWeight: "600",
  color: Colors.lightBlack,
  padding: "0 0 8px 0",
});

const DialogBlockInput = styled(Box)({
  padding: "0",
  margin: "0 0 0 0",
  width: "100%",
  borderRadius: "0",
  position: "relative",
  "& input": {
    fontSize: "14px",
    lineHeight: "17px",
    color: Colors.silver,
    padding: "5px 10px 5px 17px",
  },
  "& fieldset": {
    border: "none",
    outline: "none !important",
    color: Colors.silver,
  },
  "& fieldset:hover": {
    border: "none !important",
    outline: "none !important",
  },
  "input::placeholder": {
    color: `${Colors.silver} !important`,
    opacity: "1",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0",
    border: `1px solid ${Colors.lightWhite} !important`,
  },
});

const DialogBlockItemIcon = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "0 0 0 0",
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "7px",
});

const DialogButtons = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 25px 26px 25px",
  "& button:nth-of-type(1)": {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "500",
    color: Colors.gray,
    padding: "0",
    margin: "0",
    textTransform: "none",
    background: Colors.lightWhite,
    borderRadius: "0",
    width: "88px",
    height: "35px",
    transition: "all 0.3s linear",
    "&:hover": {
      background: Colors.brightGray,
    },
  },
  "& button:nth-of-type(2)": {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "500",
    color: Colors.white,
    textTransform: "none",
    background: Colors.skyBlue,
    borderRadius: "0",
    width: "152px",
    height: "35px",
    padding: "0",
    margin: "0 0 0 0",
    transition: "all 0.3s linear",
    "&:hover": {
      background: Colors.blueDark,
    },
  },
}));

const menuStyles = {
  background: "none !important",
  ".MuiMenu-list": {
    width: "100%",
    "& ul": {
      padding: "0",
    },
    "ul li": {
      background: "none !important",
      paddingRight: "7px",
      cursor: "pointer",
    },
    "ul li:hover": {
      background: "none !important",
    },
  },
};

const Arrow = styled(Box)({
  position: "absolute",
  top: "4px",
  right: "2px",
  width: "25px",
});

export default ProfileDocumentsCreateNewPopup;
