import { Box, ListItem, ListItemText, styled } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { referenceTableItems } from "src/system/constants";
import { Colors } from "src/system/constants";

const ConnectedReferenceTable = () => {
  return (
    <StyledBox>
      <Tabs>
        <TabList>
          <Tab>Reference table</Tab>
        </TabList>
        <TabPanel>
          <ReferenceTableBLock>
            <ReferenceTableItems>
              {referenceTableItems.map((item, index) => (
                <ReferenceTableItem key={index}>
                  <ReferenceTableItemTitle>
                    {item.title}
                  </ReferenceTableItemTitle>
                  <ReferenceTableItemList>
                    {item.items.map((subitem, subindex) => (
                      <ListItem key={subindex}>
                        <ListItemText>
                          <span></span>
                          <b>{subitem.value}</b>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </ReferenceTableItemList>
                </ReferenceTableItem>
              ))}
            </ReferenceTableItems>
          </ReferenceTableBLock>
        </TabPanel>
      </Tabs>
    </StyledBox>
  );
};
export default ConnectedReferenceTable;

const StyledBox = styled(Box)({
  padding: "30px 0 0 0",
  fontSize: "16px",
  lineHeight: "18px",
  fontWeight: 500,
  color: Colors.lightBlack,

  "& .react-tabs__tab-list": {
    marginBottom: "0",
    border: "none",
    "& span": {
      color: Colors.lightWhite,
    },
  },
  "& .react-tabs__tab": {
    marginBottom: "0",
    border: "none",
    padding: "6px 10px",
    color: Colors.silver,
  },
  "& .react-tabs__tab--selected": {
    border: "none",
    fontWeight: "700",
    color: Colors.lightBlack,
  },
});

const ReferenceTableItems = styled(Box)(({ theme }) => ({
  padding: "7px 0 0 0",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    gap: "16px",
  },
}));

const ReferenceTableItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "31.8%",
  marginTop: "20px",
  marginBottom: "0",
  border: `1px solid ${Colors.lightWhite}`,
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    width: "calc(50% - 8px)",
  },
}));

const ReferenceTableItemTitle = styled(Box)({
  fontSize: "12px",
  lineHeight: "14px",
  color: Colors.greenBlack,
  fontWeight: 500,
  padding: "7px 0 7px 13px",
  background: Colors.brightWhite,
});

const ReferenceTableItemList = styled(Box)(({ theme }) => ({
  padding: "2px 14px 0 14px",
  margin: "0",
  listStyle: "none",
  flexGrow: 1,
  "& .css-tlelie-MuiListItemText-root": {
    marginTop: "0",
    marginBottom: "0",
  },
  "& li": {
    margin: "0 0 0 0",
    padding: "7px 0 7px 0",
    borderBottom: `1px solid ${Colors.lightWhite}`,
  },
  "& .MuiTypography-root": {
    fontSize: "10px",
    lineHeight: "12px",
    color: Colors.black,
    display: ["flex", "flex"],
    alignItems: ["center", "center"],
    justifyContent: "space-between",
    height: "28px",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  "& li:last-child": {
    marginBottom: "0",
    border: "none",
  },
  "& .MuiTypography-root span": {
    width: "12px",
    height: "12px",
    borderRadius: "6px",
    display: "block",
    marginRight: "4px",
    flex: "none",
  },
  "& li:nth-of-type(1) .MuiTypography-root span": {
    backgroundColor: Colors.pink,
  },
  "& li:nth-of-type(2) .MuiTypography-root span": {
    backgroundColor: Colors.bicycleYellow,
  },
  "& li:nth-of-type(3) .MuiTypography-root span": {
    backgroundColor: Colors.brightGreen,
  },
  "& .MuiTypography-root b": {
    fontSize: "12px",
    lineHeight: "14px",
    color: Colors.lightBlack,
    fontWeight: 400,
    flexGrow: 1,
  },
}));

const ReferenceTableBLock = styled(Box)(({ theme }) => ({
  padding: "0 0 0 0",
  [theme.breakpoints.down("lg")]: {
    "& .referenceTable__item": {
      width: "32.5%",
    },
    "& ul.referenceTable__item-list li b": {
      fontSize: "11px",
    },
  },
}));
