import { Box, styled, Typography } from "@mui/material";
import ConnectedStationsVoltage from "./ConnectedStationsVoltage";
import ConnectedStationsSourcesOfConsumption from "./ConnectedStationsSourcesOfConsumption";
import ConnectedStationsWatt from "./ConnectedStationsWatt";
import ConnectedStationsPower from "./ConnectedStationsPower";
import EcoFlowCharger from "./EcoFlowCharger";
import { Colors, EnergyStatTypes } from "src/system/constants";
import BatteryInfo from "./BatteryInfo";
import EnergyInfo from "./EnergyInfo";
import { DataTypeKey } from "src/types/connectedStationType";
import { RoutesPath } from "../../../types/dashboardTypes";
import { BackIcon } from "../../../assets/icons";
import { NavLink } from "react-router-dom";
import React from "react";

const ConnectedStationsPage = () => (
  <ConnectedStations>
    <Typography component="h1">
      <NavLink to={RoutesPath.DEVICES}>
        <BackIcon />
      </NavLink>
      Ecoflow River
    </Typography>
    <Box className="connectedStations__row">
      <Box className="connectedStations__col">
        <EcoFlowCharger />
      </Box>
      <Box className="connectedStations__col">
        <Box className="connectedStations__line-block">
          <EnergyInfo type={EnergyStatTypes.PRODUCTION} />
          <EnergyInfo type={EnergyStatTypes.CONSUMPTION} />
        </Box>
        <Box className="connectedStations__poswer">
          <BatteryInfo type={DataTypeKey.IN_WATTS} maximum inWattsProps />
          <BatteryInfo type={DataTypeKey.TEMPERATURE} />
          <BatteryInfo type={DataTypeKey.IN_VOL} maximum inVoltProps />
        </Box>
      </Box>
    </Box>
    <Box className="connectedStations__boxs">
      <Box className="connectedStations__box">
        <ConnectedStationsVoltage />
      </Box>
      <Box className="connectedStations__box">
        <ConnectedStationsSourcesOfConsumption />
      </Box>
      <Box className="connectedStations__box">
        <ConnectedStationsWatt />
      </Box>
      <Box className="connectedStations__box">
        <ConnectedStationsPower />
      </Box>
    </Box>
  </ConnectedStations>
);

const ConnectedStations = styled(Box)(({ theme }) => ({
  padding: "0 0 0 0",
  fontFamily: "inherit",
  "& h1": {
    padding: "0",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
  },
  "& h2": {
    fontSize: "22px",
    lineHeight: "26px",
    color: Colors.lightBlack,
    fontWeight: 500,
    padding: "0",
  },
  ".connectedStations__box h2": { marginLeft: "8px" },
  ".connectedStations__row": {
    display: "flex",
    justifyContent: "space-between",
    padding: "44px 0 0 0",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  ".connectedStations__row .connectedStations__col:nth-of-type(1)": {
    width: "41.4%",
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  ".connectedStations__row .connectedStations__col:nth-of-type(2)": {
    width: "57.3%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  ".connectedStations__row .connectedStations__col:nth-of-type(2) .connectedStations__poswer":
    {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
  ".connectedStations__row .connectedStations__col:nth-of-type(2) .connectedStations__poswer .MuiBox-root span p":
    {
      color: Colors.skyBlue,
    },
  ".connectedStations__row .connectedStations__line-block": {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  ".connectedStations__items": {
    display: "flex",
    justifyContent: "space-between",
  },
  ".connectedStations__row1": {
    display: "flex",
    justifyContent: "space-between",
  },
  ".connectedStations__boxs": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  ".connectedStations__box .recharts-legend-wrapper li span": {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
  },
  ".connectedStations__box": {
    width: "49.4%",
    boxShadow: `0 0 3px ${Colors.lightGray}`,
    padding: "20px 0 0 0",
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  ".connectedStations__box:nth-of-type(1)": { padding: "23px 15px 20px 15px" },
  ".connectedStations__box:nth-of-type(2)": { padding: "23px 15px 20px 15px" },
  ".connectedStations__box:nth-of-type(2) h2": {
    padding: "0 0 0 0",
    marginLeft: "9px",
  },
  ".connectedStations__box:nth-of-type(3)": { padding: "23px 15px 20px 15px" },
  ".connectedStations__box:nth-of-type(3) h2": {
    padding: "0 0 0 0",
    marginLeft: "9px",
  },
  ".connectedStations__box:nth-of-type(4)": { padding: "23px 15px 20px 15px" },
  ".connectedStations__box:nth-of-type(4) h2": { padding: "0 0 0 0" },
  ".voltage-chart .recharts-legend-wrapper": {
    position: "absolute !important",
    top: "-35px !important",
    right: "0 !important",
    left: "auto !important",
  },
  ".voltage-chart .recharts-default-legend": { display: "flex" },
  ".voltage-chart .recharts-default-legend svg": { display: "none !important" },
  ".voltage-chart .recharts-default-legend li": {
    border: `1px solid ${Colors.cloudy}`,
    height: "26px",
    textAlign: "center",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  ".voltage-chart .recharts-cartesian-axis-tick": { position: "relative" },
  ".voltage-chart .recharts-text:before": {
    content: '""',
    position: "absolute",
    top: "0px",
    right: "0px",
    background: Colors.black,
    width: "10px",
    height: "10px",
  },
  ".voltage-chart .recharts-legend-item.legend-item-0 span": {
    color: `${Colors.black} !important`,
  },
  ".voltage-chart .recharts-legend-item.legend-item-1 span": {
    color: `${Colors.black} !important`,
  },
  ".activeReactive .recharts-legend-wrapper": {
    position: "absolute !important",
    top: "-35px !important",
    right: "0 !important",
    left: "auto !important",
  },
  ".activeReactive .recharts-default-legend": { display: "flex" },
  ".activeReactive .recharts-default-legend svg": {
    display: "none !important",
  },
  ".activeReactive .recharts-default-legend li": {
    border: `1px solid ${Colors.lightGreen}`,
    height: "24px",
    textAlign: "center",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  ".activeReactive .recharts-default-legend li:nth-of-type(1)": {
    border: `1px solid ${Colors.lightYellow}`,
  },
  ".activeReactive .recharts-default-legend li:nth-of-type(2)": {
    border: `1px solid ${Colors.skyBlue}`,
  },
  ".activeReactive .recharts-default-legend li:nth-of-type(3)": {
    border: `1px solid ${Colors.lightBrightGreen}`,
  },
  ".activeReactive .recharts-default-legend li:nth-of-type(4)": {
    border: `1px solid ${Colors.deepBlue}`,
  },
  ".activeReactive .recharts-cartesian-axis-tick": { position: "relative" },
  ".activeReactive .recharts-legend-item.legend-item-0 span": {
    color: `${Colors.black} !important`,
  },
  ".activeReactive .recharts-legend-item.legend-item-1 span": {
    color: `${Colors.black} !important`,
  },
  ".activeReactive .recharts-legend-item.legend-item-2 span": {
    color: `${Colors.black} !important`,
  },
  ".activeReactive .recharts-legend-item.legend-item-3 span": {
    color: `${Colors.black} !important`,
  },
  ".recharts-surface": { overflow: "inherit !important" },
  ".activeReactive .recharts-layer": { position: "relative" },
  ".activeReactive .recharts-layer:before": {
    content: '"V"',
    position: "absolute",
    top: "0px",
    right: "0px",
    background: Colors.black,
    width: "10px",
    height: "10px",
    zIndex: 999,
    fontSize: "20px",
    lineHeight: "22px",
  },
  ".current-chart .recharts-legend-wrapper": {
    position: "absolute !important",
    top: "-35px !important",
    right: "0 !important",
    left: "auto !important",
  },
  ".current-chart .recharts-default-legend": { display: "flex" },
  ".current-chart .recharts-default-legend svg": { display: "none !important" },
  ".current-chart .recharts-default-legend li": {
    border: `1px solid ${Colors.cloudy}`,
    height: "22px",
    textAlign: "center",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  ".current-chart .recharts-default-legend li:nth-of-type(2)": {
    border: `1px solid ${Colors.lightYellow}`,
  },
  ".current-chart .recharts-cartesian-axis-tick": { position: "relative" },
  ".current-chart .recharts-text:before": {
    content: '""',
    position: "absolute",
    top: "0px",
    right: "0px",
    background: Colors.black,
    width: "10px",
    height: "10px",
  },
  ".current-chart .recharts-legend-item.legend-item-0 span": {
    color: `${Colors.black} !important`,
  },
  ".current-chart .recharts-legend-item.legend-item-1 span": {
    color: `${Colors.black} !important`,
  },
  ".usb_1_watts": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.lightYellow,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
  },
  ".usb_2_watts": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.skyBlue,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
  },
  ".usb_3_watts": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.lightBrightGreen,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
  },
  ".type_c_watts": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.deepBlue,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
  },
  ".amp": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.lightGreen,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
  },
  ".watt-new": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.brightYellow,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
    padding: "0",
  },
  ".voltage": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.brightBlue,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
    padding: "0",
  },
  ".type_c_1_watts": {
    content: "''",
    width: "11px",
    height: "11px",
    background: Colors.lightBrightGreen,
    position: "absolute",
    top: "6px",
    left: "-18px",
    zIndex: 10,
  },
  "& .custom-tooltip": {
    background: Colors.white,
    boxShadow: `0 0 12px ${Colors.lightGray}`,
    padding: "10px 10px 10px 10px",
    width: "120px",
  },
  "& .param": {
    position: "relative",
    margin: "0 0 0 18px",
    padding: "0 0 0 0",
  },
  "& .time-tooltip": {
    padding: "0",
    margin: "0",
  },
  "& svg": {
    margin: "4px 5px 0 0",
  },
}));

export default ConnectedStationsPage;
