import React, { useRef, useState } from "react";
import { Box, styled } from "@mui/material";
import Header from "../Header";
import useOnClickOutside from "../../../utils/hooks/useOnClickOutside";

const BurgerMenu = () => {
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLVideoElement | null>(null);
  const nodeBtn = useRef<HTMLButtonElement | null>(null);

  useOnClickOutside(node, (event) => {
    const el = nodeBtn?.current;
    if (el && !el.contains(event?.target as Node)) {
      setOpen(false);
    }
  });

  return (
    <BurgerMenuBlock>
      <StyledBurger
        ref={nodeBtn}
        id="burger-btn"
        open={open}
        onClick={() => setOpen(!open)}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <StyledMenuContainer open={open}>
        <StyledMenu ref={node}>
          <Header mobileMode setClose={() => setOpen(false)} />
        </StyledMenu>
      </StyledMenuContainer>
    </BurgerMenuBlock>
  );
};

const StyledBurger = styled("button")<{ open: boolean }>(({ open }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  width: "32px",
  height: "32px",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  padding: 0,
  zIndex: 15,
  position: open ? "absolute" : "relative",
  left: open ? 16 : 0,
  top: open ? 12 : 0,

  "&:focus": {
    outline: "none",
  },

  div: {
    width: "2rem",
    height: "0.25rem",
    background: open ? "#85C7DE" : "#EFFFFA",
    borderRadius: "10px",
    transition: "all 0.3s linear",
    transformOrigin: "1px",

    "&:first-of-type": {
      transform: open ? "rotate(45deg)" : "rotate(0)",
    },

    "&:nth-of-type(2)": {
      opacity: open ? "0" : "1",
      transform: open ? "translateX(20px)" : "translateX(0)",
    },

    "&:nth-of-type(3)": {
      transform: open ? "rotate(-45deg)" : "rotate(0)",
    },
  },
}));

const StyledMenuContainer = styled(Box)<{ open: boolean }>(
  ({ theme, open }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: open ? "translateX(0)" : "translateX(-100%)",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: "transform 0.3s ease-in-out",
    zIndex: 10,
    background: "rgba(8, 32, 61, 0.60)",
    backdropFilter: "blur(2px)",
  })
);

const StyledMenu = styled("nav")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "left",
  padding: "2rem",
  width: 304,
  height: "100vh",
  background: "white",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
}));

const BurgerMenuBlock = styled(Box)({
  svg: {
    path: {
      stroke: "#4F4F4F",
    },
  },
});

export default BurgerMenu;
