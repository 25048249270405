import React from "react";
import { Box, styled } from "@mui/material";
import { Colors } from "src/system/constants";

const HeaderBeta: React.FC<{ inBeta?: boolean }> = ({ inBeta }) => {
  const beta = inBeta ?? false;
  return <StyledBoxBeta beta={beta}>Beta</StyledBoxBeta>;
};

const StyledBoxBeta = styled(Box)<{ beta: boolean }>(({ beta }) => ({
  visibility: beta ? "visible" : "collapse",
  margin: "-4px 0px 0px 9px",
  padding: "2px 8px 2px 8px",
  alignSelf: "flex-start",
  fontSize: 10,
  fontWeight: 500,
  lineHeight: "11.72px",
  color: Colors.white,
  textAlign: "center",
  borderRadius: 5,
  backgroundColor: Colors.lightBlue,
}));

export default HeaderBeta;
