import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ConnectedRoseOneDay from "./ConnectedRoseOneDay";
import ConnectedRoseWeek from "./ConnectedRoseWeek";
import ConnectedRoseMonth from "../components/ConnectedRoseMonth";
import { Box, styled } from "@mui/material";
import { Colors } from "src/system/constants";

const ConnectedRose = () => {
  return (
    <ConnectedTab>
      <Tabs>
        <TabPanel>
          <ConnectedTabBlock>
            <ConnectedTabSRose>
              <ConnectedRoseOneDay />
            </ConnectedTabSRose>
          </ConnectedTabBlock>
        </TabPanel>
        <TabPanel>
          <ConnectedTabBlock>
            <ConnectedTabSRose>
              <ConnectedRoseWeek />
            </ConnectedTabSRose>
          </ConnectedTabBlock>
        </TabPanel>
        <TabPanel>
          <ConnectedTabBlock>
            <ConnectedTabSRose>
              <ConnectedRoseMonth />
            </ConnectedTabSRose>
          </ConnectedTabBlock>
        </TabPanel>
        <TabList>
          <Tab data-testid="tab-rose-day">Day</Tab>
          <Tab data-testid="tab-rose-week">Week</Tab>
          <Tab data-testid="tab-rose-month">Month</Tab>
        </TabList>
      </Tabs>
    </ConnectedTab>
  );
};

const ConnectedTab = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "59px 0 0 0",
  display: "flex",
  justifyContent: "center",
  "& .react-tabs": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .recharts-surface": {
    overflow: "inherit",
    width: "276px",
  },
  "& .react-tabs__tab-list": {
    display: "flex",
    margin: "0",
    justifyContent: "center",
    borderBottom: "none",
  },
  "& .react-tabs__tab": {
    padding: "0 0",
    width: "100%",
    maxWidth: "48px",
    height: "26px",
    margin: "0 21px 0 0",
    border: `1px solid ${Colors.lightWhite} !important`,
    fontSize: "12px",
    lineHeight: "14px",
    color: Colors.silver,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: "0 !important",
    textAlign: "center",
    borderRadius: "0",
    background: "none",
    outline: "none",
    position: "static",
  },
  "& .react-tabs__tab:last-child": {
    marginRight: "0",
  },
  "& .react-tabs__tab.react-tabs__tab--selected": {
    border: `1px solid ${Colors.lightBlack} !important`,
    color: Colors.lightBlack,
  },
  ".recharts-polar-angle-axis-ticks .recharts-polar-angle-axis-tick": {
    padding: "20px 0 0 0",
    transform: "translate(0, 6px)",
  },
  ".breadcrumb li:last-of-type": { pointerEvents: "none" },
  ".rose-tooltip": {
    backgroundColor: Colors.white,
    padding: "12px 15px 12px 8px",
    width: "100%",
    boxShadow: "5px 4px 16px 0px rgba(0, 0, 0, 0.07)"
  },
  ".rose-time-tooltip": {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: Colors.dimGray,
  },
  ".rose-tooltip .top,\n.rose-tooltip .down": {
    display: "flex",
    alignItems: "center",
  },
  ".rose-tooltip .top .wrapUp,\n.rose-tooltip .down .wrapDown": {
    borderRadius: "1px",
    width: "10px",
    height: "10px",
    marginRight: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".rose-tooltip .top .wrapUp": { background: "rgba(194, 255, 220, 0.4)" },
  ".rose-tooltip .down .wrapDown": { background: "rgba(255, 160, 160, 0.4)" },
  ".rose-current-week": {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: Colors.dimGray,
    display: "flex",
    alignItems: "center",
  },
  ".current-week-color": {
    width: "10px",
    height: "10px",
    marginRight: "8px",
    backgroundColor: Colors.heavenly,
  },
  ".wrap-param": { marginBottom: "10px" },
  ".wrap-param.undefined": { display: "none" },
  ".wrap-param:last-of-type": { marginBottom: "0" },
  ".wrap-param .top,\n.wrap-param .down": {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    color: Colors.dimGray,
  },
  ".wrap-param .rose-week-tooltip": {
    marginBottom: "8px",
    display: "flex",
    alignItems: "center",
  },
  ".wrap-param .rose-week-tooltip p": {
    marginBottom: "0",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: Colors.dimGray,
  },
  ".box-tooltip": { width: "10px", height: "10px", marginRight: "10px" },
  ".box-tooltip.week1": { backgroundColor: Colors.bicycleYellow },
  ".box-tooltip.week2": { backgroundColor: Colors.heavenly },
  ".box-tooltip.week3": { backgroundColor: Colors.pink },
  ".box-tooltip.week4": { backgroundColor: Colors.brightGreen },
  ".box-tooltip.week5": { backgroundColor: Colors.violet },
  ".recharts-polar-angle-axis-tick-value": {
    transform: "scale(1.1) translate(-12px, -15px)",
    fontWeight: 400,
  },
});

const ConnectedTabBlock = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ConnectedTabSRose = styled(Box)({
  width: "284px",
  padding: "0 0 0 0",
  margin: "0 0 0 0",
});

export default ConnectedRose;
