import React from "react";
import { ChartProps } from "src/routes/ConnectedSensors/components/ConnectedPage";
import { Box, styled, Typography } from "@mui/material";
import { Colors, DEFAULT_Y_AXIS_DOMAIN_PRESSURE, ParamTooltipTypes, units, SensorChartGraphHeight } from "src/system/constants";
import ConnectedChart from "./ConnectedChart";

const ConnectedPressure: React.FC<ChartProps> = ({ data }) => {
  return (
    <>
      <ChartBox>
        <Typography>
          Pressure <ChartBoxText>mbar</ChartBoxText>
        </Typography>
      </ChartBox>
      <ConnectedChart
        averageChartItems = {[
          {name: ParamTooltipTypes.PRESSURE, unit: units.Pressure}
        ]}
        chartType = {ParamTooltipTypes.PRESSURE}
        chartUnit = {units.Pressure}
        chartDomain = {DEFAULT_Y_AXIS_DOMAIN_PRESSURE}
        graphHeight = {SensorChartGraphHeight.NORMAL}
        strokeColor = {Colors.yellow}
        areaColor = {Colors.yellow}
        grid
        yAxis
      />
    </>
  );
};

const ChartBox = styled(Box)({
  padding: "0 0 10px 0",
  display: "flex",
  justifyContent: "space-between",
  "& p": {
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: "20px",
  },
});

const ChartBoxText = styled(Box)({
  padding: "0 0 0 0",
  display: "inline-block",
  color: Colors.gray,
});

export default ConnectedPressure;
