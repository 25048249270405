import React, { useEffect, useState } from "react";
import ConnectedStationsWattChart from "./ConnectedStationsWattChart";
import dayjs, { Dayjs } from "dayjs";
import ConnectedStationTitle from "./ConnectedStationTitle";

const ConnectedStationsWatt = () => {
  const [date] = React.useState<Dayjs | null>(dayjs);

  const [updateKey, setUpdateKey] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => setUpdateKey((x) => x + 1), 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ConnectedStationTitle name="Watt" />
      <ConnectedStationsWattChart date={date} key={updateKey} />
    </>
  );
};

export default ConnectedStationsWatt;
