import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "src/routes/Dashboard/Dashboard";
import Devices from "src/routes/Devices/Devices";
import Notifications from "src/routes/Notifications/Notifications";
import ConnectedSensors from "src/routes/ConnectedSensors/ConnectedSensors";
import ConnectedStations from "src/routes/ConnectedStation/ConnectedStations";
import CallbackPageHandler from "./CallbackPageHandler";
import { Box, styled, Typography } from "@mui/material";
import { RoutesPath } from "../types/dashboardTypes";

const Content = () => {
  return (
    <ComponentStylde>
      <Typography className="content">
        <ContentIndent>
          <Routes>
            <Route path={RoutesPath.DASHBOARD} element={<Dashboard />} />
            <Route path={RoutesPath.DEVICES} element={<Devices />} />
            <Route
              path={RoutesPath.NOTIFICATIONS}
              element={<Notifications />}
            />
            <Route
              path={RoutesPath.CONNECTED_SENSORS}
              element={<ConnectedSensors />}
            />
            <Route
              path={RoutesPath.CONNECTED_STATIONS}
              element={<ConnectedStations />}
            />
            <Route
              path={RoutesPath.CALLBACK}
              element={<CallbackPageHandler />}
            />
          </Routes>
        </ContentIndent>
      </Typography>
    </ComponentStylde>
  );
};

const ComponentStylde = styled(Box)({
  ".content": { position: "relative", zIndex: 9 },
  ".content__indent": { padding: "24px 0 0 247px" },
});

const ContentIndent = styled(Box)(({ theme }) => ({
  padding: "24px 0 0 247px",
  [theme.breakpoints.down("lg")]: {
    padding: "24px 16px",
  },
}));

export default Content;
