import { GetChartDataItem } from "src/types/apiTypes";

const calculateAverageChartData = (items: GetChartDataItem[] | undefined, valueName: keyof GetChartDataItem): string => {
    
    if ( !items || items.length < 1) {
        return '0';
    }

    const sum = items.reduce((total, item) => total + (item[valueName] ?? 0), 0);
    const average = (sum / items.length).toFixed(2);
    
    return average;
}

export default calculateAverageChartData;