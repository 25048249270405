import React from "react";
import determineColor from "src/utils/determineColor";
import {
  sensorBoundsMap,
  sensorBoundsMapNotification,
} from "../../../system/constants";
import { Box } from "@mui/material";
import { Bounds } from "../../../types/connectedSensorsType";
import { SensorTypeNotification } from "./assets/types/notificationsTypes";
import NotificationDetermineColor from "./NotificationDetermineColor";

interface ConnectedChartDataIndicatorProps {
  sensor: SensorTypeNotification;
  value: number;
}

const ConnectedChartDataIndicatorNotification: React.FC<
  ConnectedChartDataIndicatorProps
> = ({ sensor, value }) => {
  const sensorBounds = sensorBoundsMapNotification.get(sensor) as Bounds;
  const color = NotificationDetermineColor(
    value,
    sensorBounds?.greenBound ?? 0,
    sensorBounds?.redBound ?? 0,
    sensorBounds?.secondGreenBound ?? 0
  );
  return (
    <Box
      sx={{
        width: 10,
        height: 10,
        marginRight: "8px",
        borderRadius: 5,
        backgroundColor: color,
      }}
    />
  );
};

export default ConnectedChartDataIndicatorNotification;
