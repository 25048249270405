import React, { useContext } from "react";
import ConnectedRose from "./ConnectedRose";
import ConnectedReferenceTable from "./ConnectedReferenceTable";
import ConnectedChartData from "./ConnectedChartData";
import ConnectedPressure from "./ConnectedPressure";
import ConnectedChartsCo2Voc from "./ConnectedChartsCo2Voc";
import ConnectedRadon from "./ConnectedRadon";
import ConnectedTemperature from "./ConnectedTemperature";
import ConnectedHumidity from "./ConnectedHumidity";
import { ReportingFrequencyContext } from "src/utils/reportingFrequencyContext";
import { Box, styled, Typography } from "@mui/material";
import { Colors } from "src/system/constants";
import { postApi } from "src/state/reducers/postApi";
import { DevicesIds, DeviceTypes, UnitTypes } from "src/system/constants";
import { NavLink } from "react-router-dom";
import { RoutesPath } from "../../../types/dashboardTypes";
import { BackIcon } from "../../../assets/icons";

export type ChartProps = {
  data: unknown;
};

const ConnectedPage = () => {
  const reportingFrequencyContext = useContext(ReportingFrequencyContext);
  const { data: chartData } = postApi.useGetChartDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.DAY,
    dateStart: reportingFrequencyContext?.dateStart,
    dateEnd: reportingFrequencyContext?.dateEnd,
  });

  return (
    <ConnectedPageBlock>
      <Typography component="h1">
        <NavLink to={RoutesPath.DEVICES}>
          <BackIcon />
        </NavLink>
        Airthings Wave Plus
      </Typography>
      <ConnectedBlock>
        <ConnectedBlockRadar>
          <ConnectedRose />
        </ConnectedBlockRadar>
        <ConnectedBlockReferenceTable>
          <ConnectedReferenceTable />
        </ConnectedBlockReferenceTable>
      </ConnectedBlock>
      <ConnectedItems>
        <ConnectedItem>
          <ConnectedChartData />
        </ConnectedItem>
        <ConnectedItem>
          <ConnectedPressure data={chartData} />
        </ConnectedItem>
        <ConnectedItem>
          <ConnectedChartsCo2Voc data={chartData} />
        </ConnectedItem>
        <ConnectedItem>
          <ConnectedRadon data={chartData} />
        </ConnectedItem>
        <ConnectedItem>
          <ConnectedTemperature data={chartData} />
        </ConnectedItem>
        <ConnectedItem>
          <ConnectedHumidity data={chartData} />
        </ConnectedItem>
      </ConnectedItems>
    </ConnectedPageBlock>
  );
};

const ConnectedBlock = styled(Box)({
  padding: "0",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

const ConnectedBlockRadar = styled(Box)(({ theme }) => ({
  width: "49.4%",
  height: "427px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    "& h2": {
      fontSize: "16px",
      lineHeight: "18px",
    },
    "&:nth-child(1n)": {
      marginRight: "0",
    },
  },
}));

const ConnectedBlockReferenceTable = styled(Box)(({ theme }) => ({
  width: "49.4%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    "& h2": {
      fontSize: "16px",
      lineHeight: "18px",
    },
    "&:nth-child(1n)": {
      marginRight: "0",
    },
  },
}));

const ConnectedItems = styled(Box)({
  padding: "35px 0 0 0",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

const ConnectedItem = styled(Box)(({ theme }) => ({
  width: "calc(50% - (20px / 2))",
  padding: "24px 27px 24px 24px",
  boxShadow: `0 0 3px ${Colors.lightGray}`,
  marginRight: "20px",
  marginBottom: "22px",
  position: "relative",

  "&:nth-child(2n)": {
    marginRight: "0",
  },

  "& h2": {
    fontSize: "22px",
    lineHeight: "26px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    "& h2": {
      fontSize: "16px",
      lineHeight: "18px",
    },
    "&:nth-child(1n)": {
      marginRight: "0",
    },
  },
}));

const ConnectedPageBlock = styled(Typography)({
  padding: "0",
  "& .custom-tooltip": {
    background: Colors.white,
    boxShadow: `0 0 12px ${Colors.lightGray}`,
    padding: "10px 10px 10px 10px",
    width: "120px",
  },
  "& .param": {
    position: "relative",
    margin: "0 0 0 18px",
    padding: "0 0 0 0",
    fontSize: "10px",
    lineHeight: "12px",
  },
  "& .time-tooltip": {
    padding: "0 0 10px 0",
    margin: "0",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "500",
  },
  "& h1": {
    display: "flex",
    alignItems: "center",
  },
  "& svg": {
    margin: "4px 5px 0 0",
  },
});

export default ConnectedPage;
