import { SensorTypeNotification } from "../routes/Notifications/components/assets/types/notificationsTypes";
import {
  CHANGE_LEVEL_TEXT,
  CO2_TEXT,
  HIGH_HUMIDITY_TEXT,
  HIGH_TEMPERATURE_TEXT,
  LOW_HUMIDITY_TEXT,
  LOW_TEMPERATURE_TEXT,
  PRESSURE_TEXT,
  RADON_TEXT,
  VOC_TEXT,
} from "../system/constants";

export const mapErrorTypeToText = (
  errorType: SensorTypeNotification,
  modelName: string
) => {
  switch (errorType) {
    case SensorTypeNotification.LOST_CONNECTION:
      return `Lost connection with ${modelName}`;
    case SensorTypeNotification.CHARGE_LEVEL:
      return CHANGE_LEVEL_TEXT;
    case SensorTypeNotification.HIGH_CO2:
      return CO2_TEXT;
    case SensorTypeNotification.HIGH_VOC:
      return VOC_TEXT;
    case SensorTypeNotification.HIGH_RADON:
      return RADON_TEXT;
    case SensorTypeNotification.HIGH_PRESSURE:
      return PRESSURE_TEXT;
    case SensorTypeNotification.LOW_HUMIDITY:
      return LOW_HUMIDITY_TEXT;
    case SensorTypeNotification.HIGH_HUMIDITY:
      return HIGH_HUMIDITY_TEXT;
    case SensorTypeNotification.HIGH_TEMPERATURE:
      return HIGH_TEMPERATURE_TEXT;
    case SensorTypeNotification.LOW_TEMPERATURE:
      return LOW_TEMPERATURE_TEXT;
    default:
      return errorType;
  }
};
