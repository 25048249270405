
import { Colors } from "src/system/constants";

const determineColor = (value: number, greenBound: number, redBound: number, secondGreenBound?: number ) => {
    if (redBound && value >= redBound)
        return Colors.pink;
    else if (greenBound && redBound && value >= greenBound && value < redBound )
        return Colors.bicycleYellow
    else if (secondGreenBound && value < secondGreenBound)
        return Colors.pink
    else return Colors.brightGreen
};

export default determineColor;