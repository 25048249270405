import React, { JSXElementConstructor } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const DeviceTransition = React.forwardRef((
    props: TransitionProps & {
      children: React.ReactElement<
        unknown,
        string | JSXElementConstructor<unknown>
      >;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  export default DeviceTransition;