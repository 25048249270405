import { Box, styled } from "@mui/material";
import { Colors } from "src/system/constants";
import { TitleProps } from "src/types/connectedStationType";

const ConnectedStationTitle = (props: TitleProps) => {
  return <TitleBLock>{props.name}</TitleBLock>;
};

const TitleBLock = styled(Box)(({ theme }) => ({
  fontSize: "22px",
  lineHeight: "26px",
  color: Colors.lightBlack,
  fontWeight: "500",
  padding: "0",
  marginLeft: "8px",
  marginBottom: "9px",
  [theme.breakpoints.down("xl")]: {
      fontSize: "20px",
      lineHeight: "22px",
  },
  [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "18px",
  },
}));

export default ConnectedStationTitle;
