import { Box, Typography } from "@mui/material";
import noDataImg from "../assets/images/no-data-6.png";
import React from "react";
import { Colors } from "../system/constants";

const DEFAULT_NO_DATA_HEIGHT = 200;
const NoData: React.FC<{
  height?: number;
}> = ({ height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: height ?? DEFAULT_NO_DATA_HEIGHT,
      }}
    >
      <img
        style={{
          height: height ?? DEFAULT_NO_DATA_HEIGHT,
        }}
        src={noDataImg}
        alt="No Data"
      />
      <Typography
        sx={{
          color: Colors.darkSilver,
          marginTop: "-16px",
        }}
      >
        Currently there is no data!
      </Typography>
    </Box>
  );
};
export default NoData;
