import { SensorTypeData } from "../system/constants";

export const getSensorData = (
  currentData: unknown,
  sensorType: SensorTypeData
): number =>
  Math.round(
    (currentData as Record<string, number>)?.[sensorType.toLowerCase()] || 0
  );

export const getAllSensorsData = (
  currentData: unknown
): Record<string, number> => {
  return {
    currentCo2: getSensorData(currentData, SensorTypeData.CO2),
    currentTemp: getSensorData(currentData, SensorTypeData.TEMP),
    currentVoc: getSensorData(currentData, SensorTypeData.VOC),
    currentHum: getSensorData(currentData, SensorTypeData.HUM),
    currentRad: getSensorData(currentData, SensorTypeData.RAD),
    currentPress: getSensorData(currentData, SensorTypeData.PRESS),
  };
};
