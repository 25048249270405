import React from "react";
import { Box, styled } from "@mui/material";
import { CalendarArrowIcon } from "src/assets/icons";
import { Colors } from "src/system/constants";

interface CalendarResultProps {
  previousAction: () => void;
  nextAction: () => void;
  clickAction: () => void;
  dateStr?: string;
}

const CalendarResult: React.FC<CalendarResultProps> = ({
  previousAction,
  nextAction,
  clickAction,
  dateStr
}) => {
  return (
    <StyledWrapper>
      <button data-testid="calendar-button-prev" onClick={previousAction}>
        <CalendarArrowIcon />
      </button>
      <TextBox data-testid="calendarTitle" onClick={clickAction}>
        {dateStr}
      </TextBox>
      <button data-testid="calendar-button-next" onClick={nextAction}>
        <CalendarArrowIcon />
      </button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "auto",
  justifyContent: "center",
  gap: "8px",
  "button": {
    backgroundColor: "transparent",
    border: "0",
    display: "flex",
    cursor: "pointer",
    zIndex: 10,
    "&:last-of-type": {
      transform: "rotate(180deg)"
    }
  }
});

const TextBox = styled(Box)({
  margin: "0",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "21px",
  color: Colors.dimGray,
  minWidth: "220px",
  textAlign: "center",
  cursor: "pointer"
});

export default CalendarResult;