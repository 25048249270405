import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { systemVariables } from "src/system/constants";
import { makeQueryStringFromObject } from "src/system/api";
import { DeviceTypes } from "./../../system/constants";
import { DevicesIds } from "./../../system/constants";
import { UnitTypes } from "./../../system/constants";
import { GetChartDataItem, GetRadarDataItem } from "./../../types/apiTypes";

const apiPath = systemVariables.apiBaseUrl;

const baseQuery = fetchBaseQuery({ baseUrl: apiPath });

export const postApi = createApi({
  reducerPath: "postAPI",
  baseQuery,
  endpoints: (build) => ({
    getAllDevice: build.query({
      query: () => ({
        url: "/devices",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      },
    }),

    getChartData: build.query<
      {
        items: GetChartDataItem[];
        count: number;
      },
      {
        deviceId: DevicesIds;
        deviceType: DeviceTypes;
        unitType: UnitTypes;
        dateStart?: number | undefined;
        dateEnd?: number | undefined;
        shouldLog?: boolean;
      }
    >({
      query: ({
        deviceId,
        deviceType,
        unitType,
        dateStart,
        dateEnd,
        shouldLog,
      }) => ({
        url: `/data?${makeQueryStringFromObject({
          device_id: deviceId,
          device_type: deviceType,
          unit_type: unitType,
          date_start: dateStart || 0,
          date_end: dateEnd || 0,
        })}`,

        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      },
    }),

    getRadarData: build.query<
      GetRadarDataItem,
      {
        deviceId: DevicesIds;
        deviceType: DeviceTypes;
        unitType: UnitTypes;
        shouldLog?: boolean;
      }
    >({
      query: ({ deviceId, deviceType, unitType, shouldLog }) => ({
        url: `/radar_sensors/${unitType}?${makeQueryStringFromObject({
          device_id: deviceId,
          device_type: deviceType,
        })}`,

        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useGetAllDeviceQuery,
  useGetChartDataQuery,
  useGetRadarDataQuery,
} = postApi;
