import React from "react";
import { Box, styled, Link } from "@mui/material";
import BurgerMenu from "./components/BurgerMenu";
import { NotificationIcon, WhiteLogoIcon } from "../../assets/icons";
import { RoutesPath } from "../../types/dashboardTypes";

const MobileHeader = () => {
  return (
    <MobileHeaderBlock>
      <BurgerMenu />
      <Link href={RoutesPath.DASHBOARD}>
        <WhiteLogoIcon />
      </Link>
      <NotificationIcon />
    </MobileHeaderBlock>
  );
};

const MobileHeaderBlock = styled(Box)({
  width: "100%",
  height: "56px",
  backgroundColor: "#2680EA",
  padding: "12px 12px 12px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  svg: {
    path: {
      stroke: "white",
    },
  },
});

export default MobileHeader;
