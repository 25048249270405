import { Box, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const SystemLoader = () => (
  <MyCoolLoaderBLock>
    <CircularProgress />
  </MyCoolLoaderBLock>
);

const MyCoolLoaderBLock = styled(Box)({
  height: "220px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0",
});

export default SystemLoader;
