import DeviceItem from "./components/DeviceItem";
import { Box, styled, Typography } from "@mui/material";
import { Colors, deviceData, DeviceStatus } from "src/system/constants";
import { useState } from "react";
import DevicePopup from "./components/DevicePopup";
import { DevicePlusIcon } from "src/assets/icons";
import { postApi } from "src/state/reducers/postApi";
import HeaderBeta from "src/components/Header/components/HeaderBeta";
import { AllDevicesItem } from "src/types/devicesTypes";

const Devices = () => {
  const [isOpenedPopup, setIsOpenedPopup] = useState(false);

  const handleClickOpen = () => {
    setIsOpenedPopup(true);
  };

  const allDevices: Array<AllDevicesItem> =
    postApi.useGetAllDeviceQuery("").data?.device;

  return (
    <Box>
      <Device>
        <DeviceSection>
          <Box>
            <DeviceTitle>Devices</DeviceTitle>
          </Box>
          <DeviceButton onClick={handleClickOpen}>
            <DevicePlusIcon />
            <HeaderBeta />
            Add new device
          </DeviceButton>
          <DevicePopup
            open={isOpenedPopup}
            setOpen={setIsOpenedPopup}
            title="Add new device"
          />
        </DeviceSection>
        <DeviceMain>
          {allDevices &&
            allDevices.map((item) => (
              <DeviceItem
                key={item.model}
                title={item.model}
                image={deviceData[item.id].image}
                link={deviceData[item.id].link}
                name={item.name}
                type={item.device_type}
                connection={item.connection_date}
                dataTestId={deviceData[item.id].dataTestId}
                status={item.device_status as DeviceStatus}
              />
            ))}
        </DeviceMain>
      </Device>
    </Box>
  );
};

const Device = styled(Box)({
  padding: "0",
});

const DeviceSection = styled(Box)({
  padding: "0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const DeviceTitle = styled(Typography)({
  fontSize: "24px",
  lineHeight: "26px",
  color: Colors.lightBlack,
  fontWeight: 500,
});

const DeviceMain = styled(Typography)({
  padding: "32px 31px 30px 31px",
  background: Colors.whiteGrayLight,
  margin: "17px 0 0 0",
});

const DeviceButton = styled(Typography)({
  position: "relative",
  width: "176px",
  height: "35px",
  background: Colors.whiteGray,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  lineHeight: "19px",
  color: Colors.white,
  fontWeight: "500",
  cursor: "pointer",
  transition: "all 0.3s linear",
  pointerEvents: "none",
  "& svg": {
    margin: "0 5px 0 0",
  },
  "&:hover": {
    background: Colors.blueDark,
  },
  "& div": {
    position: "absolute",
    visibility: "initial",
    left: "-25px",
  },
});

export default Devices;
