import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Curve,
  Tooltip,
} from "recharts";
import SystemLoader from "src/components/SystemLoader";
import { postApi } from "src/state/reducers/postApi";
import {
  Colors,
  DevicesIds,
  DeviceTypes,
  UnitTypes,
  DEFAULT_Y_AXIS_DOMAIN_ROSE_WEEK,
  DEFAULT_GRADENT_OPACITY,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT,
  RADAR_LINE_TYPE,
} from "src/system/constants";
import CustomTooltip from "./CustomTooltip";
import getDayWeekRadarDataset from "src/utils/getDayWeekRadarDataset";

const ConnectedRoseWeek = () => {
  const { data: sensorData, isLoading } = postApi.useGetRadarDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.WEEK,
  });

  const radarDataset = getDayWeekRadarDataset(UnitTypes.WEEK, sensorData);

  return isLoading ? (
    <SystemLoader />
  ) : (
    <ResponsiveContainer width={284} height={284}>
      <RadarChart cx="50%" cy="50%" outerRadius="100%" data={radarDataset}>
        <PolarGrid stroke={Colors.skyViolet} gridType="polygon" />
        <PolarAngleAxis
          dataKey="chartType"
          style={{
            fontSize: "14px",
          }}
        />
        <PolarRadiusAxis
          tick={false}
          axisLine={false}
          tickCount={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT}
        />
        <PolarRadiusAxis angle={30} domain={DEFAULT_Y_AXIS_DOMAIN_ROSE_WEEK} />
        <Tooltip
          content={
            <CustomTooltip unitType={UnitTypes.WEEK} data={sensorData} />
          }
        />
        <Radar
          name="radar"
          dataKey="value"
          stroke={Colors.heavenly}
          fill={Colors.heavenly}
          fillOpacity={DEFAULT_GRADENT_OPACITY}
          shape={<Curve type={RADAR_LINE_TYPE} />}
          dot={{
            r: 1,
            strokeWidth: 2,
            fill: Colors.heavenly,
            stroke: Colors.heavenly,
            opacity: 1,
          }}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};
export default ConnectedRoseWeek;
