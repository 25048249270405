import { Box, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ChartItem } from "src/types/customTooltipType";
import {
  DataType,
  EnergyUnitTypes,
  EDUCATION_TO_TIME,
  TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE,
} from "src/system/constants";

const CustomTooltip: React.FC<{
  data: ChartItem;
  type: DataType;
}> = ({ data, type }) => {
  if (!data) {
    return null;
  }
  const payload = data?.payload;
  if (!payload) {
    return null;
  }
  const voltageValue = (payload?.[0]?.payload?.vol ?? 0).toFixed(2);
  const payloadTimestamp = payload?.[0]?.payload?.timestamp;
  const timestamp = dayjs(payloadTimestamp * EDUCATION_TO_TIME).format(
    TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE
  );
  if (type === DataType.ENERGY || type === DataType.VOL) {
    return (
      <CustomTooltipWrap className="custom-tooltip">
        <Typography className="time-tooltip">{timestamp}</Typography>
        <Typography className="param param-1">
          <span className="voltage"></span>
          {`${voltageValue} ${EnergyUnitTypes.V}`}
        </Typography>
      </CustomTooltipWrap>
    );
  }
  return null;
};

const CustomTooltipWrap = styled(Box)({
  fontSize: "12px",
  fontWeight: "500",
  "& .param": {
    fontSize: "10px",
    fontWeight: "400",
    display: "flex",
    span: {
      width: "10px",
      height: "10px",
      top: "3px",
    },
  },
});

export default CustomTooltip;
