import React from "react";
import { useCountUp } from "react-countup";
import { Box, styled } from "@mui/material";
import { Colors, UsageProperties } from "src/system/constants";

const AirQualityMonitorUsageEnergy = (props: PropTypes) => {
  useCountUp({
    ref: props.name,
    start: 0,
    end: props.percent,
    duration: 1,
  });
  const isActive = props.percent !== 0;
  return (
    <AirQualityMonitorUsageEnergyStyled
      propName={props.name as UsageProperties}
      isActive={isActive}
    >
      <div className="progress__indent">
        <div className={`progress ${isActive ? "progress-true" : ""}`}>
          <div className="progressBarText">
            <span>{props.name}</span>
          </div>
          <div className="wrapProgress">
            <div
              className={`progressBar ${
                props.percent === 0 ? "progress-none" : ""
              }`}
              style={{ width: props.percent + "%" }}
            ></div>
            <div
              style={{
                color: isActive ? Colors.dimGray : Colors.silver,
              }}
              className="wrap__wolt"
            >
              <span id={props.name}>{props.percent}</span>
              <em>W</em>
            </div>
          </div>
        </div>
      </div>
    </AirQualityMonitorUsageEnergyStyled>
  );
};

const AirQualityMonitorUsageEnergyStyled = styled(Box)<{
  isActive: boolean;
  propName: UsageProperties;
}>(({ isActive, propName }) => ({
  ".progress": {
    marginBottom:
      propName === UsageProperties.AC_250V
        ? "17px !important"
        : "16px !important",
    position: "relative",
    fontSize: "16px",
    background: Colors.white,
    boxSizing: "border-box",
    borderRadius: "6px",
    height: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ".progress::before": {
    content: '""',
    position: "absolute",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: Colors.lightWhite,
    top: "50%",
    left: "0",
    transform: "translateY(-50%)",
  },
  ".progress.progress-true::before": {
    backgroundColor: Colors.greenMana,
  },
  ".progress .wrap__wolt": {
    flex: "0 0 auto",
    color: Colors.dimGray,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
  },
  ".progress .wrap__wolt em": { fontStyle: "initial" },
  ".progressBar": {
    width: "121px !important",
    flex: "0 1 auto",
    background: "none !important",
    display: "flex",
    alignItems: "center",
    height: ["16px", "12px"],
    transition: "width 0.6s ease-in-out",
    position: "relative",
    marginRight: "8px",
    minWidth: "30px",
  },
  ".progressBar:before": {
    content: '""',
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "100%",
    background:
      "linear-gradient(270deg, #85C7DE 0%, rgba(160, 196, 226, 0.12) 109.02%)",
    borderRadius: "6px",
    animation: "animation1 1s forwards",
  },
  ".progressBar.progress-none:before": { background: Colors.lightWhite },
  "@keyframes animation1": {
    "0%": { width: "0" },
    "100%": { width: "100%" },
  },
  ".progressBlock": { padding: "56px 25px 0 25px" },
  ".progressBarText": {
    display: "flex",
    padding: "0 8px 0 14px",
    margin: "0",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "14px",
    width: "50%",
    color: isActive ? Colors.dimGray : Colors.silver,
  },
  ".wrapProgress": {
    display: "flex",
    alignItems: "center",
  },
  ".progressBarText span": { display: "block", whiteSpace: "nowrap" },
  ".progressBarSection": {
    flex: "0 1 168px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

interface PropTypes {
  percent: number;
  name: string;
}

export default AirQualityMonitorUsageEnergy;
