import React from "react";
import dayjs from "dayjs";
import { Box, styled, Typography } from "@mui/material";
import { ConnectedChartTooltipProps } from "src/types/connectedSensorsType";
import {
  TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE,
  EDUCATION_TO_TIME,
  ParamTooltipTypes,
} from "src/system/constants";

const ConnectedChartTooltip: React.FC<ConnectedChartTooltipProps> = ({
  payload,
  tooltipColor,
  paramNameTooltip,
  unitsOfMeasurement,
}) => {
  if (!payload || payload.length === 0) {
    return null;
  }
  const vocValue =
    payload?.[0]?.payload?.[paramNameTooltip ?? ParamTooltipTypes.CO2];

  const defaultFormat = dayjs(
    payload?.[0]?.payload?.timestamp * EDUCATION_TO_TIME
  ).format(TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE);

  return (
    <Typography className="custom-tooltip">
      <Typography className="time-tooltip">{defaultFormat}</Typography>
      <Typography className="param param-1">
        <ColorTootltip markerColor={tooltipColor}></ColorTootltip>
        {vocValue} {unitsOfMeasurement}
      </Typography>
    </Typography>
  );
};

const ColorTootltip = styled(Box)<{markerColor: string}>(({markerColor}) => ({
  content: "''",
  width: "10px",
  height: "10px",
  position: "absolute",
  top: "1px",
  left: "-18px",
  zIndex: 10,
  background: markerColor
}));

export default ConnectedChartTooltip;
