import { Box, styled, Typography } from "@mui/material";
import ConnectedChartDataIndicator from "./ConnectedChartDataIndicator";
import dayjs from "dayjs";
import { postApi } from "src/state/reducers/postApi";
import {
  Colors,
  DEFAULT_DATE_FORMAT,
  DevicesIds,
  DeviceTypes,
  UnitTypes,
  SensorType,
  units,
  chartData,
} from "src/system/constants";
import { getAllSensorsData } from "../../../utils/getSensorData";

const DAY = "day";

const ConnectedChartData = () => {
  const baseDate = dayjs(
    dayjs().add(1, DAY).format(DEFAULT_DATE_FORMAT),
    DEFAULT_DATE_FORMAT
  );

  const { data } = postApi.useGetChartDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.DAY,
    dateStart: dayjs(baseDate).subtract(1, DAY).unix(),
    dateEnd: baseDate.unix(),
  });

  const currentData = data?.items?.[data?.items ? data?.items.length - 1 : 0];
  const sensorsData = getAllSensorsData(currentData);

  const chartDataCurrent = chartData.map((item) => ({
    ...item,
    value: item.key ? sensorsData[item.key] : 0,
  }));

  return (
    <>
      <ChartDataTitle>Current Chart Data</ChartDataTitle>
      <WrapParametrsChartDataAll>
        {chartDataCurrent.map((item, index) => {
          const Icon = item.imgComponent;
          const formattedValue = `${isNaN(+item.value) ? 0 : item.value} ${
            item.unit
          }`;
          return (
            <ChartDataItem key={index}>
              <ChartDataItemParam>
                <ChartDataItemImg>
                  {Icon && <Icon />}
                  <span>{item.sensor}</span>
                </ChartDataItemImg>
                <Box width="120px" display="flex" alignItems="center">
                  <ConnectedChartDataIndicator
                    sensor={item.sensor}
                    value={item.value}
                  />
                  <Typography className="wrapLastResult">
                    {formattedValue}
                  </Typography>
                </Box>
              </ChartDataItemParam>
            </ChartDataItem>
          );
        })}
      </WrapParametrsChartDataAll>
    </>
  );
};

const ChartDataTitle = styled(Box)({
  fontSize: "22px",
  lineHeight: "26px",
  color: Colors.lightBlack,
  fontWeight: 500,
  marginBottom: "34px",
  padding: "4px 0 0 0",
});

const ChartDataItem = styled(Box)(({ theme }) => ({
  width: "43%",
  marginBottom: "40px",
  "& .wrapLastResult": {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: Colors.dimGray,
  },
  "&:nth-of-type(5)": {
    marginBottom: "0",
  },
  "&:nth-of-type(6)": {
    marginBottom: "0",
  },
}));

const ChartDataItemParam = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const ChartDataItemImg = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& svg": {
    margin: " 0 12px 0 0",
  },
  "& span": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: Colors.dimGray,
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "10px",
  },
}));

const WrapParametrsChartDataAll = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  "& .wrapParametrsChartData": {
    width: "50%",
    marginBottom: "40px",
  },
  [theme.breakpoints.down("lg")]: {
    "& .wrapParametrsChartData .param .wrapLastResult": {
      fontSize: "13px",
      marginLeft: "115px",
    },
  },
}));

export default ConnectedChartData;
