import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import dayjs, { Dayjs } from "dayjs";
import { Box, styled } from "@mui/material";
import getTicksOfInterval from "src/utils/getTicksOfInterval";
import SystemLoader from "./SystemLoader";
import { postApi } from "src/state/reducers/postApi";
import {
  DataType,
  Colors,
  UnitTypes,
  DevicesIds,
  DeviceTypes,
  ParamTooltipTypes,
  UnitTypesIntervalStr,
  UNIT_TYPE_DAY,
  UNIT_TYPE_WEEK,
  TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE,
  THE_DAY_OF_THE_WEEK,
  THE_DAY_OF_THE_MONTH,
  DEFAULT_DATE_FORMAT,
  MOCKED_AVERAGE_PRESSURE,
  EDUCATION_TO_TIME,
  TIMESTAMP_ONE_MINUTE,
  chartMaxValues,
} from "src/system/constants";
import CustomTooltip from "./CustomTooltip";
import { AxisDomain } from "recharts/src/util/types";
import getCalendarDateParameter, {
  DateJSUnitOfTime,
} from "../utils/getCalendarDateParameter";
import { maxBy } from "lodash";

interface ScheduleCalendarProps {
  date?: Dayjs | null;
  renderSingleValue?: boolean;
  classNameTooltipFirst?: string;
  classNameTooltipSecond?: string;
  paramTooltipFirst?: ParamTooltipTypes;
  paramTooltipSecond?: ParamTooltipTypes;
  paramNameTooltipFirst?: string;
  paramNameTooltipSecond?: string;
  renderGradient?: boolean;
  stopOpacityGradientFirst?: string;
  stopOpacityGradientSecond?: string;
  stopColorGradientFirst?: string;
  stopColorGradientSecond?: string;
  domainStart?: number | string;
  domainEnd?: number | string;
  renderArea?: boolean;
  unitType: UnitTypes;
  intervalStr: UnitTypesIntervalStr;
  deviceId: DevicesIds;
  deviceType: DeviceTypes;
  dataType: DataType;
  kind?: string;
  tickCount?: number;
}

const PRESSURE_KEY_NAME = "pressure";

const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({
  date,
  renderSingleValue,
  classNameTooltipFirst,
  classNameTooltipSecond,
  paramNameTooltipFirst,
  paramNameTooltipSecond,
  paramTooltipFirst,
  paramTooltipSecond,
  renderGradient,
  stopOpacityGradientFirst,
  stopOpacityGradientSecond,
  stopColorGradientFirst,
  stopColorGradientSecond,
  domainStart,
  domainEnd,
  renderArea,
  unitType,
  intervalStr,
  deviceId,
  deviceType,
  dataType,
  kind,
  tickCount,
}) => {
  const secondParameter = getCalendarDateParameter(
    DateJSUnitOfTime.START_OF,
    true,
    kind,
    date
  );

  const lastParameter = getCalendarDateParameter(
    DateJSUnitOfTime.END_OF,
    true,
    kind,
    date
  );

  const todayParameter = getCalendarDateParameter(
    DateJSUnitOfTime.START_OF,
    false,
    kind,
    dayjs()
  );

  const dateStartParameter = getCalendarDateParameter(
    DateJSUnitOfTime.START_OF,
    false,
    kind,
    date
  );

  const { data, isLoading } = postApi.useGetChartDataQuery({
    deviceId: deviceId,
    deviceType: deviceType,
    unitType: unitType,
    dateStart: secondParameter as number,
    dateEnd: lastParameter as number,
  });

  const interval = useRef<number | null>(null);

  const [updateKey, setUpdateKey] = useState(0);
  useEffect(() => {
    if (!date) return;
    if (interval.current) clearInterval(interval.current);
    const isSame = (todayParameter as Dayjs).isSame(dateStartParameter);
    if (isSame) {
      interval.current = window.setInterval(() => {
        if (!date) return;
        // if today equal start day we update key
        if (isSame) setUpdateKey((x) => x + 1);
      }, TIMESTAMP_ONE_MINUTE);
    }
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [date, dateStartParameter, todayParameter]);

  const firstValueTimestamp = data?.items?.[0]?.timestamp;
  const lastValueTimestamp = data?.items?.slice()?.sort()?.[
    data?.items ? data?.items.length - 1 : 0
  ]?.timestamp;

  const ticks = getTicksOfInterval(
    firstValueTimestamp,
    lastValueTimestamp,
    `${intervalStr}`
  );

  const yAxisDomain: AxisDomain = data?.items?.some(
    (x) =>
      (paramTooltipFirst && !!x[paramTooltipFirst]) ||
      (paramTooltipSecond && !!x[paramTooltipSecond])
  )
    ? ["dataMin", "dataMax"]
    : [domainStart ?? "dataMin", domainEnd ?? "dataMax"];

  // Here we remove duplicate dates
  const uniqueTicks =
    intervalStr === (UnitTypes.WEEK as string)
      ? ticks.reduce((init: number[], item): number[] => {
          const sameDates = init.filter((x) => {
            return (
              dayjs(new Date(x * EDUCATION_TO_TIME)).format(
                DEFAULT_DATE_FORMAT
              ) ===
              dayjs(new Date(item * EDUCATION_TO_TIME)).format(
                DEFAULT_DATE_FORMAT
              )
            );
          });
          if (!sameDates.length) {
            return [...init, item];
          }
          return init;
        }, [])
      : [...ticks, lastValueTimestamp ?? 0];

  const tickFormatter = useCallback(
    (value: number) => {
      const formatDay = dayjs(value * EDUCATION_TO_TIME).format(
        TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE
      );
      const formatWeek = dayjs(value * EDUCATION_TO_TIME).format(
        THE_DAY_OF_THE_WEEK
      );
      const formatDefault = dayjs(value * EDUCATION_TO_TIME).format(
        THE_DAY_OF_THE_MONTH
      );
      if (intervalStr === UNIT_TYPE_DAY) {
        return formatDay;
      } else if (intervalStr === UNIT_TYPE_WEEK) {
        return formatWeek;
      } else {
        return formatDefault;
      }
    },
    [intervalStr]
  );

  const dataForChart = data?.items.map((dataItem) => {
    const newDataItem = {
      ...dataItem,
      averageValue: MOCKED_AVERAGE_PRESSURE,
    };
    if (paramTooltipFirst && !newDataItem[paramTooltipFirst]) {
      newDataItem[paramTooltipFirst] = 0;
    }
    if (paramTooltipSecond && !newDataItem[paramTooltipSecond]) {
      newDataItem[paramTooltipSecond] = 0;
    }
    return newDataItem;
  });

  const isPressure = paramTooltipFirst === PRESSURE_KEY_NAME;

  const checkMaxValues = (param: ParamTooltipTypes) => {
    const maxValue = maxBy(
      data?.items || [],
      (v) => v?.[param as ParamTooltipTypes]
    )?.[param];

    const maxAllowedValue =
      chartMaxValues[param as keyof typeof chartMaxValues];

    if ((maxValue || 0) >= maxAllowedValue) {
      return true;
    }
    return false;
  };

  return (
    <SheduleDayStyled>
      {isLoading && <SystemLoader />}
      {!isLoading && (
        <ChartsUpdate key={updateKey}>
          <ResponsiveContainer width="100%" height={220}>
            <AreaChart
              data-testid="AreaChart"
              data={dataForChart ?? []}
              width={500}
              height={400}
              margin={{
                top: 10,
                right: 0,
                left: -20,
                bottom: 0,
              }}
            >
              <defs>
                {renderGradient ? (
                  <linearGradient
                    data-testid={`linearGradient ${renderGradient}`}
                    id="colorPv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={stopColorGradientFirst}
                      stopOpacity={stopOpacityGradientFirst}
                    />
                    <stop
                      offset="95%"
                      stopColor={stopColorGradientFirst}
                      stopOpacity={stopOpacityGradientSecond}
                    />
                  </linearGradient>
                ) : (
                  <>
                    <linearGradient
                      data-testid="linearGradientSecond"
                      id="colorPv"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={stopColorGradientFirst}
                        stopOpacity={stopOpacityGradientFirst}
                      />
                      <stop
                        offset="95%"
                        stopColor={stopColorGradientFirst}
                        stopOpacity={stopOpacityGradientSecond}
                      />
                    </linearGradient>
                    <linearGradient
                      data-testid="linearGradientThird"
                      id="colorUv"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={stopColorGradientSecond}
                        stopOpacity={stopOpacityGradientSecond}
                      />
                      <stop
                        offset="95%"
                        stopColor={stopColorGradientSecond}
                        stopOpacity={stopOpacityGradientSecond}
                      />
                    </linearGradient>
                  </>
                )}
              </defs>
              <CartesianGrid
                data-testid="CartesianGrid"
                strokeDasharray="3 3"
              />
              <XAxis
                data-testid="XAxis"
                dataKey="timestamp"
                type="number"
                domain={["dataMin", "dataMax"]}
                tick={{ fontSize: 12, fontWeight: 500, fill: Colors.silver }}
                tickMargin={20}
                axisLine={false}
                tickSize={0}
                ticks={uniqueTicks}
                tickFormatter={tickFormatter}
              />
              <YAxis
                data-testid="YAxis"
                type="number"
                domain={yAxisDomain}
                axisLine={false}
                tickLine={false}
                tickCount={tickCount}
                tick={{ fontSize: 12, fontWeight: 500, fill: Colors.silver }}
                tickFormatter={(number) => `${(number ?? 0).toFixed(0)}`}
              />
              {isPressure && (
                <Area
                  data-testid="RenderArea"
                  dataKey={`averageValue`}
                  stroke={stopColorGradientFirst}
                  fillOpacity={1}
                  fill="transparent"
                  activeDot={{
                    r: 0,
                    strokeWidth: 2,
                    stroke: `${stopColorGradientFirst}`,
                    fill: Colors.white,
                  }}
                />
              )}
              <Tooltip
                data-testid="Tooltip"
                content={
                  <CustomTooltip
                    renderSingleValue={renderSingleValue}
                    classNameTooltipFirst={classNameTooltipFirst}
                    classNameTooltipSecond={classNameTooltipSecond}
                    paramNameTooltipFirst={paramNameTooltipFirst}
                    paramNameTooltipSecond={paramNameTooltipSecond}
                    paramTooltipFirst={paramTooltipFirst}
                    paramTooltipSecond={paramTooltipSecond}
                  />
                }
              />

              {renderArea ? (
                <Area
                  data-testid="RenderArea"
                  name={paramTooltipFirst}
                  dataKey={`${paramTooltipFirst}`}
                  stroke={stopColorGradientFirst}
                  fillOpacity={1}
                  fill="url(#colorPv)"
                  activeDot={{
                    r: 6,
                    strokeWidth: 2,
                    stroke: `${stopColorGradientFirst}`,
                    fill: Colors.white,
                  }}
                />
              ) : (
                <>
                  <Area
                    data-testid="RenderArea"
                    name={paramTooltipFirst}
                    dataKey={`${paramTooltipFirst}`}
                    stroke={stopColorGradientFirst}
                    fillOpacity={1}
                    fill="url(#colorPv)"
                    activeDot={{
                      r: 6,
                      strokeWidth: 2,
                      stroke: `${stopColorGradientFirst}`,
                      fill: Colors.white,
                    }}
                  />
                  <Area
                    data-testid="SecondRenderArea"
                    name={paramTooltipSecond}
                    dataKey={`${paramTooltipSecond}`}
                    stroke={stopColorGradientSecond}
                    strokeWidth="2"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                    activeDot={{
                      r: 6,
                      stroke: `${stopColorGradientSecond}`,
                      fill: Colors.white,
                    }}
                  />
                </>
              )}
              {!!paramTooltipFirst && checkMaxValues(paramTooltipFirst) && (
                <ReferenceLine
                  style={{ fontSize: 12 }}
                  y={
                    paramTooltipFirst
                      ? chartMaxValues[
                          paramTooltipFirst as keyof typeof chartMaxValues
                        ]
                      : undefined
                  }
                  stroke="red"
                />
              )}
              {!!paramTooltipSecond && checkMaxValues(paramTooltipSecond) && (
                <ReferenceLine
                  style={{ fontSize: 12 }}
                  y={
                    paramTooltipSecond
                      ? chartMaxValues[
                          paramTooltipSecond as keyof typeof chartMaxValues
                        ]
                      : undefined
                  }
                  stroke="red"
                />
              )}
            </AreaChart>
          </ResponsiveContainer>
        </ChartsUpdate>
      )}
    </SheduleDayStyled>
  );
};

const SheduleDayStyled = styled(Box)(({ theme }) => ({
  ".ScheduleDay-indent": { padding: "0 15px 20px 22px", margin: "-20px 0 0 0" },
  ".custom-tooltip": {
    width: "120px",
    height: "auto",
    boxShadow: "5px 4px 16px rgba(0, 0, 0, 0.07)",
    backgroundColor: Colors.white,
    padding: "8px",
    paddingBottom: "12px",
    zIndex: 222,
  },
  ".custom-tooltip .time-tooltip,\n.custom-tooltip .date-tooltip": {
    color: Colors.dimGray,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
  },
  ".custom-tooltip .date-tooltip": {
    position: "absolute",
    top: "8px",
    right: "8px",
  },
  ".custom-tooltip .param": { marginBottom: "6px" },
  ".custom-tooltip .param:last-of-type": { marginBottom: "0" },
  ".custom-tooltip .param-1,\n.custom-tooltip .param-2": {
    position: "relative",
    marginLeft: "18px",
    color: Colors.dimGray,
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
  },
  ".custom-tooltip .param-1::before,\n.custom-tooltip .param-2::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "10px",
    height: "10px",
    left: "-18px",
  },
  ".custom-tooltip .param-1.humidity": {
    display: "flex",
    whiteSpace: "nowrap",
    paddingLeft: "18px",
    backgroundColor: "transparent",
    marginLeft: "0px",
  },
  ".custom-tooltip .param-1.humidity::before": {
    backgroundColor: Colors.heavenly,
    left: "0",
    marginBottom: "7px",
  },
  ".custom-tooltip .param-1.co2::before": {
    backgroundColor: Colors.peachCobbler,
    marginBottom: "7px",
  },
  ".custom-tooltip .param-2.voc::before": {
    backgroundColor: Colors.blue,
  },
  ".custom-tooltip .param-1.production::before": {
    backgroundColor: Colors.blue,
    marginBottom: "7px",
  },
  ".custom-tooltip .param-2.used::before": {
    backgroundColor: Colors.peachCobbler,
    marginBottom: "7px",
  },
  ".custom-tooltip .param-1.temp::before": {
    backgroundColor: Colors.brightGreen,
  },
  ".custom-tooltip .param-1.pressure::before": {
    backgroundColor: Colors.bicycleYellow,
  },
  ".custom-tooltip .param-1.radon::before": {
    backgroundColor: Colors.skylLightBlue,
  },
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const ChartsUpdate = styled(Box)(({ theme }) => ({
  padding: "0",
  [theme.breakpoints.down("md")]: {
    overflow: "hidden",
    width: "500px",
  },
}));

export default ScheduleCalendar;
