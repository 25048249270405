import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { CaretUp, CaretDown } from "src/assets/icons";
import {
  UnitTypes,
  EDUCATION_TO_TIME,
  TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE,
  roseTooltipHeaderClasses as headerClasses,
} from "src/system/constants";
import { PairedString } from "src/types/appTypes";

const DEFAULT_ENTRY_WRAPPER_CLASS = "rose-tooltip";

interface CustomTooltipEntryProps {
  agregatedData?: number;
  localValues: PairedString;
  unitType: UnitTypes;
  wrapperClass?: string;
  weekNumber?: number;
}

const CustomTooltipEntry: React.FC<CustomTooltipEntryProps> = ({
  agregatedData = 0,
  localValues,
  unitType,
  wrapperClass = DEFAULT_ENTRY_WRAPPER_CLASS,
  weekNumber = 0,
}) => {
  const daytimeString = dayjs(agregatedData * EDUCATION_TO_TIME).format(
    TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE
  );

  return (
    <Typography className={wrapperClass}>
      <Typography className={headerClasses[unitType]}>
        {unitType === UnitTypes.DAY ? (
          daytimeString
        ) : unitType === UnitTypes.WEEK ? (
          <>
            <Typography className="current-week-color"></Typography>Current Week
          </>
        ) : (
          <>
            <Typography
              className={`box-tooltip week${weekNumber}`}
            ></Typography>
            <Typography>{`Week ${weekNumber}`}</Typography>
          </>
        )}
      </Typography>
      <Typography className="top">
        <Typography className="wrapUp">
          <CaretUp />
        </Typography>
        {localValues.highest}
      </Typography>
      <Typography className="down">
        <Typography className="wrapDown">
          <CaretDown />
        </Typography>
        {localValues.lowest}
      </Typography>
    </Typography>
  );
};

export default CustomTooltipEntry;
