import ProgressBar from "react-customizable-progressbar";
import { Box, styled, Typography } from "@mui/material";
import {
  Colors,
  EnergyTypes,
  StatPosition,
  EnergyUnitTypes,
  HOUR,
  HOURS_TO_SUBTRACT,
  DEFAULT_DEVICE_ID,
  DEFAULT_PROJECT_TRANSITION,
  DEFAULT_TRACK_TRANSITION,
  UNIT_TYPE_DAY,
  MINUTE,
  thousand,
  DECIMAL_PLACES,
  thirtyThousand,
} from "src/system/constants";
import { CSSObject } from "@emotion/styled";
import { getLastExistingValueInArrayofObjects } from "../../../utils/getLastExistingValueInArrayofObjects";
import {
  Datatype,
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import dayjs from "dayjs";
import { MyObject } from "../../../types/appTypes";

const DEFAULT_PROGRESSBAR_RADIUS = 100;
const PROGRESSBAR_STROKE_WIDTH = 3;
const PROGRESSBAR_POINTER_STROKE_WIDTH = 4;
const PROGRESSBAR_POINTER_RADIUS_DEFAULT = 14;
const PROGRESSBAR_POINTER_RADIUS_SMALL = 10;
const REFERENCE_NAME_SUFFIX = "_counter";

interface EnergyInfoStatProps {
  type: EnergyTypes;
  position?: StatPosition;
  strokeColor?: string;
  power?: boolean;
  inVolProps?: boolean;
}

const EnergyInfoStat: React.FC<EnergyInfoStatProps> = ({
  type,
  position = StatPosition.SINGLE,
  strokeColor = Colors.blue,
  power,
  inVolProps,
}) => {
  const { data: DataEnergy } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: power
      ? Datatype.PowerDelivery
      : Datatype.MaximumPowerPointTracking,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const inVolData = DataEnergy?.[DataEnergy?.length - 1];
  const inVol = Number(
    ((inVolData?.in_vol ?? 0) / thousand).toFixed(DECIMAL_PLACES)
  );

  const lastTimestamp = (
    getLastExistingValueInArrayofObjects(
      DataEnergy as unknown as MyObject[],
      type
    ) ?? 0
  ).toFixed(DECIMAL_PLACES);

  const latestDataNumber = parseFloat(lastTimestamp);

  const unit =
    type === EnergyTypes.VOL || type === EnergyTypes.IN_VOL
      ? EnergyUnitTypes.V
      : EnergyUnitTypes.W;

  return (
    <EnergyInfoStatItem elementPosition={position}>
      <ProgressBar
        progress={latestDataNumber}
        steps={inVolProps ? thirtyThousand : undefined}
        radius={DEFAULT_PROGRESSBAR_RADIUS}
        strokeWidth={PROGRESSBAR_STROKE_WIDTH}
        strokeColor={strokeColor}
        trackStrokeWidth={PROGRESSBAR_STROKE_WIDTH}
        pointerRadius={
          position !== StatPosition.COLUMN_SECOND
            ? PROGRESSBAR_POINTER_RADIUS_DEFAULT
            : PROGRESSBAR_POINTER_RADIUS_SMALL
        }
        pointerStrokeWidth={PROGRESSBAR_POINTER_STROKE_WIDTH}
        pointerStrokeColor={strokeColor}
        initialAnimation={false}
        transition={DEFAULT_PROJECT_TRANSITION}
        trackTransition={DEFAULT_TRACK_TRANSITION}
      />
      <EnergyInfoStatValue elementPosition={position}>
        <Typography>{inVolProps ? inVol : lastTimestamp}</Typography>
        <span>{unit}</span>
      </EnergyInfoStatValue>
    </EnergyInfoStatItem>
  );
};

const EnergyInfoStatItem = styled(Box)<{ elementPosition: StatPosition }>(
  ({ elementPosition }) => {
    if (elementPosition !== StatPosition.COLUMN_SECOND) {
      return {
        width: "151px",
        height: "151px",
        margin: "auto",
        "& svg": {
          width: "151px",
          height: "151px",
        },
        ".RCP ": {
          width: "auto !important",
          lineHeight: 0,
        },
      };
    } else {
      return {
        position: "absolute",
        top: "77px",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "105px !important",
        height: "105px !important",
        "& svg": {
          width: "105px !important",
          height: "105px !important",
        },
        ".RCP ": {
          width: "auto !important",
          lineHeight: 0,
        },
      };
    }
  }
);

const EnergyInfoStatValue = styled(Typography)<{
  elementPosition: StatPosition;
}>(({ elementPosition }) => {
  const defaultStyles = {
    position: "absolute",
    top: "43%",
    left: "0",
    right: "0",
    fontSize: "18px",
    lineHeight: "21px",
    color: Colors.lightBlack,
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    gap: "2px",
    "& p": {
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "inherit",
    },
  };
  if (elementPosition === StatPosition.COLUMN_FIRST) {
    defaultStyles.top = "59px";
    defaultStyles.lineHeight = "16px";
  } else if (elementPosition === StatPosition.COLUMN_SECOND) {
    defaultStyles.top = "52%";
    defaultStyles.fontSize = "14px";
    defaultStyles.lineHeight = "16px";
    defaultStyles.color = Colors.gray;
  }
  return defaultStyles as CSSObject;
});

export default EnergyInfoStat;
