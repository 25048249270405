import { systemVariables } from "src/system/constants";
import useSWR from "swr";
import { fetcherJson } from 'src/system/api';

const useGetStatusData = () => {

  const apiPath = `${systemVariables.apiBaseUrl}/devices`;

  const { data, mutate } = useSWR(
    [
      apiPath,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      },
    ],
    fetcherJson
  );

  let defaultValue: unknown = "";

  return { isLoading: false, data : data?data: defaultValue, error: "", mutate };
};

export default useGetStatusData;
