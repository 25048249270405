import {
  UNIT_TYPE_DAY,
  UNIT_TYPE_MONTH,
  UNIT_TYPE_WEEK,
} from "../system/constants";
import dayjs, { Dayjs } from "dayjs";

export enum DateJSUnitOfTime {
  START_OF = "startOf",
  END_OF = "endOf",
}

const getCalendarDateParameter = (
  type: DateJSUnitOfTime,
  unix: boolean,
  kind?: string,
  date?: Dayjs | null
) => {
  if (!date) return;
  let newDate = dayjs();
  if (kind === UNIT_TYPE_DAY) {
    newDate = date?.[type](UNIT_TYPE_DAY);
  } else if (kind === UNIT_TYPE_WEEK) {
    newDate = date?.[type](UNIT_TYPE_WEEK);
  } else if (kind === UNIT_TYPE_MONTH) {
    newDate = date?.[type](UNIT_TYPE_MONTH);
  } else {
    newDate = date?.[type](UNIT_TYPE_DAY);
  }

  return unix ? newDate?.unix() : newDate;
};

export default getCalendarDateParameter;
