import { LabelName, UnitTypes } from "src/system/constants";
import { GetRadarDataItem } from "src/types/apiTypes";
import useChartNameToTypeString from "src/utils/useChartNameToTypeString";
import CustomTooltipEntry from "./CustomTooltipEntry";
import { Typography } from "@mui/material";
import { PairedString } from "src/types/appTypes";

interface CustomTooltipProps {
  unitType: UnitTypes;
  data?: GetRadarDataItem;
  label?: LabelName;
  active?: string;
  payload?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  unitType,
  data,
  label,
  active,
  payload,
}) => {
  const localValues: PairedString[] = useChartNameToTypeString(
    unitType,
    data,
    label
  );

  const agregatedData = data?.aggregated_data?.timestamp ?? 0;

  return localValues.length === 1 ? (
    <CustomTooltipEntry
      agregatedData={agregatedData}
      localValues={localValues[0]}
      unitType={unitType}
    />
  ) : (
    <Typography className="rose-tooltip">
      {localValues.map((highestLowestStrings, index) => (
        <CustomTooltipEntry
          localValues={highestLowestStrings}
          unitType={unitType}
          wrapperClass="wrap-param"
          weekNumber={index + 1}
        />
      ))}
    </Typography>
  );
};

export default CustomTooltip;
