import React, { useState, useEffect } from "react";
import { Box, Pagination, styled } from "@mui/material";
import { Colors } from "src/system/constants";
import {
  useGetNotificationsPaginateQuery,
  useUpdateNotificationsReadAllMutation,
} from "../../../state/reducers/notificationsApi";
import NotificationTable from "./NotificationTable";

const NotificationsPage = () => {
  const [page, setPage] = useState(1);
  const { data } = useGetNotificationsPaginateQuery(page);
  const [updateNotificationsReadAll] = useUpdateNotificationsReadAllMutation();

  useEffect(() => {
    if (data?.notifications.length) {
      updateNotificationsReadAll(null);
    }
  }, [updateNotificationsReadAll, data]);

  return (
    <NotificationSection>
      <h1>Notifications</h1>
      <>
        {data?.notifications.length ? (
          <NotificationTable data={data.notifications} />
        ) : (
          <NotificationNoData>No data available</NotificationNoData>
        )}
        <NotificationPagination>
          <Pagination
            count={data?.total_pages || 0}
            onChange={(event, page) => setPage(page)}
            page={page}
          />
        </NotificationPagination>
      </>
    </NotificationSection>
  );
};

const NotificationSection = styled(Box)(({ theme }) => ({
  h1: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    color: Colors.lightBlack,
    marginBottom: "24px",
    paddingBottom: "0",
  },
}));

const NotificationPagination = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: "30px 0 0 0",
  "& button.Mui-selected": {
    background: "none",
  },
  "& .Mui-selected span": {
    background:
      "linear-gradient(90deg, rgba(133, 199, 222, 0.2) 0%, rgba(244, 248, 255, 0) 132.08%)",
  },
});

const NotificationNoData = styled(Box)({
  fontSize: "20px",
  lineHeight: "22px",
  padding: "30px 0 0 0",
  textAlign: "center",
});

export default NotificationsPage;
