import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { postApi } from "src/state/reducers/postApi";
import userReducer from "./reducers/UserSlice";
import notificationsApi from "./reducers/notificationsApi";

const rootReducer = combineReducers({
  userReducer,
  [postApi.reducerPath]: postApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      postApi.middleware,
      notificationsApi.middleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
