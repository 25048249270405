import Calendar from "./CalendarHumidity";
import { TabProps } from "../components/Switches";
import { Box, styled } from "@mui/material";
import Zone from "./Zone";
import { HumidityZonePicIcon } from "src/assets/icons";
import { Colors } from "src/system/constants";

const Humidity: React.FC<TabProps> = (props: TabProps) => {
  return (
    <Box>
      <HumidityBlockStyled>
        <Zone
          tab={props.tab}
          setTab={props.setTab}
          name="Humidity"
          image={<HumidityZonePicIcon />}
          type="humidity"
        />
        <Calendar />
      </HumidityBlockStyled>
    </Box>
  );
};

const HumidityBlockStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  ".Humidity__block": { display: "flex" },
  ".Humidity__block1": { display: "flex", justifyContent: "space-between" },
  ".Humidity__bottom": { display: "flex", justifyContent: "space-between" },
  ".Humidity__bottom-temperature": {
    fontSize: "20px",
    lineHeight: "23px",
    color: Colors.dimGray,
    padding: "23px 0 0 0",
  },
  ".Humidity__bottom-temperature span": {
    color: Colors.silver,
    display: "inline-block",
    padding: "0 4px 0 4px",
  },
  ".Humidity__bottom-data": {
    fontSize: "20px",
    lineHeight: "23px",
    color: Colors.dimGray,
    padding: "23px 0 0 0",
  },
  ".Humidity__bottom-data span": {
    color: Colors.silver,
    display: "inline-block",
    padding: "0 4px 0 4px",
  },
}));

export default Humidity;
