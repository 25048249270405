import React, { useEffect, useState } from "react";
import {
  DeviceStatus,
  Colors,
  NO_DATA_VALUE,
  DeviceColumnTitle,
  DATE_FORMAT_CONNECTION,
  DATE_FORMAT_TOTAL_TIME,
} from "src/system/constants";
import { NavLink } from "react-router-dom";
import { Box, styled } from "@mui/material";
import DeviceItemColumn from "./DeviceItemColumn";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const GO_TO_DEVICE_STR = "Go to device";
const DEVICE_IMG_ALT = "Device Image";
const INTERVAL_DURATION = 1000;

interface DeviceItemProps {
  title?: string;
  image?: string;
  link?: string;
  name?: string;
  type?: string;
  connection?: string;
  dataTestId?: string;
  status?: DeviceStatus;
}

const DeviceItem: React.FC<DeviceItemProps> = ({
  title,
  image,
  link,
  name = NO_DATA_VALUE,
  type = NO_DATA_VALUE,
  connection,
  dataTestId,
  status = DeviceStatus.OFF,
}) => {
  const [formattedTotalTime, setFormattedTotalTime] =
    useState<string>(NO_DATA_VALUE);
  const checkColor = (status: DeviceStatus): string => {
    if (status === DeviceStatus.ON) {
      return Colors.greenMana;
    } else {
      return Colors.highlighterRed;
    }
  };

  const connectionDateStr = connection ?? NO_DATA_VALUE;
  useEffect(() => {
    const updateTotalTime = () => {
      const newTotalTimeStr =
        dayjs
          .duration(Math.abs(dayjs(connection, DATE_FORMAT_CONNECTION).diff()))
          .format(DATE_FORMAT_TOTAL_TIME) ?? NO_DATA_VALUE;

      setFormattedTotalTime(newTotalTimeStr);
    };

    updateTotalTime();

    const intervalId = setInterval(updateTotalTime, INTERVAL_DURATION);

    return () => clearInterval(intervalId);
  }, [connection, DATE_FORMAT_CONNECTION]);

  return (
    <DeviceItemWrapper>
      <DeviceItemsPicture>
        <img src={image} alt={DEVICE_IMG_ALT} />
      </DeviceItemsPicture>
      <DeviceItemsSection>
        <DeviceItemsBlock>
          <Box>
            <DeviceItemsTitle>{title}</DeviceItemsTitle>
          </Box>
          {!!link && (
            <DeviceItemStatus fontColor={checkColor(status)}>
              {status}
            </DeviceItemStatus>
          )}
        </DeviceItemsBlock>
        <DeviceItemRow>
          <DeviceItemColumn
            title={DeviceColumnTitle.NAME}
            value={name}
            color={checkColor(status ?? DeviceStatus.OFF)}
          />
          <DeviceItemColumn
            title={DeviceColumnTitle.DEVICE_TYPE}
            value={type}
          />
          <DeviceItemColumn
            title={DeviceColumnTitle.CONNECTION_DATE}
            value={connectionDateStr}
          />
          <DeviceItemColumn
            title={DeviceColumnTitle.TOTAL_TIME}
            value={formattedTotalTime}
          />
        </DeviceItemRow>
        {!!link && (
          <DeviceFormButtonRight>
            <DeviceFormButton data-testid={dataTestId}>
              <NavLink to={link}>{GO_TO_DEVICE_STR}</NavLink>
            </DeviceFormButton>
          </DeviceFormButtonRight>
        )}
      </DeviceItemsSection>
    </DeviceItemWrapper>
  );
};

const DeviceItemsPicture = styled(Box)(({ theme }) => ({
  flex: "0 0 176px",
  margin: "0 25px 0 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRight: `1px solid ${Colors.lightWhite}`,
  "& img": {
    maxWidth: "140px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "110px",
    },
  },
  [theme.breakpoints.down("lg")]: {
    flex: "0 0 140px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: 0,
    borderRight: `none`,
  },
}));

const DeviceItemsSection = styled(Box)({
  width: "100%",
});

const DeviceItemStatus = styled(Box)<{ fontColor: string }>(
  ({ fontColor }) => ({
    fontSize: "12px",
    lineHeight: "14px",
    color: fontColor,
    border: "none",
    background: Colors.aliceMint,
    width: "84px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
  })
);

const DeviceItemsBlock = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    button: {
      position: "absolute",
      top: -2,
      right: -12,
    },
  },
}));

const DeviceItemsTitle = styled(Box)({
  fontSize: "16px",
  lineHeight: "18px",
  color: Colors.lightBlack,
  fontWeight: 500,
});

const DeviceItemRow = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  columnGap: "20px",
  rowGap: "10px",
  border: "1px solid #f2f2f2",
  padding: "23px 20px 18px 23px",
  margin: "16px 0 0 0",
  borderRadius: "3px",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "space-between",
    columnGap: "0",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const DeviceItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "100%",
  padding: "18px 28px 16px 10px",
  background: Colors.white,
  margin: "0 0 24px 0",
  "&:last-child": {
    margin: "0",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "18px 0px 32px 0px",
  },
}));

const DeviceFormButtonRight = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const DeviceFormButton = styled(Box)({
  display: "flex",
  fontSize: "16px",
  lineHeight: "18px",
  fontWeight: 500,
  flex: "0 0 153px",
  margin: "16px 0 0 0",
  textAlign: "center",
  justifyContent: "center",
  transition: "all 0.3s linear",
  height: "fit-content",
  background: Colors.cloudy,
  "&:hover": {
    background: Colors.blueDark,
  },
  "& a": {
    color: Colors.white,
    display: "block",
    padding: "9px 31px 8px 31px",
  },
  "& a:hover": {
    color: Colors.white,
  },
});

export default DeviceItem;
