import React from "react";
import Calendar from "./CalendarTemperature";
import { TabProps } from "../components/Switches";
import { Box, styled } from "@mui/material";
import Zone from "./Zone";
import { TemperaturePicIcon } from "src/assets/icons";

const TempSensor: React.FC<TabProps> = (props: TabProps) => {
  return (
    <Box>
      <HumidityBlock>
        <Zone
          tab={props.tab}
          setTab={props.setTab}
          name="Temperature"
          image={<TemperaturePicIcon />}
          type="temperature"
        />
        <Calendar />
      </HumidityBlock>
    </Box>
  );
};

const HumidityBlock = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export default TempSensor;
