import React from "react";
import { styled } from "@mui/material";
import { TabUnstyled, TabUnstyledProps } from "@mui/base";
import { Colors } from "src/system/constants";

export default function CustomTab (props: TabUnstyledProps) {
    return <StyledTab {...props}>{props.children}<span>Beta</span></StyledTab>;
};

const StyledTab = styled(TabUnstyled)({
    width: "100%",
    maxWidth: "25%",
    height: "33px",
    padding: "0 0 16px 0",
    background: "none",
    backgroundColor: Colors.transparent,
    border: "none",
    borderBottom: "2px solid transparent",
    textAlign: "center",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "20px",
    verticalAlign: "top",
    color: Colors.dimGray,
    "&:hover:not(.Mui-disabled)": {
        cursor: "pointer"
    },
    "&.Mui-selected": {
        color: Colors.skyBlue,
        borderBottom: "2px solid" + Colors.skyBlue
    },
    span: {
        visibility: "collapse",
        position: "absolute",
        width: 37,
        verticalAlign: "top",
        margin: "-9px 0px 0px 3px",
        padding: "1.5px 8px 2.5px 8px",
        fontSize: 10,
        fontWeight: 500,
        lineHeight: "11.72px",
        color: Colors.white,
        textAlign: "center",
        borderRadius: 5,
        backgroundColor: Colors.lightBlue
    },
    "&.Mui-disabled": {
        color: Colors.kinglyCloud,
        span: {
            visibility: "visible"
        }
    }
});
