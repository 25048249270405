import React from "react";
import { ChartProps } from "src/routes/ConnectedSensors/components/ConnectedPage";
import { Box, styled, Typography } from "@mui/material";
import {
  Colors,
  DEFAULT_Y_AXIS_DOMAIN_CO2,
  DEFAULT_Y_AXIS_DOMAIN_VOC,
  ParamTooltipTypes,
  units,
  SensorChartGraphHeight,
} from "src/system/constants";
import ConnectedChart from "./ConnectedChart";

const ConnectedChartsCo2Voc: React.FC<ChartProps> = () => (
  <>
    <ConnectPmBlock>
      <ChartBox>
        <Typography>
          CO2 <span>ppm</span> / VOC <span>ppb</span>
        </Typography>
      </ChartBox>
    </ConnectPmBlock>
    <ConnectPmFlex>
      <Relative>
        <ConnectPmText>CO2</ConnectPmText>
      </Relative>
      <ConnectedChart
        averageChartItems={[
          { name: ParamTooltipTypes.CO2, unit: units.CO2 },
          { name: ParamTooltipTypes.VOC, unit: units.VOC },
        ]}
        chartType={ParamTooltipTypes.CO2}
        chartUnit={units.CO2}
        chartDomain={DEFAULT_Y_AXIS_DOMAIN_CO2}
        graphHeight={SensorChartGraphHeight.SMALL}
        strokeColor={Colors.lightSkyBlue}
        areaColor={Colors.lightSkyBlue}
      />
    </ConnectPmFlex>
    <ConnectPmIndent>
      <ConnectPmFlex>
        <Relative>
          <ConnectPmText>VOC</ConnectPmText>
        </Relative>
        <ConnectedChart
          chartType={ParamTooltipTypes.VOC}
          chartUnit={units.VOC}
          chartDomain={DEFAULT_Y_AXIS_DOMAIN_VOC}
          graphHeight={SensorChartGraphHeight.SMALL}
          strokeColor={Colors.lightSkyBlue}
          areaColor={Colors.lightSkyBlue}
        />
      </ConnectPmFlex>
    </ConnectPmIndent>
  </>
);

const ConnectPmBlock = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 0 5px 0",
});

const ConnectPmFlex = styled(Box)({
  padding: "0",
});

const ConnectPmText = styled(Typography)({
  fontSize: "14px",
  lineHeight: "18px",
  color: Colors.gray,
  position: "absolute",
  top: "30px",
  left: "-22px",
  width: "45px",
  textAlign: "right",
});

const ConnectPmNumber = styled(Typography)({
  fontSize: "16px",
  lineHeight: "18px",
  color: Colors.brightGreen,
  fontWeight: "500",
  flex: "0 0 28px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& span:first-child": {
    color: Colors.pink,
    display: "block",
    padding: "0 0 14px 0",
  },
});

const ChartBox = styled(Box)({
  padding: "0",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "& span": {
    color: Colors.gray,
  },
  "& p": {
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: "20px",
  },
});

const Relative = styled(Box)({
  position: "relative",
});

const ConnectPmIndent = styled(Box)({
  padding: "27px 0 0 0",
});

export default ConnectedChartsCo2Voc;
