import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { DetailsCalendarType } from "src/types/dashboardTypes";

const ParametrsDetailsCalendar: React.FC<DetailsCalendarType> = ({
  renderSingleValue,
  titleDetailsCalendar,
  firstClassNameDetailsCalendar,
  firstNameDetailsCalendar,
  secondClassNameDetailsCalendar,
  secondNameDetailsCalendar,
}) => {
  return (
    <Typography className="wrap__title">
      <TitleCalendar data-testid={titleDetailsCalendar}>
        {titleDetailsCalendar}
      </TitleCalendar>
      <Typography className="wrap__colors">
        {renderSingleValue ? (
          <Typography className="param__datails">
            <Typography
              className={`range ${firstClassNameDetailsCalendar}`}
            ></Typography>
            <Typography className="name__param">
              {firstNameDetailsCalendar}
            </Typography>
          </Typography>
        ) : (
          <>
            <Typography className="param__datails">
              <Typography
                className={`range ${firstClassNameDetailsCalendar}`}
              ></Typography>
              <Typography className="name__param">
                {firstNameDetailsCalendar}
              </Typography>
            </Typography>
            <Typography className="param__datails">
              <Typography
                className={`range ${secondClassNameDetailsCalendar}`}
              ></Typography>
              <Typography className="name__param">
                {secondNameDetailsCalendar}
              </Typography>
            </Typography>
          </>
        )}
      </Typography>
    </Typography>
  );
};

const TitleCalendar = styled(Box)({
  paddingLeft: "25px",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "21.09px",
});

export default ParametrsDetailsCalendar;
