import React from "react";
import { alpha, Box, ListItem, ListItemText, styled } from "@mui/material";
import { SensorTypeNotification } from "./assets/types/notificationsTypes";
import { Colors, notificationsData } from "../../../system/constants";

interface NotificationReferencePopupProps {
  errorType: SensorTypeNotification;
}

const NotificationReferencePopup: React.FC<NotificationReferencePopupProps> = ({
  errorType,
}) => {
  const notificationData =
    notificationsData[errorType as SensorTypeNotification.HIGH_VOC];

  if (!notificationData) return null;

  return (
    <ReferencePopup>
      <ReferenceTableItemTitle>
        {notificationData.title}
      </ReferenceTableItemTitle>
      <ReferenceTableItemList>
        {notificationData.items.map(
          (item: { value: string }, index: number) => (
            <ListItem key={index}>
              <ListItemText>
                <span></span>
                <b>{item.value}</b>
              </ListItemText>
            </ListItem>
          )
        )}
      </ReferenceTableItemList>
    </ReferencePopup>
  );
};

const ReferencePopup = styled(Box)({
  position: "absolute",
  top: "0",
  left: "60px",
  border: `1px solid ${Colors.lightWhite}`,
  background: Colors.white,
  zIndex: "100",
});

const ReferenceTableItemTitle = styled(Box)({
  fontSize: "10px",
  lineHeight: "12px",
  color: Colors.greenBlack,
  fontWeight: 500,
  padding: "0 0 0 13px",
  background: alpha(Colors.lightWhite, 0.1),
  textAlign: "left",
  height: "24px",
  display: "flex",
  alignItems: "center",
});

const ReferenceTableItemList = styled(Box)(({ theme }) => ({
  padding: "2px 0 0 0",
  margin: "0",
  listStyle: "none",
  flexGrow: 1,
  "& .css-tlelie-MuiListItemText-root": {
    marginTop: "0",
    marginBottom: "0",
  },
  "& li": {
    margin: "0 0 0 0",
    padding: "4px 14px 4px 14px",
    borderBottom: `1px solid ${Colors.lightWhite}`,
  },
  "& .MuiTypography-root": {
    fontSize: "10px",
    lineHeight: "12px",
    color: Colors.black,
    display: ["flex", "flex"],
    alignItems: ["center", "center"],
    justifyContent: "space-between",
    height: "auto",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  "& li:last-child": {
    marginBottom: "0",
    border: "none",
  },
  "& .MuiTypography-root span": {
    width: "10px",
    height: "10px",
    borderRadius: "6px",
    display: "block",
    marginRight: "8px",
    marginTop: "-2px",
    flex: "none",
  },
  "& li:nth-of-type(1) .MuiTypography-root span": {
    backgroundColor: Colors.pink,
  },
  "& li:nth-of-type(2) .MuiTypography-root span": {
    backgroundColor: Colors.bicycleYellow,
  },
  "& li:nth-of-type(3) .MuiTypography-root span": {
    backgroundColor: Colors.brightGreen,
  },
  "& .MuiTypography-root b": {
    fontSize: "10px",
    lineHeight: "12px",
    color: Colors.lightBlack,
    fontWeight: 400,
    flexGrow: 1,
  },
}));

export default NotificationReferencePopup;
