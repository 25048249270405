import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Curve,
  Tooltip,
} from "recharts";
import SystemLoader from "src/components/SystemLoader";
import { postApi } from "src/state/reducers/postApi";
import {
  Colors,
  DevicesIds,
  DeviceTypes,
  UnitTypes,
  ParamTooltipTypes,
  DEFAULT_Y_AXIS_DOMAIN_ROSE_MOUNTH,
  DEFAULT_GRADENT_OPACITY,
  DEFAULT_VALUE_TO_UP_ROSE,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT,
  sensorTypeNames,
  DEFAULT_RADAR_AGGREGATED_VALUE,
  DEFAULT_RADAR_HIGHEST_VALUE,
  DEFAULT_RADAR_LOWEST_VALUE,
  RADAR_LINE_TYPE,
  UNKNOWN_CHART_STRING,
} from "src/system/constants";
import { WeekKeys, RadarWeeklyData } from "src/types/connectedSensorsType";
import CustomTooltip from "./CustomTooltip";

const weekRadarColors = {
  [WeekKeys.FIRST_WEEK]: Colors.bicycleYellow,
  [WeekKeys.SECOND_WEEK]: Colors.heavenly,
  [WeekKeys.THIRD_WEEK]: Colors.pink,
  [WeekKeys.FOURTH_WEEK]: Colors.brightGreen,
  [WeekKeys.FIFTH_WEEK]: Colors.violet,
};

const ConnectedRoseMonth = () => {
  const { data: sensorData, isLoading } = postApi.useGetRadarDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.MONTH,
  });

  const radarDataset: Array<RadarWeeklyData> = Object.keys(
    sensorData?.first_week?.lowest_value ?? {}
  )
    .map((key) => {
      const allowedKeys = new Set(Object.values(ParamTooltipTypes));
      if (sensorData && allowedKeys.has(key as ParamTooltipTypes)) {
        const returnData: RadarWeeklyData = {
          chartType:
            sensorTypeNames.find(({ type }) => type === key)?.name ??
            UNKNOWN_CHART_STRING,
          [WeekKeys.FIRST_WEEK]: 0,
          [WeekKeys.SECOND_WEEK]: 0,
          [WeekKeys.THIRD_WEEK]: 0,
          [WeekKeys.FOURTH_WEEK]: 0,
          [WeekKeys.FIFTH_WEEK]: 0,
        };

        for (const week of Object.keys(sensorData)) {
          const allowedWeekKeys = new Set(Object.values(WeekKeys));
          if (allowedWeekKeys.has(week as WeekKeys)) {
            const weeklyData = sensorData[week as WeekKeys];
            const aggregatedData =
              weeklyData.aggregated_data?.[
                key as keyof typeof sensorData.first_week.lowest_value
              ] ?? DEFAULT_RADAR_AGGREGATED_VALUE;
            const lowestValue =
              weeklyData.lowest_value?.[
                key as keyof typeof sensorData.first_week.lowest_value
              ] ?? DEFAULT_RADAR_LOWEST_VALUE;
            const highValue =
              weeklyData.highest_value?.[
                key as keyof typeof sensorData.first_week.lowest_value
              ] ?? DEFAULT_RADAR_HIGHEST_VALUE;
            returnData[week as WeekKeys] =
              ((aggregatedData - lowestValue) * DEFAULT_VALUE_TO_UP_ROSE) /
              (highValue - lowestValue);
          }
        }

        return returnData;
      } else {
        return null;
      }
    })
    .filter((entry): entry is RadarWeeklyData => entry !== null);

  return (
    <>
      {isLoading && <SystemLoader />}
      {!isLoading && (
        <ResponsiveContainer width={284} height={284}>
          <RadarChart cx="50%" cy="50%" outerRadius="100%" data={radarDataset}>
            <PolarGrid stroke={Colors.skyViolet} gridType="polygon" />
            <PolarAngleAxis
              dataKey="chartType"
              style={{
                fontSize: "14px",
              }}
            />
            <PolarRadiusAxis
              tick={false}
              axisLine={false}
              tickCount={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT}
            />
            <PolarRadiusAxis
              angle={30}
              domain={DEFAULT_Y_AXIS_DOMAIN_ROSE_MOUNTH}
            />
            <Tooltip
              content={
                <CustomTooltip unitType={UnitTypes.MONTH} data={sensorData} />
              }
            />
            {Object.values(WeekKeys).map((week) => (
              <Radar
                name={`radar_${week}`}
                dataKey={week}
                stroke={weekRadarColors[week]}
                fill={weekRadarColors[week]}
                fillOpacity={DEFAULT_GRADENT_OPACITY}
                shape={<Curve type={RADAR_LINE_TYPE} />}
                dot={{
                  r: 1,
                  strokeWidth: 2,
                  fill: weekRadarColors[week],
                  stroke: weekRadarColors[week],
                  opacity: 1,
                }}
              />
            ))}
          </RadarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ConnectedRoseMonth;
