import React from "react";
import IotTooltip from "../../../components/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, styled } from "@mui/material";

interface ZoneResultDataItemProps {
  value: string | number;
  title: string;
  suffix: string;
  testid: string;
}

const INTERES = "%";

const ZoneResultDataItem: React.FC<ZoneResultDataItemProps> = ({
  value,
  title,
  suffix,
  testid,
}) => (
  <ZoneResultData data-testid={testid}>
    <Box>{suffix !== INTERES ? `${value} ${suffix}` : `${value}${suffix}`}</Box>
    <IotTooltip title={title}>
      <HelpOutlineOutlinedIcon color="info" />
    </IotTooltip>
  </ZoneResultData>
);

const ZoneResultData = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  position: "relative",
  span: {
    cursor: "pointer",
    marginTop: "-10px",
    svg: {
      fontSize: 16,
      path: {
        fill: "white !important",
      },
      margin: "0 !important",
    },
  },
});

export default ZoneResultDataItem;
