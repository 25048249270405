export const makeQueryStringFromObject = (obj: object) => {
  return Object.entries(obj)
    .map(([a, b]) => `${a}=${b}`)
    .join("&");
};

type FetchArgs = [string, any];

export const fetcherJson = async (...args: FetchArgs) => {
  return fetch(...args).then((res) => res.json());
}