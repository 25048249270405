import React, { useEffect, useState } from "react";
import ConnectedStationsSourcesOfConsumptionChart from "./ConnectedStationsSourcesOfConsumptionChart";
import dayjs, { Dayjs } from "dayjs";
import { Box, styled } from "@mui/material";
import ConnectedStationTitle from "./ConnectedStationTitle";

const ConnectedStationsSourcesOfConsumption = () => {
  const [date] = React.useState<Dayjs | null>(dayjs);

  const [updateKey, setUpdateKey] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => setUpdateKey((x) => x + 1), 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <ActiveReactiveEnergyBlock>
      <ConnectedStationTitle name="Sources of consumption" />
      <ConnectedStationsSourcesOfConsumptionChart date={date} key={updateKey} />
    </ActiveReactiveEnergyBlock>
  );
};

const ActiveReactiveEnergyBlock = styled(Box)(({ theme }) => ({
  padding: "0 0 0 0",
  [theme.breakpoints.down("xl")]: {
    "& h2": {
      fontSize: "20px",
      lineHeight: "22px",
    },
  },
  [theme.breakpoints.down("lg")]: {
    "& h2": {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
}));

export default ConnectedStationsSourcesOfConsumption;
