import React from "react";
import { ChartProps } from "src/routes/ConnectedSensors/components/ConnectedPage";
import { Box, styled, Typography } from "@mui/material";
import {
  Colors,
  DEFAULT_Y_AXIS_DOMAIN_RADON,
  ParamTooltipTypes,
  units,
  SensorChartGraphHeight,
} from "src/system/constants";
import ConnectedChart from "./ConnectedChart";

const ConnectedRadon: React.FC<ChartProps> = ({ data }) => (
  <>
    <ConnectedDioxideBox>
      <ChartBox>
        <Typography>
          Radon <ChartBoxText>Bq/m³</ChartBoxText>
        </Typography>
      </ChartBox>
    </ConnectedDioxideBox>
    <ConnectedChart
      averageChartItems={[
        { name: ParamTooltipTypes.RADON_ST_AVG, unit: units.Radon },
      ]}
      chartType={ParamTooltipTypes.RADON_ST_AVG}
      chartUnit={units.Radon}
      chartDomain={DEFAULT_Y_AXIS_DOMAIN_RADON}
      graphHeight={SensorChartGraphHeight.NORMAL}
      strokeColor={Colors.black}
      areaColor={Colors.black}
      grid
      yAxis
    />
  </>
);

const ConnectedDioxideBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 0 2px 0",
});

const ChartBox = styled(Box)({
  padding: "0",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "& p": {
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: "20px",
  },
});

const ChartBoxText = styled(Box)({
  padding: "0 0 0 0",
  display: "inline-block",
  color: Colors.gray,
});

export default ConnectedRadon;
