import React from "react";
import determineColor from "src/utils/determineColor";
import { sensorBoundsMap, SensorType } from "../../../system/constants";
import { Box } from "@mui/material";
import { Bounds } from "../../../types/connectedSensorsType";

interface ConnectedChartDataIndicatorProps {
  sensor: SensorType;
  value: number;
}

const ConnectedChartDataIndicator: React.FC<
  ConnectedChartDataIndicatorProps
> = ({ sensor, value }) => {
  const sensorBounds = sensorBoundsMap.get(sensor) as Bounds;
  const color = determineColor(
    value,
    sensorBounds?.greenBound,
    sensorBounds?.redBound,
    sensorBounds?.secondGreenBound
  );
  return (
    <Box
      sx={{
        width: 10,
        height: 10,
        marginRight: "8px",
        borderRadius: 5,
        backgroundColor: color,
      }}
    />
  );
};

export default ConnectedChartDataIndicator;
