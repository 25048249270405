export interface NotificationType {
  id: string;
  model: string;
  timestamp: number;
  error_type: SensorTypeNotification;
  data: number;
  status: string;
  read?: boolean;
}

export enum SensorTypeNotification {
  LOST_CONNECTION = "LostConnection",
  CHARGE_LEVEL = "ChargeLevel",
  HIGH_CO2 = "HighCO2",
  HIGH_VOC = "HighVOC",
  HIGH_HUMIDITY = "HighHumidity",
  LOW_HUMIDITY = "LowHumidity",
  HIGH_RADON = "HighRadon",
  HIGH_TEMPERATURE = "HighTemperature",
  LOW_TEMPERATURE = "LowTemperature",
  HIGH_PRESSURE = "HighPressure",
}

export interface NotificationData {
  total_pages: number;
  notifications: NotificationType[];
  current_page: number;
  total_notifications: number;
}
