import React from "react";
import {
  Box,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
} from "@mui/material";
import { Colors } from "src/system/constants";
import {
  NotificationType,
  SensorTypeNotification,
} from "./assets/types/notificationsTypes";
import { NoConnectionIcon } from "../../../assets/icons";
import { sensorTypeUnits } from "src/system/constants";
import { mapErrorTypeToText } from "../../../utils/getMapErrorTypeToText";
import ConnectedChartDataIndicatorNotification from "./ConnectedChartDataIndicatorNotification";
import NotificationReferencePopup from "./NotificationReferencePopup";

const MILLISECONDS_PER_SECOND = 1000;
const UNREAD = "Unread";

interface NotificationTableProps {
  data: NotificationType[];
}

const NotificationTable: React.FC<NotificationTableProps> = ({ data }) => {
  const formatTimestamp = (timestamp: number): string => {
    const date = new Date(Number(timestamp) * MILLISECONDS_PER_SECOND);
    return date.toLocaleString();
  };

  return (
    <NotificationTableSection>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>ErrorType</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((device) => {
              const sensorData =
                device.error_type === SensorTypeNotification.LOST_CONNECTION ? (
                  <span>
                    <NoConnectionIcon /> No Connection
                  </span>
                ) : device.error_type in sensorTypeUnits ? (
                  `${device.data} ${sensorTypeUnits[device.error_type]}`
                ) : (
                  "-"
                );

              return (
                <TableRow
                  key={device.timestamp}
                  className={device.status === UNREAD ? "active" : ""}
                >
                  <TableCell>{device.model}</TableCell>
                  <TableCell>{formatTimestamp(device.timestamp)}</TableCell>
                  <TableCell>
                    {mapErrorTypeToText(device.error_type, device.model)}
                  </TableCell>
                  <TableCell>
                    <TableCellIndicator>
                      {device.error_type ===
                      SensorTypeNotification.LOST_CONNECTION ? (
                        ""
                      ) : (
                        <ConnectedChartDataIndicatorNotification
                          sensor={device.error_type}
                          value={device.data}
                        />
                      )}
                      {sensorData}
                      <NotificationReferencePopup
                        errorType={device.error_type}
                      />
                    </TableCellIndicator>
                  </TableCell>
                  <TableCell>{device.status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </NotificationTableSection>
  );
};

const NotificationTableSection = styled(Box)(({ theme }) => ({
  padding: "0",
  "& .MuiTableContainer-root": {
    overflowX: "inherit",
  },
  "& tr.active": {
    background: Colors.brightWhite,
    position: "relative",
  },
  "& tr:hover": {
    background:
      "linear-gradient(90deg, rgba(133, 199, 222, 0.2) 0%, rgba(244, 248, 255, 0) 132.08%)",
  },
  "& tr.active td:nth-child(1):before": {
    content: "''",
    position: "absolute",
    top: "9px",
    bottom: "9px",
    left: 0,
    width: "6px",
    background: Colors.pink,
  },
  "& td": {
    padding: "14px 16px 15px 16px",
  },
  "& td:nth-child(1)": {
    fontWeight: "700",
  },
  "& .MuiTableRow-root": {
    position: "relative",
  },
  "& .MuiTableCell-head:nth-child(1)": {
    width: "21%",
  },
  "& .MuiTableCell-head:nth-child(2)": {
    width: "21%",
  },
  "& .MuiTableCell-head:nth-child(3)": {
    width: "21%",
  },
  "& .MuiTableCell-head:nth-child(4)": {
    width: "21%",
  },
  "& .MuiTableCell-head:nth-child(5)": {
    width: "16%",
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiTableContainer-root": {
      overflowX: "auto",
    },
  },
}));

const TableCellIndicator = styled(Box)({
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  position: "relative",
  cursor: "pointer",
  "& .MuiBox-root:nth-child(2)": {
    display: "none",
  },
  "&:hover .MuiBox-root:nth-child(2)": {
    display: "block",
  },
});

export default NotificationTable;
