import { ParamTooltipTypes, sensorTypeNames, UNKNOWN_CHART_STRING, DEFAULT_VALUE_ROSE_DATA, DEFAULT_RADAR_AGGREGATED_VALUE, DEFAULT_RADAR_LOWEST_VALUE, DEFAULT_RADAR_HIGHEST_VALUE, PERCENT_COEFFICIENT, MINIMUM_DISPLAY_VALUE, UnitTypes } from "src/system/constants";
import { GetRadarDataItem } from "src/types/apiTypes";
import { RadarData } from "src/types/connectedSensorsType";

const getDayWeekRadarDataset = (unitType: UnitTypes.DAY | UnitTypes.WEEK, data?: GetRadarDataItem): Array<RadarData> => {

    const radarDataset: Array<RadarData> = Object.keys(data?.lowest_value ?? {}).map((key) => {
    
      // Chart keys filtration
      const allowedKeys = new Set(Object.values(ParamTooltipTypes));
      if (data && allowedKeys.has(key as ParamTooltipTypes)) {
    
        // Finding corresponding label for char tkey
        const keyName = sensorTypeNames.find(({type}) => type === key)?.name ?? UNKNOWN_CHART_STRING;
    
        // Gathering basic values
        const aggregated_data = data.aggregated_data[key as keyof typeof data.lowest_value] ?? DEFAULT_RADAR_AGGREGATED_VALUE;
        const lowest_value = data.lowest_value[key as keyof typeof data.lowest_value] ?? DEFAULT_RADAR_LOWEST_VALUE;
        const highest_value = data.highest_value[key as keyof typeof data.lowest_value] ?? DEFAULT_RADAR_HIGHEST_VALUE;

        // Calculating % of current value in between lowest and highest values
        // x = 5; x: [5 10] => 0%
        // x = 8; x: [5 10] => 80%
        let currentChartValue = highest_value !== lowest_value ? ((aggregated_data - lowest_value) * PERCENT_COEFFICIENT / (highest_value - lowest_value)) : PERCENT_COEFFICIENT;
        // Adding additional value for day rose display so that it will stand out of minimal values even if we have 0%. Value range is adjsuted accordingly.
        if (unitType === UnitTypes.DAY) {
            currentChartValue += MINIMUM_DISPLAY_VALUE;
        }

        const currentChartSensorData: RadarData = {
            // required prop
            chartType: keyName,
            // placeholder values for day rose
            co2: DEFAULT_VALUE_ROSE_DATA,
            temperature: DEFAULT_VALUE_ROSE_DATA,
            voc: DEFAULT_VALUE_ROSE_DATA,
            pressure: DEFAULT_VALUE_ROSE_DATA,
            radon_st_avg: DEFAULT_VALUE_ROSE_DATA,
            humidity: DEFAULT_VALUE_ROSE_DATA,
            timestamp: data.aggregated_data.timestamp ?? DEFAULT_VALUE_ROSE_DATA,
            // value for all other roses
            value: currentChartValue,
            // overrides one of the day rose placeholder values
            // at the end of props because of its relativelly complex code
            [key as keyof typeof data.lowest_value]: currentChartValue
        };
        
        return currentChartSensorData;
      } else {
        // cant skip iteration for map cycle so we return null and then filter it out
        return null;
      }
    // filtering out the null entries
    }).filter((entry): entry is RadarData => entry !== null);

    return radarDataset;
};

export default getDayWeekRadarDataset;