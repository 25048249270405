import React, { useState, useRef, createContext, useEffect, useCallback } from "react";
import dayjs from "dayjs";

type ReportingFrequencyContextValue = {
  value: number;
  setValue?: Function;
  dateStart?: number | undefined;
  dateEnd?: number | undefined;
};

export const ReportingFrequencyContext =
  createContext<ReportingFrequencyContextValue>({
    value: 1,
  });

export const ReportingFrequencyProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [sliderminutes, setSliderminutes] = useState(1);
  const [dateStart, setDateStart] = useState<number | undefined>(undefined);
  const [dateEnd, setDateEnd] = useState<number | undefined>(undefined);
  const timer = useRef(null);

  const handleChartUpdate = useCallback(() => {
    if (timer && timer.current) clearInterval(timer.current);
    const setDates = () => {
      const newDateStart = dayjs().subtract(sliderminutes, "minutes").unix();
      const newDateEnd = dayjs().unix();
      setDateEnd(newDateEnd);
      setDateStart(newDateStart);
    };
    setInterval(setDates, sliderminutes * 60000);
    setDates();
  }, [sliderminutes]);

  useEffect(() => {
    handleChartUpdate();
  }, [sliderminutes, handleChartUpdate]);

  return (
    <ReportingFrequencyContext.Provider
      value={{
        value: sliderminutes,
        setValue: setSliderminutes,
        dateStart: dateStart,
        dateEnd: dateEnd,
      }}
    >
      {children}
    </ReportingFrequencyContext.Provider>
  );
};
