import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import roundDate from "src/utils/roundDate";
import { Box, styled, Typography } from "@mui/material";
import ConnectedChartTooltip from "./ConnectedChartTooltip";
import dayjs from "dayjs";
import { postApi } from "src/state/reducers/postApi";
import {
  Colors,
  ParamTooltipTypes,
  DEFAULT_DATE_FORMAT,
  DevicesIds,
  DeviceTypes,
  UnitTypes,
  DEFAULT_TICK_FORMAT,
  DEFAULT_GRADENT_OPACITY,
  DEFAULT_GRADENT_OPACITY_STOP,
  chartMaxValues,
} from "src/system/constants";
import calculateAverageChartData from "src/utils/calculateAverageChartData";
import { AxisDomain } from "recharts/types/util/types";
import { ConnectedChartAxisYTickCount } from "src/types/appTypes";
import { AverageChartItem } from "src/types/connectedSensorsType";
import SystemLoader from "../../../components/SystemLoader";
import NoData from "../../../components/NoData";
import { maxBy } from "lodash";

const TIMESTAMP_KEY = "timestamp";

const AXIS_Y_WIDTH = 65;

const TICK_INTERVAL = 100;
const TICK_MARGIN = 14;
const TICK_SIZE = 0;
const TEMPERATURE_UNIT = "°C";
const DAY = "day";

interface ConnectedChartProps {
  averageChartItems?: AverageChartItem[];
  chartType: ParamTooltipTypes;
  chartUnit: string;
  chartDomain: AxisDomain;
  graphHeight: number;
  strokeColor: string;
  areaColor: string;
  areaStartOpacity?: number;
  areaStopOpacity?: number;
  grid?: boolean;
  yAxis?: boolean;
  spaceBeforeUnit?: boolean;
}

const ConnectedChart: React.FC<ConnectedChartProps> = ({
  averageChartItems = [],
  chartType,
  chartUnit,
  chartDomain,
  graphHeight,
  strokeColor,
  areaColor,
  areaStartOpacity = DEFAULT_GRADENT_OPACITY,
  areaStopOpacity = DEFAULT_GRADENT_OPACITY_STOP,
  grid = false,
  yAxis = false,
  spaceBeforeUnit = true,
}) => {
  const baseDate = dayjs(
    dayjs().add(1, DAY).format(DEFAULT_DATE_FORMAT),
    DEFAULT_DATE_FORMAT
  );

  const { data: chartsData, isLoading } = postApi.useGetChartDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.DAY,
    dateStart: dayjs(baseDate).subtract(1, DAY).unix(),
    dateEnd: baseDate.unix(),
  });

  const averageLevelItems = averageChartItems.map((averageChartItem) => ({
    value: calculateAverageChartData(chartsData?.items, averageChartItem.name),
    unit: averageChartItem.unit,
  }));

  const chartsDataItemsExist = chartsData?.items && chartsData.items.length > 0;

  const checkMaxValues = (param: ParamTooltipTypes) => {
    const maxValue = maxBy(
      chartsData?.items || [],
      (v) => v?.[param as ParamTooltipTypes]
    )?.[param];

    const maxAllowedValue =
      chartMaxValues[param as keyof typeof chartMaxValues];

    if ((maxValue || 0) >= maxAllowedValue) {
      return true;
    }
    return false;
  };

  return isLoading ? (
    <SystemLoader />
  ) : !chartsDataItemsExist ? (
    <NoData />
  ) : (
    <>
      {averageLevelItems.length > 0 && (
        <AverageLevel spaceBeforeUnit={spaceBeforeUnit}>
          Average level
          <AverageLevelItems>
            {averageLevelItems.map((averageLevelItem) => {
              const averageLevelItemValue =
                averageLevelItem.unit === TEMPERATURE_UNIT
                  ? Math.round(+averageLevelItem.value)
                  : averageLevelItem.value;
              return (
                <AverageLevelItem>
                  <span>
                    {averageLevelItemValue}
                    <Typography>{averageLevelItem.unit}</Typography>
                  </span>
                </AverageLevelItem>
              );
            })}
          </AverageLevelItems>
        </AverageLevel>
      )}
      <ChartWrapper>
        <ChartBlock>
          <ResponsiveContainer width="100%" height={graphHeight}>
            <AreaChart
              data={chartsData?.items ?? []}
              margin={{
                top: 10,
                right: 0,
                left: -32,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient
                  id={`${chartType}-colorGradient`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={areaColor}
                    stopOpacity={areaStartOpacity}
                  />
                  <stop
                    offset="95%"
                    stopColor={areaColor}
                    stopOpacity={areaStopOpacity}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid
                vertical={grid}
                horizontal={false}
                stroke={Colors.lightWhite}
                strokeWidth="2"
                strokeDasharray="3"
              />
              {!!chartType && checkMaxValues(chartType) && (
                <ReferenceLine
                  style={{ fontSize: 12 }}
                  y={
                    chartType
                      ? chartMaxValues[chartType as keyof typeof chartMaxValues]
                      : undefined
                  }
                  stroke="red"
                />
              )}
              <XAxis
                tickLine={false}
                stroke={Colors.lightWhite}
                strokeDasharray="3"
                strokeWidth="2"
                interval={TICK_INTERVAL}
                tick={{
                  fontSize: 12,
                  fontWeight: 500,
                  fill: Colors.gray,
                }}
                tickMargin={TICK_MARGIN}
                tickSize={TICK_SIZE}
                dataKey={TIMESTAMP_KEY}
                tickFormatter={(value: number) =>
                  roundDate(value, DEFAULT_TICK_FORMAT)
                }
              />
              <YAxis
                stroke={yAxis ? Colors.lightWhite : undefined}
                domain={chartDomain}
                axisLine={false}
                tickLine={false}
                tickCount={
                  yAxis
                    ? ConnectedChartAxisYTickCount.DEFAULT
                    : ConnectedChartAxisYTickCount.HIDDEN
                }
                style={yAxis ? undefined : { opacity: 0 }}
                width={AXIS_Y_WIDTH}
                tick={
                  yAxis
                    ? {
                        fontSize: 10,
                        fontWeight: 500,
                        fill: Colors.gray,
                      }
                    : undefined
                }
              />
              <Tooltip
                content={
                  <ConnectedChartTooltip
                    tooltipColor={strokeColor}
                    paramNameTooltip={chartType}
                    unitsOfMeasurement={chartUnit}
                  />
                }
              />
              <Area
                name={chartType}
                dataKey={chartType}
                stroke={strokeColor}
                strokeWidth="1"
                fillOpacity={1}
                fill={`url(#${chartType}-colorGradient)`}
                activeDot={{
                  r: 6,
                  stroke: strokeColor,
                  fill: Colors.white,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </ChartBlock>
      </ChartWrapper>
    </>
  );
};

const ChartWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const ChartBlock = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    overflow: "hidden",
    width: "500px",
  },
}));

const AverageLevel = styled(Box)<{ spaceBeforeUnit: boolean }>(
  ({ theme, spaceBeforeUnit }) => ({
    fontSize: "14px",
    lineHeight: "16px",
    color: Colors.lightBlack,
    fontWeight: "500",
    padding: "0 0 0 0",
    position: "absolute",
    top: "24px",
    right: "20px",
    display: "flex",
    height: "40px",
    alignItems: "center",
    "& span": {
      color: Colors.heavenly,
      display: "inline-block",
      padding: "0 3px 0 5px",
      fontWeight: "500",
      "& p": {
        fontSize: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit",
      },
    },
    "& p": {
      display: "inline-block",
      padding: spaceBeforeUnit ? "0 0 0 5px" : "0 0 0 0",
      fontWeight: "500",
    },
    [theme.breakpoints.down("lg")]: {
      top: "17px",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  })
);

const AverageLevelItems = styled(Box)({
  padding: "0 0 0 10px",
});

const AverageLevelItem = styled(Box)({
  padding: "0 0 2px 0",
});

export default ConnectedChart;
