import React, { CSSProperties } from "react";

const EcoFlowChargerBattery60: React.FC<{
  style?: CSSProperties;
}> = ({ style }) => {
  return (
    <svg
      style={style}
      width="180"
      height="180"
      viewBox="0 0 180 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.875 5.625C61.875 7.11684 62.4676 8.54759 63.5225 9.60248C64.5774 10.6574 66.0082 11.25 67.5 11.25L112.5 11.25C113.992 11.25 115.423 10.6574 116.477 9.60248C117.532 8.54759 118.125 7.11684 118.125 5.625C118.125 4.13315 117.532 2.70241 116.477 1.64752C115.423 0.592625 113.992 -2.2781e-06 112.5 -2.21289e-06L67.5 -2.45877e-07C66.0082 -1.80666e-07 64.5774 0.592627 63.5225 1.64752C62.4676 2.70241 61.875 4.13316 61.875 5.625Z"
        fill="#828282"
      />
      <path
        d="M33.75 39.375L33.75 146.25C33.7685 150.72 35.5524 155.001 38.713 158.162C41.8737 161.323 46.1552 163.106 50.625 163.125L129.375 163.125C133.845 163.106 138.126 161.323 141.287 158.162C144.448 155.001 146.232 150.72 146.25 146.25L146.25 39.375C146.232 34.9052 144.448 30.6237 141.287 27.463C138.126 24.3023 133.845 22.5185 129.375 22.5L50.625 22.5C46.1552 22.5185 41.8737 24.3024 38.713 27.463C35.5523 30.6237 33.7685 34.9052 33.75 39.375ZM129.375 30.9375C130.867 30.9375 133.945 32.6951 135 33.75C136.055 34.8049 137.813 37.8832 137.813 39.375L137.813 146.25C137.813 147.742 136.055 150.82 135 151.875C133.945 152.93 130.867 154.687 129.375 154.687L50.625 154.688C49.1332 154.688 46.0549 152.93 45 151.875C43.9451 150.82 42.1875 147.742 42.1875 146.25L42.1875 39.375C42.1875 37.8832 43.9451 34.8049 45 33.75C46.0549 32.6951 49.1332 30.9375 50.625 30.9375L129.375 30.9375Z"
        fill="#828282"
      />
      <path
        d="M56.25 78.75L56.25 135C56.25 138.107 58.7684 140.625 61.875 140.625L118.125 140.625C121.232 140.625 123.75 138.107 123.75 135L123.75 78.75C123.75 75.6434 121.232 73.125 118.125 73.125L61.875 73.125C58.7684 73.125 56.25 75.6434 56.25 78.75Z"
        fill="#C2FFDC"
      />
    </svg>
  );
};

export default EcoFlowChargerBattery60;
