import { TabProps } from "src/routes/Dashboard/components/Switches";
import { GetChartDataItem } from "./apiTypes";

export interface ZoneProps extends TabProps {
  image: React.ReactElement;
  name: string;
  type: string;
}

export interface Items {
  co2: number;
  humidity: number;
  id: string;
  model: string;
  pressure: number;
  radon_lt_avg: number;
  radon_st_avg: number;
  temperature: number;
  timestamp: number;
  uuid: string;
  voc: number;
  watts_in_sum: number;
  in_watts: number;
  latestData: unknown;
}

export interface DeviceData {
  items: GetChartDataItem[];
  count: number;
}

export interface BatteryPopupType {
  socProp: number;
}

export interface DetailsCalendarType {
  renderSingleValue?: boolean;
  titleDetailsCalendar?: string;
  firstClassNameDetailsCalendar?: string;
  secondClassNameDetailsCalendar?: string;
  firstNameDetailsCalendar?: string;
  secondNameDetailsCalendar?: string;
}

export enum RoutesPath {
  DASHBOARD = "/",
  DEVICES = "/devices",
  STATION_SETUP = "/station-setup",
  NOTIFICATIONS = "/notifications",
  CONNECTED_SENSORS = "/connected-sensors",
  CONNECTED_STATIONS = "/connected-stations",
  CALLBACK = "/callback",
  SETTINGS = "/settings",
}

export interface TimeSplit {
  days: number;
  hours: number;
  minutes: number;
}

export interface InOutVolType {
  inv_out_vol?: number;
}

export interface TypeC1Watts {
  type_c_1_watts?: number;
}

export enum DataTypeEnum {
  HUMIDITY = "humidity",
  ENERGY = "energy",
  TEMPERATURE = "temperature",
  PRESSURE = "pressure",
  RADON = "radon",
}
