import React from "react";
import { ChartItem } from "src/types/customTooltipType";
import dayjs from "dayjs";
import { Box, styled, Typography } from "@mui/material";
import { TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE } from "../../../system/constants";

const MILLISECONDS_PER_SECOND = 1000;

const CustomTooltip: React.FC<ChartItem> = ({ payload }) => {
  if (!payload) {
    return null;
  }

  const timeTooltip = dayjs(
    payload?.[0]?.payload?.timestamp * MILLISECONDS_PER_SECOND
  ).format(TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE);

  const watts = (payload?.[0]?.payload?.in_watts ?? 0).toFixed(2);

  return (
    <CustomTooltipWrap className="custom-tooltip">
      <Typography className="time-tooltip">{timeTooltip}</Typography>
      <Typography className="param param-1">
        <span className="watt-new"></span>
        {watts} W
      </Typography>
    </CustomTooltipWrap>
  );
};

const CustomTooltipWrap = styled(Box)({
  fontSize: "12px",
  fontWeight: "500",
  "& .param": {
    fontSize: "10px",
    fontWeight: "400",
    display: "flex",
    "& span": {
      width: "10px",
      height: "10px",
      top: "3px",
    },
  },
});

export default CustomTooltip;
