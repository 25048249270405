import device from "../assets/images/device.png";
import ecoflow from "../assets/images/ecoflow.png";
import station from "../assets/images/station.png";
import solarPanel from "../assets/images/solar-panel.png";
import {
  Bounds,
  ChartDataItemType,
  DeviceDataType,
} from "src/types/connectedSensorsType";
import { TypeLabelProps } from "src/types/connectedStationType";
import {
  EnergyPicIcon,
  HumidityImg,
  PolytionImg,
  PressureImg,
  RadonIcon,
  RadonImg,
  TemperatureImg,
} from "../assets/icons";
import {
  NotificationData,
  SensorTypeNotification,
} from "../routes/Notifications/components/assets/types/notificationsTypes";
import React from "react";

export const NameSensor = [
  {
    id: 1,
    nameSensor: "Air Sensor 1",
    createTime: "22/03/2022 16:28:20",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
  {
    id: 2,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2021 16:28:17",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
  {
    id: 3,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2022 16:28:14",
    type: "Heavy pollution",
    severity: "Critical",
    status: "Cleared Unacknowledged",
  },
  {
    id: 4,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2023 16:28:19",
    type: "Heavy pollution",
    severity: "Basic",
    status: "Cleared Unacknowledged",
  },
  {
    id: 5,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2021 16:28:17",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
  {
    id: 6,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2021 16:28:17",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
  {
    id: 7,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2021 16:28:17",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
  {
    id: 8,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2021 16:28:17",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
  {
    id: 9,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2021 16:28:17",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
  {
    id: 10,
    nameSensor: "Air Sensor 2",
    createTime: "22/03/2021 16:28:17",
    type: "Heavy pollution",
    severity: "Viewed",
    status: "Problem solved",
  },
];

export const Colors = {
  black: "#000000",
  orange: "#F2994A",
  lightSkyYellow: "#F2C94C",
  lightSkyBlue: "#A1C4E2",
  lightBlack: "#333333",
  dartGray: "#272727",
  dimGray: "#4F4F4F",
  silver: "#BDBDBD",
  whiteSky: "#F6F7F9",
  kinglyCloud: "#E0E0E0",
  white: "#FFFFFF",
  white1: "#E7EBF0",
  whiteGrayLight: "#fafafa",
  highlighterRed: "#EB5757",
  peachCobbler: "#FFB682",
  bicycleYellow: "#FFE68E",
  cloudy: "#85C7DF",
  lightBlue: "#AED1E6",
  brightBlue: "#9BD1E4",
  skyBlue: "#85C7DE",
  greenMana: "#27AE60",
  blue: "#85C7DE",
  blueDark: "#239cc8",
  transparent: "transparent",
  heavenly: "#A0C4E2",
  greenLight: "#65D696",
  gray: "#828282",
  brightGray: "#DDDDDD",
  lightGray2: "#AEAEAE",
  lightWhite: "#f2f2f2",
  yellow: "#fde58d",
  lightYellow: "#FFD541",
  lightBlue2: "#69AEC6",
  brightYellow: "#fae7b2",
  brightGreen: "#C2FFDC",
  lightGreen: "#67d596",
  lightBrightGreen: "#3FEFBE",
  pink: "#FFA0A0",
  brightWhite: "#FBFBFB",
  violet: "#AFA8FF",
  lightGray: "#CCCCCC",
  deepBlue: "#3C76F1",
  skylLightBlue: "#94CEE2",
  red: "#FF0000",
  mint: "#3FF0BE",
  skyLightBlue: "#31A4E9",
  grayWhite: "#F0F0F0",
  grayLight: "#F8F8F8",
  tomato: "#C91726",
  darkGray: "#B3B3B3",
  whiteGray: "#DBDBDB",
  darkGreen: "#27AE61",
  lightSkyWhite: "#F3FCF7",
  darkLightGreen: "#219653",
  lightSalt: "#D4EFDF",
  lightPink: "#FBDDDD",
  brightLightWhite: "#FDFDFD",
  grayDark: "#9A9A9A",
  darkSilver: "#39393D",
  lightBrightMint: "#33CF4D",
  lightViolet: "#BBBBD9",
  skyViolet: "#BFD2FA",
  skyMint: "#C2FFDD",
  lightSky: "#A0C5E2",
  lightBrightSky: "#AFCDE6",
  skyYellow: "#FED440",
  greenBlack: "#101010",
  aliceMint: "#E9F4EE",
  blueHover: "#B3E4F5",
};

const cognitoClientId = "6nctf6cduaf93v8sfeu9heqkm2";
const cognitoBaseUrl =
  "https://user-pool-domain-008427551003.auth.us-east-1.amazoncognito.com";
const cognitoRedirectUrl = window.location.host.includes("localhost")
  ? "http://localhost:3000/callback"
  : "https://dcc1o1dv9htzf.cloudfront.net/callback";
const loginUrl = `${cognitoBaseUrl}/login?client_id=${cognitoClientId}&response_type=code&scope=email+openid+profile&redirect_uri=${cognitoRedirectUrl}`;

export const systemVariables = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? "",
  cognitoClientId,
  cognitoBaseUrl,
  cognitoRedirectUrl,
  loginUrl,
};

export const constants = {
  dateConversionFormat: "YYYY-MM-DD",
};

export const MyColor = {};

export const deviceData: DeviceDataType = {
  "2930135085": {
    image: device,
    link: "../connected-sensors",
    dataTestId: "button-wavePlus",
  },
  solar_panel: {
    image: solarPanel,
    link: "",
    dataTestId: "button-riverOne",
  },
  R601ZEB5HEA51837: {
    image: ecoflow,
    link: "../connected-stations",
    dataTestId: "button-riverTwo",
  },
};

export enum SensorType {
  CO2 = "CO2",
  VOC = "VOC",
  RAD = "Rad",
  TEMP = "Temp",
  HUM = "Hum",
  PRESS = "Press",
}

// export enum SensorType {
//   CO2 = "CO2",
//   VOC = "VOC",
//   RAD = "Radon",
//   TEMP = "Temp",
//   HUM = "Humidity",
//   PRESS = "Pressure",
// };

export interface AverageChartItem {
  name: ParamTooltipTypes;
  unit: string;
  value?: string;
}

export const devicesPageSection = [
  {
    title: "Wave Plus",
    image: device,
    link: "../connected-sensors",
    name: "Wave Plus",
    type: "Sensor",
    connection: "2022-07-11",
    time: "118h 20m 42s",
  },
  {
    title: "Ecoflow River",
    image: ecoflow,
    link: "../connected-stations",
    name: "Ecoflow River",
    type: "Charge Station",
    connection: "2022-08-10",
    time: "118h 20m 42s",
  },
  {
    title: "Renogy 100 Watt 12 Volt Portable Solar Panel for Power",
    image: station,
    link: "",
    name: "Renogy 100 Watt 12 Volt Portable Solar Panel for Power",
    type: "Solar Panel",
    connection: "2022-08-10",
    time: "118h 20m 42s",
  },
];

export const referenceTableItems = [
  {
    title: "Radon",
    items: [
      {
        value: "≥ 150 Bq/m3",
      },
      {
        value: "≥ 100 and < 150 Bq/m3",
      },
      {
        value: "< 100 Bq/m3",
      },
    ],
  },
  {
    title: "CO2",
    items: [
      {
        value: "≥ 1000 ppm",
      },
      {
        value: "≥ 600 and < 1000 ppm",
      },
      {
        value: "< 600 ppm",
      },
    ],
  },
  {
    title: "VOC",
    items: [
      {
        value: "≥ 2000 ppb",
      },
      {
        value: "≥ 250 and < 2000 ppb",
      },
      {
        value: "< 250 ppb",
      },
    ],
  },
  {
    title: "Temperature",
    items: [
      {
        value: "< 19 or ≥ 29 °C",
      },
      {
        value: "≥ 24 and < 29 °C",
      },
      {
        value: "≥ 19 and < 24 °C",
      },
    ],
  },
  {
    title: "Humidity",
    items: [
      {
        value: "< 30 or ≥ 70 %",
      },
      {
        value: "≥ 60 and < 70 %",
      },
      {
        value: "≥ 30 and < 60 %",
      },
    ],
  },
  {
    title: "Pressure",
    items: [
      {
        value: "≥ 1020 mbar",
      },
      {
        value: "≥ 995 and < 1020 mbar",
      },
      {
        value: "< 995 mbar",
      },
    ],
  },
];

export const units = {
  Hum: "%",
  CO2: "ppm",
  Temp: "°C",
  VOC: "ppb",
  Pressure: "mbar",
  Radon: "Bq/m³",
};

export const NAME_OPEN = [
  { id: 1, name: "Ecoflow River" },
  { id: 2, name: "Ecoflow River2" },
];

export enum DeviceStatus {
  ON = "on",
  OFF = "off",
}

export enum SensorStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum DeviceTypes {
  RIVER_ONE = "RiverOne",
  RIVER_TWO = "RiverTwo",
  WAVE_PLUS = "WavePlus",
}

export enum DevicesIds {
  RIVER_ONE_ID = "R7EBZ7XE3190197",
  RIVER_TWO_ID = "R601ZEB5HEA51837",
  WAVE_PLUS_ID = "2930135085",
}

export enum DeviceColumnTitle {
  NAME = "Name",
  DEVICE_TYPE = "Device Type",
  CONNECTION_DATE = "Connection date",
  TOTAL_TIME = "Total time",
}

export enum UnitTypes {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export enum UnitTypesIntervalStr {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export enum DataType {
  VOL = "vol",
  ENERGY = "energy",
  SENSORS = "sensors",
  SOLAR_PANEL = "solar_panel",
  ENERGY_COST = "energy_cost",
}

export enum ZoneDataName {
  HUMIDITY = "humidity",
  ENERGY = "energy",
  TEMPERATURE = "temperature",
  PRESSURE = "pressure",
  RADON = "radon",
  POLLUTION = "pollution",
}

export enum ParamTooltipTypes {
  HUMIDITY = "humidity",
  RADON_ST_AVG = "radon_st_avg",
  CO2 = "co2",
  VOC = "voc",
  PRESSURE = "pressure",
  WATTS_IN_SUM = "watts_in_sum",
  IN_WATTS = "in_watts",
  WATTS_OUT_SUM = "watts_out_sum",
  INPUT_WATTS = "input_watts",
  TEMPERATURE = "temperature",
  IN_VOL = "in_vol",
}

export enum ParamUSB {
  USB_A = "USB-A",
  USB_B = "USB-B",
  USB_C = "USB-C",
  USB_F_C = "USB F.C.",
  TYPE_ONE_C = "TYPE ONE C",
}

export enum DeviceName {
  RENOGY_ONE_HUNDRED_WATT = "Renogy 100 Watt 12 Volt",
  AIRTHING_WAVE_PLUS = "Airthings Wave Plus",
}

export enum LabelName {
  HUM = "Hum",
  CO2 = "CO2",
  TEMP = "Temp",
  VOC = "VOC",
  PRESSURE = "Pressure",
  RADON = "Radon",
}

export const sensorTypeNames = [
  {
    type: ParamTooltipTypes.HUMIDITY,
    name: LabelName.HUM,
    color: Colors.pink,
  },
  {
    type: ParamTooltipTypes.RADON_ST_AVG,
    name: LabelName.RADON,
    color: Colors.violet,
  },
  {
    type: ParamTooltipTypes.CO2,
    name: LabelName.CO2,
    color: Colors.brightGreen,
  },
  {
    type: ParamTooltipTypes.VOC,
    name: LabelName.VOC,
    color: Colors.lightViolet,
  },
  {
    type: ParamTooltipTypes.PRESSURE,
    name: LabelName.PRESSURE,
    color: Colors.bicycleYellow,
  },
  {
    type: ParamTooltipTypes.TEMPERATURE,
    name: LabelName.TEMP,
    color: Colors.heavenly,
  },
];

export enum SensorChartGraphHeight {
  SMALL = 70,
  NORMAL = 170,
}

export const roseTooltipHeaderClasses = {
  [UnitTypes.DAY]: "rose-time-tooltip",
  [UnitTypes.WEEK]: "rose-current-week",
  [UnitTypes.MONTH]: "rose-week-tooltip",
  [UnitTypes.YEAR]: "rose-year-tooltip",
};

export const DEVICE_ID_RIVER_ONE = "R7EBZ7XE3190197";
export const DEVICE_ID_RIVER_TWO = "R601ZEB5HEA51837";
export const DEVICE_ID_WAWE_PLUS = "2930135085";

export const DEVICE_TYPE_RIVER_ONE = "RiverOne";
export const DEVICE_TYPE_RIVER_TWO = "RiverTwo";
export const DEVICE_TYPE_WAWE_PLUS = "WavePlus";

export const UNIT_TYPE_DAY = "day";
export const UNIT_TYPE_WEEK = "week";
export const UNIT_TYPE_MONTH = "month";
export const UNIT_TYPE_YEAR = "year";

export const PERCENT_COEFFICIENT = 100;
export const DEFAULT_VALUE_ROSE_DATA = 10;
export const MINIMUM_DISPLAY_VALUE = 30;

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export const DEFAULT_TICK_FORMAT = "HH:[00]";

export const ARROW_LEFT_KEY = "ArrowLeft";
export const ARROW_RIGHT_KEY = "ArrowRight";

export const TOOLTIP_NAME_HUMIDITY = "humidity";

export const EDUCATION_TO_TIME = 1000;
export const TIMESTAMP_ONE_MINUTE = 1000 * 60;

export const TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE = "HH:00";
export const THE_DAY_OF_THE_WEEK = "dddd";
export const THE_DAY_OF_THE_MONTH = "DD";
export const DATE_FORMAT_CONNECTION = "YYYY-MM-DD";
export const DATE_FORMAT_TOTAL_TIME = "D[d] HH[h] mm[m] ss[s]";

export const DECIMAL_PLACES = 2;

export const DEFAULT_SENSORS_BLOCK_DATA = "sensors";
export const DEFAULT_ENERGY_BLOCK_DATA = "energy";

export const DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT = 6;
export const DEFAULT_CONNECTED_STATIONS_POWER_TICK_COUNT = 10;
export const DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN = 2;
export const DEFAULT_CONNECTED_STATIONS_VOLTAGE_TICK_MARGIN = 2;
export const DEFAULT_CONNECTED_STATIONS_SOURCE_OF_CONSUMPTION_DOMAIN = [0, 25];
export const DEFAULT_CONNECTED_STATIONS_WATT_DOMAIN = [0, 20];
export const DEFAULT_CONNECTED_STATIONS_POWER_DOMAIN = [0, 300];

export const DEFAULT_Y_AXIS_DOMAIN_RADON = [31, 60];
export const DEFAULT_Y_AXIS_DOMAIN_TEMPERATURE = [0, 35];
export const DEFAULT_Y_AXIS_DOMAIN_PRESSURE = [800, 1200];
export const DEFAULT_Y_AXIS_DOMAIN_HUMIDITY = [0, 100];
export const DEFAULT_Y_AXIS_DOMAIN_VOC = [51, 100];
export const DEFAULT_Y_AXIS_DOMAIN_CO2 = [31, 60];
export const DEFAULT_Y_AXIS_DOMAIN_ROSE_ONE_DAY = [0, 130];
export const DEFAULT_Y_AXIS_DOMAIN_ROSE_WEEK = [0, 1000];
export const DEFAULT_Y_AXIS_DOMAIN_ROSE_MOUNTH = [0, 100];

export const DEFAULT_GRADENT_OPACITY = 0.3;
export const DEFAULT_GRADENT_OPACITY_ROSE_ONE_DAY = 0.35;
export const DEFAULT_GRADENT_OPACITY_STOP = 0;
export const DEFAULT_FILL_OPACITY = 1;

export const DEFAULT_DOMAIN_START_VALUE = 0;
export const DEFAULT_DOMAIN_END_VALUE = 60;
export const DEFAULT_DOMAIN_END_VALUE_MONTH = 300;
export const DEFAULT_DOMAIN_END_VALUE_HUMIDITY_DAY = 100;
export const DEFAULT_DOMAIN_END_VALUE_POLLUTION = 1500;
export const DEFAULT_TICK_COUNT_VALUE = 7;
export const DEFAULT_DOMAIN_START_VALUE_PRESSURE = 950;
export const DEFAULT_DOMAIN_END_VALUE_PRESSURE = 1010;
export const DEFAULT_DOMAIN_END_VALUE_RADON = 90;

export const DEFAULT_COUNT_UP_END = 0;
export const DEFAULT_COUNT_UP_DURATION = 2;
export const DEFAULT_COUNT_UP_START = 0;

export const HIGH_PERCENT_LEVEL_BATTERY = 80;
export const LOW_PERCENT_LEVEL_BATTERY = 20;
export const TIME_APPEARANCE_BATTERY_POPUP = 3000;

export const DEFAULT_VALUE_TO_UP_ROSE = 100;

export const DEFAULT_FORMAT_TIME = "dddd, MMMM DD, YYYY | HH:mm";
export const DEFAULT_FORMAT_DAYS = "days";
export const DEFAULT_FORMAT_DAY = "day";
export const DEFAULT_FORMAT_WEEEK = "week";
export const DEFAULT_FORMAT_MONTH = "MMMM";
export const DEFAULT_FORMAT_MONTH_WORD = "month";
export const DEFAULT_FORMAT_YEAR = "YYYY";
export const DEFAULT_FORMAT_DAY_SHORT = "D";
export const DEFAULT_FORMAT_DAY_CALENDAR_FORMAT = "MMMM D, YYYY";
export const DEFAULT_FORMAT_DAY_CALENDAR_FORMAT_DEVICE = "2022-07-11";

export const TIME_FORMAT = "HH:mm";

export const DEFAULT_TIME_FOR_DEVICES = "118h 20m 42s";
export const DEFAULT_TIMPERATURE_FOR_ECOFLOW_RIVER = 0;

export const NO_DATA_VALUE = "--";
export const DEFAULT_VALUE_MAX_CHARGE = 100;

export const DEFAULT_RADAR_AGGREGATED_VALUE = 0;
export const DEFAULT_RADAR_LOWEST_VALUE = 0;
export const DEFAULT_RADAR_HIGHEST_VALUE = 1;

export const UNKNOWN_CHART_STRING = "<unknown_chart>";
export const RADAR_LINE_TYPE = "linearClosed";
export const MOCKED_AVERAGE_PRESSURE = 922;

export const DATEPICKER_LABEL = "Date Energy";
export const DATEPICKER_INPUT_FORMAT = "MM/DD/YYYY";

export const DEFAULT_DEVICE_ID = "R601ZEB5HEA51837";
export const HOUR = "hour";
export const HOURS_TO_SUBTRACT = 3;
export const DEFAULT_LOWER_BOUND = 1706773149;
export const MILLISECONDS_TO_SECONDS = 1000;
export const DEFAULT_PROJECT_TRANSITION = "1.5s ease 0.5s";
export const DEFAULT_TRACK_TRANSITION = "0s ease";

export const MINUTE = "minute";

export const TypeLabels: TypeLabelProps = {
  in_watts: { header: "Produced power", unit: "kW/h" },
  temp: { header: "Battery temperature", unit: "°C" },
  in_vol: { header: "Consumed Power", unit: "kW/h" },
};

export enum EnergyStatTypes {
  PRODUCTION = "Solar Panel Energy Production",
  CONSUMPTION = "EcoFlow Energy Consumption",
}

export enum EnergyTypes {
  WATTS_IN_SUM = "watts_in_sum",
  WATTS_OUT_SUM = "watts_out_sum",
  VOL = "vol",
  INPUT_WATTS = "input_watts",
  IN_WATTS = "in_watts",
  IN_VOL = "in_vol",
}

export enum EnergyUnitTypes {
  W = "W",
  V = "V",
}

export enum StatPosition {
  SINGLE,
  COLUMN_FIRST,
  COLUMN_SECOND,
}
export enum ActiveUnactive {
  ACTIVE = "active",
  UNACTIVE = "unactive",
}
export enum UsageProperties {
  USB_A1_TITLE = "USB-A",
  USB_A2_TITLE = "USB-A2",
  USB_C_100W = "USB-C 100W",
  USB_FAST_CHARGE = "USB FAST CHARGE",
  CAR_OUTLET_PORT = "CAR OUTLET PORT",
  AC_250V = "AC 250V",
  AC_270V = "AC 270V",
}
export enum DEVICE_IDS {
  SOLAR_PANEL = "solar_panel",
  RIVER_TWO = "R601ZEB5HEA51837",
  WAVE_PLUS = "2930135085",
}

export const DINAMICKA_DEVELOPMENT_URL = "https://dinamicka.com";
export const DEFAULT_GRANT_TYPE = "authorization_code";

export enum HeadersType {
  APPLICATION_X_FORM = "application/x-www-form-urlencoded",
  ACCEPT_APPLICATION_JSON = "application/json",
}

export enum RangeScaleAlignment {
  LEFT = "left",
  RIGHT = "right",
}

export enum PropertyEcoFlowCharger {
  REMAIN_TIME = "chg_remain_time",
  DISCHARGE_TIME = "dsg_remain_time",
  LCD_SHOW_SOC = "lcd_show_soc",
  CHG_STATE = "chg_state",
}

enum ChartMaxValues {
  Pressure = "pressure",
  CO2 = "co2",
  VOC = "voc",
  RadonStAvg = "radon_st_avg",
  Temperature = "temperature",
  Humidity = "humidity",
}

export enum chartMaxValues {
  pressure = 1020,
  co2 = 1000,
  voc = 2000,
  radon_st_avg = 150,
  temperature = 29,
  humidity = 70,
}

export const chartData: ChartDataItemType[] = [
  {
    imgComponent: PolytionImg,
    sensor: SensorType.CO2,
    key: "currentCo2",
    unit: units.CO2,
    value: 0,
  },
  {
    imgComponent: TemperatureImg,
    sensor: SensorType.TEMP,
    key: "currentTemp",
    unit: units.Temp,
    value: 0,
  },
  {
    imgComponent: PolytionImg,
    sensor: SensorType.VOC,
    key: "currentVoc",
    unit: units.VOC,
    value: 0,
  },
  {
    imgComponent: HumidityImg,
    sensor: SensorType.HUM,
    key: "currentHum",
    unit: units.Hum,
    value: 0,
  },
  {
    imgComponent: RadonIcon,
    sensor: SensorType.RAD,
    key: "currentRad",
    unit: units.Radon,
    value: 0,
  },
  {
    imgComponent: PressureImg,
    sensor: SensorType.PRESS,
    key: "currentPress",
    unit: units.Pressure,
    value: 0,
  },
];

export const tabsBtn = [
  {
    title: "Energy",
    imgComponent: { icon: EnergyPicIcon, className: "energySvg" },
    dataTestId: "tab-energy",
  },
  {
    title: "Pollution",
    imgComponent: { icon: PolytionImg, className: "pollutionSvg" },
    dataTestId: "tab-pollution",
  },
  {
    title: "Humidity",
    imgComponent: { icon: HumidityImg, className: "humiditySvg" },
    dataTestId: "tab-humidity",
  },

  {
    title: "Temp",
    imgComponent: { icon: TemperatureImg, className: "tempSvg" },
    dataTestId: "tab-temperature",
  },
  {
    title: "Pressure",
    imgComponent: { icon: PressureImg, className: "pressureSvg" },
    dataTestId: "tab-pressure",
  },
  {
    title: "Radon",
    imgComponent: { icon: RadonImg, className: "radonSvg" },
    dataTestId: "tab-radonn",
  },
];

export enum SensorTypeData {
  CO2 = "co2",
  VOC = "voc",
  RAD = "radon_st_avg",
  TEMP = "temperature",
  HUM = "humidity",
  PRESS = "pressure",
}

export const sensorBoundsMap = new Map<SensorType, Bounds>([
  [SensorType.CO2, { greenBound: 600, redBound: 1000 }],
  [SensorType.VOC, { greenBound: 250, redBound: 2000 }],
  [SensorType.RAD, { greenBound: 100, redBound: 150 }],
  [SensorType.TEMP, { greenBound: 24, redBound: 29, secondGreenBound: 19 }],
  [SensorType.HUM, { greenBound: 60, redBound: 70, secondGreenBound: 30 }],
  [SensorType.PRESS, { greenBound: 995, redBound: 1020 }],
]);

export const sensorBoundsMapNotification = new Map<
  SensorTypeNotification,
  Bounds
>([
  [SensorTypeNotification.HIGH_CO2, { greenBound: 600, redBound: 1000 }],
  [SensorTypeNotification.HIGH_VOC, { greenBound: 250, redBound: 2000 }],
  [SensorTypeNotification.HIGH_RADON, { greenBound: 100, redBound: 150 }],
  [
    SensorTypeNotification.HIGH_TEMPERATURE,
    { greenBound: 24, redBound: 29, secondGreenBound: 19 },
  ],
  [
    SensorTypeNotification.LOW_TEMPERATURE,
    { greenBound: 28, redBound: 19, secondGreenBound: 29 },
  ],
  [
    SensorTypeNotification.LOW_HUMIDITY,
    { greenBound: 60, redBound: 70, secondGreenBound: 90 },
  ],
  [
    SensorTypeNotification.HIGH_HUMIDITY,
    { greenBound: 60, redBound: 70, secondGreenBound: 30 },
  ],
  [
    SensorTypeNotification.CHARGE_LEVEL,
    { greenBound: 60, redBound: 70, secondGreenBound: 90 },
  ],
  [SensorTypeNotification.HIGH_PRESSURE, { greenBound: 995, redBound: 1020 }],
]);

export const MEGAWATT_DIVISOR = 1000;

export const sensorTypeUnits = {
  [SensorTypeNotification.LOST_CONNECTION]: "",
  [SensorTypeNotification.HIGH_HUMIDITY]: "%",
  [SensorTypeNotification.LOW_HUMIDITY]: "%",
  [SensorTypeNotification.HIGH_RADON]: "q/m³",
  [SensorTypeNotification.HIGH_PRESSURE]: "mbar",
  [SensorTypeNotification.HIGH_TEMPERATURE]: "°C",
  [SensorTypeNotification.LOW_TEMPERATURE]: "°C",
  [SensorTypeNotification.HIGH_CO2]: "ppm",
  [SensorTypeNotification.HIGH_VOC]: "ppb",
  [SensorTypeNotification.CHARGE_LEVEL]: "%",
};

export const CHANGE_LEVEL_TEXT = "Low battery charge level";
export const CO2_TEXT = "CO2 pollution level is too high";
export const VOC_TEXT = "VOC pollution level is too high";
export const RADON_TEXT = "Radon level is too high";
export const PRESSURE_TEXT = "Atmospheric pressure is too high";
export const LOW_HUMIDITY_TEXT = "Humidity is too low";
export const HIGH_HUMIDITY_TEXT = "Humidity is too high";
export const HIGH_TEMPERATURE_TEXT = "High temperature";
export const LOW_TEMPERATURE_TEXT = "Low temperature";

export const MINUTES_IN_DAY = 24 * 60;
export const MINUTES_IN_HOUR = 60;
export const LEADING_ZERO_THRESHOLD = 10;
export const PERCENTAGE_MULTIPLIER = 100;

export const BATTERY_HIGH_LEVEL_MAX_VALUE = 100;
export const BATTERY_HIGH_LEVEL_MIN_VALUE = 81;
export const BATTERY_AVERAGE_LEVEL_MAX_VALUE = 80;
export const BATTERY_AVERAGE_LEVEL_MIN_VALUE = 60;
export const BATTERY_LOW_LEVEL_MAX_VALUE = 59;
export const BATTERY_LOW_LEVEL_MIN_VALUE = 30;

export enum TooltipTitleMessages {
  HUMIDITY_TOOLTIP_TITLE = "Humidity is the amount of moisture or water vapor present in the air. It is typically expressed as a percentage, representing the relative humidity",
  ENERGY_TOOLTIP_TITLE = "The amount of energy in watts produced by the panel",
  CELSIUS_TEMP_TOOLTIP_TITLE = "Temperature is a measure of how hot or cold the air is in Celsius",
  FAHRENHEIT_TEMP_TOOLTIP_TITLE = "Temperature is a measure of how hot or cold the air is in Fahrenheit",
  PRESSURE_TOOLTIP_TITLE = "Atmospheric pressure, typically measured in hectopascals (hPa), is the force exerted by the weight of the air above a given point",
  RADON_TOOLTIP_TITLE = "Radon is a radioactive gas that can seep into homes and buildings from the ground. It is a colorless, odorless gas and a known carcinogen",
  CO2_TOOLTIP_TITLE = "CO2, or carbon dioxide, is a gas produced by human respiration and combustion processes",
  VOC_TOOLTIP_TITLE = "VOC stands for volatile organic compounds, which are various organic chemicals that can evaporate into the air and may have health effects",
}

export const TICK_INTERVAL_THREE_HOURS = 3 * 60 * 60;
export const thousand = 1000;
export const thirtyThousand = 30000;

export const notificationsData = {
  [SensorTypeNotification.HIGH_RADON]: {
    title: "Radon",
    items: [
      { value: "≥ 150 Bq/m3" },
      { value: "≥ 100 and < 150 Bq/m3" },
      { value: "< 100 Bq/m3" },
    ],
  },
  [SensorTypeNotification.HIGH_CO2]: {
    title: "CO2",
    items: [
      { value: "≥ 1000 ppm" },
      { value: "≥ 600 and < 1000 ppm" },
      { value: "< 600 ppm" },
    ],
  },
  [SensorTypeNotification.HIGH_VOC]: {
    title: "VOC",
    items: [
      { value: "≥ 2000 ppb" },
      { value: "≥ 250 and < 2000 ppb" },
      { value: "< 250 ppb" },
    ],
  },
  [SensorTypeNotification.HIGH_TEMPERATURE]: {
    title: "Temperature",
    items: [
      { value: "< 19 or ≥ 29 °C" },
      { value: "≥ 24 and < 29 °C" },
      { value: "≥ 19 and < 24 °C" },
    ],
  },
  [SensorTypeNotification.LOW_TEMPERATURE]: {
    title: "Temperature",
    items: [
      { value: "< 19 or ≥ 29 °C" },
      { value: "≥ 24 and < 29 °C" },
      { value: "≥ 19 and < 24 °C" },
    ],
  },
  [SensorTypeNotification.HIGH_HUMIDITY]: {
    title: "Humidity",
    items: [
      { value: "< 30 or ≥ 70 %" },
      { value: "≥ 60 and < 70 %" },
      { value: "≥ 30 and < 60 %" },
    ],
  },
  [SensorTypeNotification.LOW_HUMIDITY]: {
    title: "Humidity",
    items: [
      { value: "< 30 or ≥ 70 %" },
      { value: "≥ 60 and < 70 %" },
      { value: "≥ 30 and < 60 %" },
    ],
  },
  [SensorTypeNotification.HIGH_PRESSURE]: {
    title: "Pressure",
    items: [
      { value: "≥ 1020 mbar" },
      { value: "≥ 995 and < 1020 mbar" },
      { value: "< 995 mbar" },
    ],
  },
};
