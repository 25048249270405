import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, styled, SxProps, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ar";
import "dayjs/locale/bg";
import "dayjs/locale/ca";
import "dayjs/locale/cs";
import "dayjs/locale/da";
import "dayjs/locale/de";
import "dayjs/locale/el";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/et";
import "dayjs/locale/fa";
import "dayjs/locale/fi";
import "dayjs/locale/fr";
import "dayjs/locale/he";
import "dayjs/locale/hi";
import "dayjs/locale/hr";
import "dayjs/locale/hu";
import "dayjs/locale/id";
import "dayjs/locale/it";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/lt";
import "dayjs/locale/lv";
import "dayjs/locale/nb";
import "dayjs/locale/nl";
import "dayjs/locale/pl";
import "dayjs/locale/pt";
import "dayjs/locale/ro";
import "dayjs/locale/ru";
import "dayjs/locale/sk";
import "dayjs/locale/sl";
import "dayjs/locale/sr";
import "dayjs/locale/sv";
import "dayjs/locale/th";
import "dayjs/locale/tr";
import "dayjs/locale/uk";
import "dayjs/locale/vi";
import "dayjs/locale/zh";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-hk";
import "dayjs/locale/zh-tw";
import { Colors } from "../system/constants";
/**

 The main range picker component
 @typedef {Object} DateRangePickerProps
 @property {string} [locale] - Optional string representing the locale to be used for formatting and displaying dates.
 @property {boolean} [isSingularCalendar=false] - Optional boolean flag indicating if the date range picker should only show a single date instead of a range.
 @property {SxProps} [sx] - Optional object representing the style props for the component using Theme UI.
 @property {Array<Date|null|undefined>|null} [value] - Optional array representing the currently selected date range. Each element in the array can be a Date object, null, or undefined. If value is not provided, the component will be uncontrolled.
 @property {function} onChange - Callback function to handle changes to the selected date range. Takes an array representing the selected date range (each element can be a Date object, null, or undefined) and returns void.
 */

export enum CalendarMode {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

const DateRangePicker: React.FC<{
  locale?: string;
  isSingularCalendar?: boolean;
  sx?: SxProps;
  value?: [Date | null | undefined, Date | null | undefined] | null;
  onChange: (
    _value: [Date | null | undefined, Date | null | undefined] | null
  ) => void;
  mode?: CalendarMode;
  disabledFeature?: boolean;
}> = ({
  locale,
  isSingularCalendar,
  onChange,
  value,
  sx,
  mode,
  disabledFeature,
}) => {
  dayjs.locale(locale ?? "en");

  const [calendarDay, setCalendarDay] = useState(dayjs(value?.[0]) ?? dayjs());

  const [hoveredDate, setHoveredDate] = useState<Dayjs | null>(null);
  const startDate = useMemo(() => {
    return value?.[0] ?? null;
  }, [value]);
  const endDate = useMemo(() => {
    return value?.[1] ?? null;
  }, [value]);
  const startDayFormatted = useMemo(() => {
    if (!startDate) {
      return startDate;
    }
    return dayjs(startDate);
  }, [startDate]);
  const endDayFormatted = useMemo(() => {
    if (!endDate) {
      return endDate;
    }
    return dayjs(endDate);
  }, [endDate]);
  const handleSetNewRange = useCallback(
    (startDateToSet: Dayjs | null | undefined) => {
      if (!startDateToSet) {
        onChange([startDateToSet, null]);
      } else {
        onChange([dayjs(startDateToSet).toDate(), null]);
      }
    },
    [onChange]
  );
  const handleSetStartDate = useCallback(
    (startDateToSet: Dayjs | null | undefined) => {
      if (!startDateToSet) {
        onChange([startDateToSet, endDate]);
      } else {
        onChange([dayjs(startDateToSet).toDate(), endDate]);
      }
    },
    [endDate, onChange]
  );
  const handleSetEndDate = useCallback(
    (endDateToSet: Dayjs | null | undefined) => {
      if (!endDateToSet) {
        onChange([startDate, endDateToSet]);
      } else {
        onChange([startDate, dayjs(endDateToSet).toDate()]);
      }
    },
    [onChange, startDate]
  );
  useEffect(() => {
    return () => {
      dayjs.locale("en");
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          width: isSingularCalendar ? "316px" : "632px",
          height: mode === CalendarMode.MONTH ? "290px" : "362px",
          backgroundColor: "white",
          display: "flex",
          ...sx,
        }}
      >
        <CalendarComponent
          handleSetNewRange={handleSetNewRange}
          isSingularCalendar={isSingularCalendar}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          startDayFormatted={startDayFormatted}
          endDayFormatted={endDayFormatted}
          handleSetStartDate={handleSetStartDate}
          handleSetEndDate={handleSetEndDate}
          setCalendarDay={setCalendarDay}
          calendarDay={calendarDay}
          kind={"firstCalendar"}
          mode={mode}
          disabledFeature={disabledFeature}
        />
        {!isSingularCalendar && (
          <CalendarComponent
            handleSetNewRange={handleSetNewRange}
            hoveredDate={hoveredDate}
            setHoveredDate={setHoveredDate}
            startDayFormatted={startDayFormatted}
            endDayFormatted={endDayFormatted}
            handleSetStartDate={handleSetStartDate}
            handleSetEndDate={handleSetEndDate}
            setCalendarDay={setCalendarDay}
            calendarDay={dayjs(calendarDay).add(1, "M")}
            kind={"secondCalendar"}
            disabledFeature={disabledFeature}
          />
        )}
      </Box>
    </>
  );
};
interface CalendarComponentProps {
  handleSetNewRange: (_startDate: Dayjs | null | undefined) => void;
  isSingularCalendar?: boolean;
  kind: "firstCalendar" | "secondCalendar";
  calendarDay: Dayjs;
  setCalendarDay: (_calendarDay: Dayjs) => void;
  startDayFormatted: Dayjs | null | undefined;
  endDayFormatted: Dayjs | null | undefined;
  handleSetStartDate: (_startDate: Dayjs | null | undefined) => void;
  handleSetEndDate: (_endDate: Dayjs | null | undefined) => void;
  hoveredDate: Dayjs | null;
  setHoveredDate: (_hoveredDate: Dayjs | null) => void;
  mode?: "day" | "week" | "month";
  disabledFeature?: boolean;
}

const DEFAULT_MONTH_FORMAT = "MMMM";
const DEFAULT_DAY_FORMAT = "D";
const DEFAULT_MONTH_ATTRIBUTE_FORMAT = "MMM";
/**

 props for a calendar component
 @typedef {Object} CalendarComponentProps
 @property {function} handleSetNewRange - Callback function to handle setting a new date range. Takes a Dayjs object, null, or undefined as a parameter and returns void.
 @property {boolean} [isSingularCalendar=false] - Optional boolean flag indicating if the calendar should only show a single date instead of a range.
 @property {'firstCalendar' | 'secondCalendar'} kind - A string literal type representing whether this is the first or second calendar component.
 @property {Dayjs} calendarDay - A Dayjs object representing the current date being displayed on the calendar.
 @property {function} setCalendarDay - Callback function to handle setting the current date being displayed on the calendar. Takes a Dayjs object as a parameter and returns void.
 @property {Dayjs|null|undefined} startDayFormatted - A Dayjs object, null, or undefined representing the start date of the current date range being displayed.
 @property {Dayjs|null|undefined} endDayFormatted - A Dayjs object, null, or undefined representing the end date of the current date range being displayed.
 @property {function} handleSetStartDate - Callback function to handle setting the start date of the date range being displayed. Takes a Dayjs object, null, or undefined as a parameter and returns void.
 @property {function} handleSetEndDate - Callback function to handle setting the end date of the date range being displayed. Takes a Dayjs object, null, or undefined as a parameter and returns void.
 @property {Dayjs|null} hoveredDate - A Dayjs object or null representing the date currently being hovered over by the user.
 @property {function} setHoveredDate - Callback function to handle setting the date currently being hovered over by the user. Takes a Dayjs object or null as a parameter and returns void.
 */
// eslint-disable-next-line react/no-multi-comp
const CalendarComponent: React.FC<CalendarComponentProps> = ({
  handleSetNewRange,
  kind,
  calendarDay,
  setCalendarDay,
  handleSetStartDate,
  handleSetEndDate,
  startDayFormatted,
  endDayFormatted,
  hoveredDate,
  setHoveredDate,
  isSingularCalendar,
  mode,
  disabledFeature,
}) => {
  const isFirstCalendar = kind === "firstCalendar";
  const isSecondCalendar = kind === "secondCalendar";
  const currentMonth = useMemo(() => {
    return calendarDay.format("MMMM");
  }, [calendarDay]);
  const currentYear = useMemo(() => {
    return calendarDay.format("YYYY");
  }, [calendarDay]);
  const endMonthDay = useMemo(() => {
    return dayjs(calendarDay).endOf("month").endOf("week");
  }, [calendarDay]);
  const startMonthDay = useMemo(() => {
    return dayjs(calendarDay).startOf("month").startOf("week");
  }, [calendarDay]);
  const calendarShowingDaysArray: Dayjs[][] = [];
  const calendarShowingMonthsArray: number[] = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
  ];
  let dateForCycle = startMonthDay.clone().subtract(1, "day");
  while (dateForCycle.isBefore(endMonthDay, "day")) {
    const week: Dayjs[] = [];
    for (let i = 0; i < 7; i++) {
      dateForCycle = dateForCycle.add(1, "day");
      week.push(dayjs(dateForCycle));
    }
    calendarShowingDaysArray.push(week);
  }

  const isMonthMode = mode === CalendarMode.MONTH;

  return (
    <Box
      className="CalendarBox"
      sx={{
        borderRight: isSingularCalendar
          ? "none"
          : isFirstCalendar
          ? `2px solid ${Colors.white1}`
          : "none",
        width: "100%",
        padding: isSingularCalendar ? "17px" : "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: !isMonthMode ? "space-between" : "center",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        {isFirstCalendar && !isMonthMode && (
          <IconButton
            className="ChangeDateIcon ChangeDateIconFirstCalendar"
            onClick={() => setCalendarDay(dayjs(calendarDay).subtract(1, "M"))}
            sx={{
              minWidth: "0px",
              svg: {
                transform: "rotate(180deg)",
              },
            }}
          >
            <ArrowIcon />
          </IconButton>
        )}
        {isSecondCalendar && <SpaceButton />}
        <Typography
          className="CalendarTitle"
          sx={{
            margin: "0",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
          }}
        >
          {currentMonth} <span className={"CalendarYear"}>{currentYear}</span>
        </Typography>
        {isFirstCalendar && !isSingularCalendar && <SpaceButton />}
        {(isSecondCalendar || isSingularCalendar) && !isMonthMode && (
          <IconButton
            className="ChangeDateIcon ChangeDateIconSecondCalendar"
            onClick={() =>
              setCalendarDay(calendarDay.add(isSingularCalendar ? 1 : 0, "M"))
            }
            sx={{
              minWidth: "0px",
            }}
          >
            <ArrowIcon />
          </IconButton>
        )}
      </Box>
      {!isMonthMode && <DateRangePickerWeekNames />}
      {isMonthMode ? (
        <Box display="flex" flexWrap="wrap">
          {calendarShowingMonthsArray.map((month) => {
            const active =
              dayjs(calendarDay).format(DEFAULT_MONTH_FORMAT) ===
              dayjs(calendarDay).month(month).format(DEFAULT_MONTH_FORMAT);
            const disabledMonth =
              disabledFeature &&
              dayjs(calendarDay).month(month).isAfter(dayjs());
            return (
              <Box
                data-month={dayjs(calendarDay)
                  .month(month)
                  .format(DEFAULT_MONTH_ATTRIBUTE_FORMAT)}
                data-month-disabled={disabledMonth}
                data-month-active={active}
                key={month}
                onClick={() => {
                  if (disabledMonth) {
                    return;
                  }
                  handleSetStartDate(dayjs(calendarDay).month(month));
                  handleSetEndDate(dayjs(calendarDay).month(month));
                  handleSetNewRange(dayjs(calendarDay).month(month));
                }}
                sx={{
                  height: "36px",
                  flex: "1 0 33.33%",
                  backgroundColor: active ? "rgb(25, 118, 210)" : "transparent",
                  margin: "8px 0",
                  borderRadius: "18px",
                  fontSize: "1rem",
                  fontWeight: 400,
                  color: active
                    ? "white"
                    : disabledMonth
                    ? "rgba(0, 0, 0, 0.6)"
                    : "unset",
                  padding: "1px 6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: !disabledMonth ? "pointer" : "default",
                }}
              >
                {dayjs().month(month).format(DEFAULT_MONTH_FORMAT)}
              </Box>
            );
          })}
        </Box>
      ) : (
        calendarShowingDaysArray.map((week, idx) => {
          const isDisabledDays = week.filter(
            (day) => disabledFeature && dayjs().isAfter(day)
          );
          const isActiveDays = week.filter((day) =>
            dayjs(calendarDay).isSame(day)
          );

          return (
            <Box
              data-week={idx + 1}
              data-week-disabled={!isDisabledDays.length}
              data-week-active={!!isActiveDays.length}
              key={idx}
              className={"swipe"}
              sx={{
                width: "calc(100% - 3px)",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                marginTop: "2px",
                gap: "0px",
              }}
            >
              {week.map((day, index) => {
                const isDayOfCurrentMonth =
                  calendarDay.format("M") === day.format("M");
                const disabledDay = disabledFeature && dayjs().isBefore(day);

                return isDayOfCurrentMonth ? (
                  <DateRangePickerDayButtonComponent
                    handleSetNewRange={handleSetNewRange}
                    hoveredDate={hoveredDate}
                    setHoveredDate={setHoveredDate}
                    startDayFormatted={startDayFormatted}
                    endDayFormatted={endDayFormatted}
                    handleSetStartDate={handleSetStartDate}
                    handleSetEndDate={handleSetEndDate}
                    key={`${dayjs(day)} ${index}`}
                    day={day}
                    disabledDay={disabledDay}
                  />
                ) : (
                  <Box sx={{ width: "40px", height: "40px" }} />
                );
              })}
            </Box>
          );
        })
      )}
    </Box>
  );
};

interface DateRangePickerDayButtonComponentType {
  day: Dayjs;
  startDayFormatted: Dayjs | null | undefined;
  endDayFormatted: Dayjs | null | undefined;
  handleSetStartDate: (_startDate: Dayjs | null | undefined) => void;
  handleSetEndDate: (_endDate: Dayjs | null | undefined) => void;
  hoveredDate: Dayjs | null;
  setHoveredDate: (_hoveredDate: Dayjs | null) => void;
  handleSetNewRange: (_startDate: Dayjs | null | undefined) => void;
  disabledDay?: boolean;
}
/**

 Interface representing props for a date range picker day button component
 @typedef {Object} DateRangePickerDayButtonComponentType
 @property {Dayjs} day - A Dayjs object representing the date displayed on the button.
 @property {Dayjs|null|undefined} startDayFormatted - A Dayjs object, null, or undefined representing the start date of the current date range being displayed.
 @property {Dayjs|null|undefined} endDayFormatted - A Dayjs object, null, or undefined representing the end date of the current date range being displayed.
 @property {function} handleSetStartDate - Callback function to handle setting the start date of the date range being displayed. Takes a Dayjs object, null, or undefined as a parameter and returns void.
 @property {function} handleSetEndDate - Callback function to handle setting the end date of the date range being displayed. Takes a Dayjs object, null, or undefined as a parameter and returns void.
 @property {Dayjs|null} hoveredDate - A Dayjs object or null representing the date currently being hovered over by the user.
 @property {function} setHoveredDate - Callback function to handle setting the date currently being hovered over by the user. Takes a Dayjs object or null as a parameter and returns void.
 @property {function} handleSetNewRange - Callback function to handle setting a new date range. Takes a Dayjs object, null, or undefined as a parameter and returns void.
 */

const DateRangePickerDayButtonComponent: React.FC<
  DateRangePickerDayButtonComponentType
  // eslint-disable-next-line react/no-multi-comp
> = ({
  day,
  startDayFormatted,
  endDayFormatted,
  handleSetStartDate,
  handleSetEndDate,
  hoveredDate,
  setHoveredDate,
  handleSetNewRange,
  disabledDay,
}) => {
  const {
    isToday,
    isCurrentDayEqualStartOrEndDate,
    isInPotentialRangeBorder,
    isInRangeDate,
    withLeftRadius,
    withRightRadius,
    withRightBorder,
    withLeftBorder,
  } = useLogicAttributesForCalendarButton(
    day,
    startDayFormatted,
    endDayFormatted,
    hoveredDate
  );
  const handleSelectDate = () => {
    if (disabledDay) return;
    const isStartDate = !!startDayFormatted;
    const isEndDate = !!endDayFormatted;
    const noStartDateOrStartDateIsAfterOrEqualSelectedDate =
      (isStartDate &&
        (day.isBefore(startDayFormatted) ||
          day.format("DD MM YYYY") ===
            startDayFormatted.format("DD MM YYYY"))) ||
      !isStartDate;

    if (isStartDate && isEndDate) {
      handleSetNewRange(day);
    } else if (noStartDateOrStartDateIsAfterOrEqualSelectedDate) {
      handleSetStartDate(day);
    } else if (!isEndDate) {
      handleSetStartDate(day);
    } else {
      handleSetEndDate(null);
      handleSetStartDate(null);
    }
  };

  return (
    <>
      <Box
        className={`
        ${isInRangeDate ? "InRangeDate" : ""} ${
          withLeftRadius ? "WithLeftRadius" : ""
        } ${withRightRadius ? "WithRightRadius" : ""} ${
          isInPotentialRangeBorder ? "InPotentialRange" : ""
        } ${withRightBorder ? "WithRightBorder" : ""} ${
          withLeftBorder ? "WithLeftBorder" : ""
        }`}
        sx={{
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          borderRadius: "0px",
          border: "2px solid transparent",
          "&.InRangeDate": {
            backgroundColor: "rgb(25, 118, 210)",
            button: {
              color: "white",
            },
          },
          "&.WithLeftRadius": {
            borderBottomLeftRadius: "50%",
            borderTopLeftRadius: "50%",
          },
          "&.WithRightRadius": {
            borderBottomRightRadius: "50%",
            borderTopRightRadius: "50%",
          },
        }}
      >
        <IconButton
          data-day={day.format(DEFAULT_DAY_FORMAT)}
          data-day-disabled={disabledDay}
          data-day-active={isInRangeDate || withLeftRadius}
          onMouseLeave={() => {
            setHoveredDate(null);
          }}
          onMouseEnter={() => {
            setHoveredDate(day);
          }}
          onClick={handleSelectDate}
          className={`${isCurrentDayEqualStartOrEndDate ? "SelectedDay" : ""} ${
            isToday ? "Today" : ""
          }`}
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: "transparent",
            minWidth: "0",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
            borderRadius: "50%",
            padding: "0",
            border: "none",
            color: disabledDay ? "rgba(0, 0, 0, 0.38)" : "rgba(0, 0, 0, 0.87)",
            cursor: disabledDay ? "default" : "pointer",
            "&:hover": {
              backgroundColor: disabledDay
                ? "transparent"
                : "rgba(0, 0, 0, 0.04)",
              border: disabledDay ? "none" : "1px solid #9e9e9e",
            },
            "&.SelectedDay": {
              width: "40px",
              height: "40px",
              color: "rgb(255, 255, 255)",
              backgroundColor: "rgb(25, 118, 210)",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "rgb(21, 101, 192)",
                border: "1px solid #9e9e9e",
              },
            },
          }}
        >
          {day.format(DEFAULT_DAY_FORMAT).toString()}
        </IconButton>
      </Box>
    </>
  );
};
/**

 A React component that renders the week day names for a date range picker using the dayjs library.
 It calculates the week day names based on the current locale and the start of the week.
 It uses the dayjs library to get the current day and format the week day names.
 The component renders a grid of seven Typography components that display the week day names.
 @returns {JSX.Element} The rendered component.
 */
// eslint-disable-next-line react/no-multi-comp
const DateRangePickerWeekNames = () => {
  function getWeekDayNames() {
    const firstDayOfWeek = dayjs().startOf("week").day();
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = dayjs().day(i + firstDayOfWeek);
      days.push(day.format("dd"));
    }
    return days;
  }

  // Example usage
  const weekDaysArray = getWeekDayNames();
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gap: "0px",
        }}
      >
        {weekDaysArray.map((weekDay, index) => {
          return (
            <Typography
              key={`${weekDay} ${index}`}
              sx={{
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 1.66,
                letterSpacing: "0.03333em",
                width: "36px",
                height: "40px",
                margin: "0 2px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {weekDay}
            </Typography>
          );
        })}
      </Box>
    </>
  );
};
const SpaceButton = styled(Box)({
  width: "32px",
});
// eslint-disable-next-line react/no-multi-comp
const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-chevron-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};
/**

 Custom React Hook to determine various visual attributes of a calendar button based on its date and context.
 @param {Dayjs} day - The date object of the button.
 @param {Dayjs | null | undefined} startDayFormatted - The start date of a selected range.
 @param {Dayjs | null | undefined} endDayFormatted - The end date of a selected range.
 @param {Dayjs | null} hoveredDate - The currently hovered date.
 @returns {{
withLeftRadius: boolean,
withRightRadius: boolean,
withRightBorder: boolean,
isInPotentialRangeBorder: boolean,
isToday: boolean,
isInRangeDate: boolean
}} An object containing various boolean attributes to set visual styles of the button.
 */
const useLogicAttributesForCalendarButton = (
  day: Dayjs,
  startDayFormatted: Dayjs | null | undefined,
  endDayFormatted: Dayjs | null | undefined,
  hoveredDate: Dayjs | null
) => {
  const isToday = useMemo(() => {
    return day.isSame(dayjs(new Date()), "date");
  }, [day]);
  const isCurrentDateInRangeOfStartAndEndDates = useMemo(() => {
    if (!startDayFormatted && !endDayFormatted) {
      return false;
    }
    return day.isBefore(endDayFormatted) && day.isAfter(startDayFormatted);
  }, [startDayFormatted, endDayFormatted, day]);
  const isCurrentDateStartOfMonth = useMemo(() => {
    return day.isSame(day.startOf("month"), "date");
  }, [day]);
  const isCurrentDateEndOfMonth = useMemo(() => {
    return day.isSame(day.endOf("month"), "date");
  }, [day]);
  const isCurrentDateStartOfWeek = useMemo(() => {
    return day.isSame(day.startOf("week"), "date");
  }, [day]);
  const isCurrentDateEndOfWeek = useMemo(() => {
    return day.isSame(day.endOf("week"), "date");
  }, [day]);
  const isCurrentDateEqualHoveredDate = useMemo(() => {
    if (!hoveredDate) {
      return false;
    }
    return day.isSame(hoveredDate, "date");
  }, [hoveredDate, day]);
  const isCurrentDayIsStartDateAndIsHoveredDayIsAfterStartDate = useMemo(() => {
    const isStartDateButNoEndDate = !!startDayFormatted && !endDayFormatted;
    if (!isStartDateButNoEndDate) {
      return false;
    }
    if (!startDayFormatted) {
      return false;
    }
    if (!hoveredDate) {
      return false;
    }
    return (
      day.isSame(startDayFormatted, "date") &&
      hoveredDate.isAfter(startDayFormatted)
    );
  }, [startDayFormatted, hoveredDate, day, endDayFormatted]);

  const isCurrentDateEqualHoveredDateAndIsAfterStartDate = useMemo(() => {
    const isStartDateButNoEndDate = !!startDayFormatted && !endDayFormatted;
    if (!isStartDateButNoEndDate) {
      return false;
    }
    if (!isCurrentDateEqualHoveredDate) {
      return false;
    }
    return day.isAfter(startDayFormatted);
  }, [day, isCurrentDateEqualHoveredDate, startDayFormatted, endDayFormatted]);
  const isCurrentDayTheSameAsStartDate = useMemo(
    () => day.isSame(startDayFormatted, "date"),
    [day, startDayFormatted]
  );
  const isCurrentDayTheSameAsEndDate = useMemo(
    () => day.isSame(endDayFormatted, "date"),
    [day, endDayFormatted]
  );
  const isCurrentDayEqualStartOrEndDate = useMemo(() => {
    return isCurrentDayTheSameAsStartDate || isCurrentDayTheSameAsEndDate;
  }, [isCurrentDayTheSameAsStartDate, isCurrentDayTheSameAsEndDate]);
  const isStartDateButNoEndDateAndDayIsBetweenHoveredAndStartDate =
    useMemo(() => {
      const isStartDateButNoEndDate = !!startDayFormatted && !endDayFormatted;
      if (!isStartDateButNoEndDate) {
        return false;
      }
      if (!hoveredDate) {
        return false;
      }
      return day.isBefore(hoveredDate) && day.isAfter(startDayFormatted);
    }, [day, hoveredDate, startDayFormatted, endDayFormatted]);
  const isInPotentialRangeBorder = useMemo(() => {
    return (
      isStartDateButNoEndDateAndDayIsBetweenHoveredAndStartDate ||
      isCurrentDayIsStartDateAndIsHoveredDayIsAfterStartDate ||
      isCurrentDateEqualHoveredDateAndIsAfterStartDate
    );
  }, [
    isStartDateButNoEndDateAndDayIsBetweenHoveredAndStartDate,
    isCurrentDayIsStartDateAndIsHoveredDayIsAfterStartDate,
    isCurrentDateEqualHoveredDateAndIsAfterStartDate,
  ]);
  const isRange = useMemo(() => {
    return !!startDayFormatted && !!endDayFormatted;
  }, [startDayFormatted, endDayFormatted]);
  const isInRangeDate = useMemo(() => {
    return (
      isCurrentDateInRangeOfStartAndEndDates ||
      ((isCurrentDayTheSameAsStartDate || isCurrentDayTheSameAsEndDate) &&
        isRange)
    );
  }, [
    isCurrentDateInRangeOfStartAndEndDates,
    isCurrentDayTheSameAsStartDate,
    isCurrentDayTheSameAsEndDate,
    isRange,
  ]);

  const withLeftRadius = useMemo(() => {
    return (
      isCurrentDayIsStartDateAndIsHoveredDayIsAfterStartDate ||
      isCurrentDateStartOfWeek ||
      isCurrentDateStartOfMonth ||
      isCurrentDayTheSameAsStartDate
    );
  }, [
    isCurrentDayIsStartDateAndIsHoveredDayIsAfterStartDate,
    isCurrentDateStartOfWeek,
    isCurrentDateStartOfMonth,
    isCurrentDayTheSameAsStartDate,
  ]);

  const withRightRadius = useMemo(() => {
    return (
      isCurrentDateEqualHoveredDateAndIsAfterStartDate ||
      isCurrentDateEndOfWeek ||
      isCurrentDateEndOfMonth ||
      isCurrentDayTheSameAsEndDate
    );
  }, [
    isCurrentDateEqualHoveredDateAndIsAfterStartDate,
    isCurrentDateEndOfWeek,
    isCurrentDateEndOfMonth,
    isCurrentDayTheSameAsEndDate,
  ]);

  const withRightBorder = useMemo(() => {
    return (
      (isCurrentDateEndOfWeek || isCurrentDateEndOfMonth) &&
      isInPotentialRangeBorder
    );
  }, [
    isCurrentDateEndOfWeek,
    isCurrentDateEndOfMonth,
    isInPotentialRangeBorder,
  ]);

  const withLeftBorder = useMemo(() => {
    return (
      (isCurrentDateStartOfWeek || isCurrentDateStartOfMonth) &&
      isInPotentialRangeBorder
    );
  }, [
    isCurrentDateStartOfWeek,
    isCurrentDateStartOfMonth,
    isInPotentialRangeBorder,
  ]);

  return {
    isToday,
    isCurrentDateInRangeOfStartAndEndDates,
    isCurrentDateStartOfMonth,
    isCurrentDateEndOfMonth,
    isCurrentDateStartOfWeek,
    isCurrentDateEndOfWeek,
    isCurrentDateEqualHoveredDate,
    isCurrentDayIsStartDateAndIsHoveredDayIsAfterStartDate,
    isCurrentDateEqualHoveredDateAndIsAfterStartDate,
    isCurrentDayTheSameAsStartDate,
    isCurrentDayTheSameAsEndDate,
    isCurrentDayEqualStartOrEndDate,
    isStartDateButNoEndDateAndDayIsBetweenHoveredAndStartDate,
    isInPotentialRangeBorder,
    isRange,
    isInRangeDate,
    withLeftRadius,
    withRightRadius,
    withRightBorder,
    withLeftBorder,
  };
};
export default DateRangePicker;
