import { Box, List, ListItem, styled } from "@mui/material";
import { Colors, ParamUSB } from "../../../system/constants";
import React from "react";
import { SourcesConsumptionType } from "../../../types/connectedStationType";

const TRANSPARENT = "transparent";
interface SourcesConsumptionRenderLegendProps {
  data?: SourcesConsumptionType[];
}

const SourcesConsumptionRenderLegend: React.FC<
  SourcesConsumptionRenderLegendProps
> = ({ data = [] }) => {
  const hasUSBWatts =
    Array.isArray(data) && data.some((item) => item?.usb_1_watts);
  const hasCarWatts =
    Array.isArray(data) && data.some((item) => item?.car_watts);
  const hasTypeCWatts =
    Array.isArray(data) && data.some((item) => item?.type_c_1_watts);

  return (
    <ConsumptionSection>
      <List>
        <ListItem
          style={{
            background: hasUSBWatts ? Colors.lightYellow : TRANSPARENT,
          }}
        >
          {ParamUSB.USB_C}
        </ListItem>
        <ListItem
          style={{
            background: hasCarWatts ? Colors.skyBlue : TRANSPARENT,
          }}
        >
          {ParamUSB.USB_F_C}
        </ListItem>
        <ListItem
          style={{
            background: hasTypeCWatts ? Colors.lightBrightGreen : TRANSPARENT,
          }}
        >
          {ParamUSB.TYPE_ONE_C}
        </ListItem>
      </List>
    </ConsumptionSection>
  );
};

const ConsumptionSection = styled(Box)({
  padding: "0 0 0 0",
  "& ul": {
    padding: "0",
    margin: "0",
    display: "flex",
  },
  "& li": {
    fontSize: "10px",
    lineHeight: "12px",
    color: Colors.gray,
    fontWeight: "normal",
    padding: "0",
    margin: "0 0 0 10px",
    height: "24px",
    width: "70px",
    justifyContent: "center",
  },
  "& li:nth-child(1)": {
    border: `1px solid ${Colors.lightYellow}`,
  },
  "& li:nth-child(2)": {
    border: `1px solid ${Colors.skyBlue}`,
  },
  "& li:nth-child(3)": {
    border: `1px solid ${Colors.lightBrightGreen}`,
  },
});

export default SourcesConsumptionRenderLegend;
