import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Curve,
  Tooltip,
} from "recharts";
import SystemLoader from "src/components/SystemLoader";
import { postApi } from "src/state/reducers/postApi";
import {
  Colors,
  DevicesIds,
  DeviceTypes,
  sensorTypeNames,
  UnitTypes,
  DEFAULT_Y_AXIS_DOMAIN_ROSE_ONE_DAY,
  DEFAULT_GRADENT_OPACITY_ROSE_ONE_DAY,
  RADAR_LINE_TYPE,
  UNKNOWN_CHART_STRING,
} from "src/system/constants";
import { RadarData } from "src/types/connectedSensorsType";
import CustomTooltip from "./CustomTooltip";
import getDayWeekRadarDataset from "src/utils/getDayWeekRadarDataset";

const ConnectedRoseOneDay = () => {
  const { data: sensorData, isLoading } = postApi.useGetRadarDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.DAY,
  });

  const radarDataset = getDayWeekRadarDataset(UnitTypes.DAY, sensorData);

  return isLoading ? (
    <SystemLoader />
  ) : (
    <ResponsiveContainer width={284} height={284}>
      <RadarChart cx="50%" cy="50%" outerRadius="100%" data={radarDataset}>
        <PolarGrid stroke={Colors.skyViolet} gridType="polygon" />
        <PolarAngleAxis
          dataKey="chartType"
          style={{
            fontSize: "14px",
          }}
        />
        <PolarRadiusAxis tick={false} axisLine={false} tickCount={6} />
        <PolarRadiusAxis
          angle={30}
          domain={DEFAULT_Y_AXIS_DOMAIN_ROSE_ONE_DAY}
        />
        <Tooltip
          content={<CustomTooltip unitType={UnitTypes.DAY} data={sensorData} />}
        />
        {radarDataset.map((radarData: RadarData) => {
          const currentChart = sensorTypeNames.find(
            ({ name }) => name === radarData.chartType
          ) ?? {
            type: UNKNOWN_CHART_STRING,
            name: UNKNOWN_CHART_STRING,
            color: Colors.black,
          };
          return (
            <Radar
              name={currentChart.type + "_radar"}
              dataKey={currentChart.type}
              stroke={currentChart.color}
              fill={currentChart.color}
              fillOpacity={DEFAULT_GRADENT_OPACITY_ROSE_ONE_DAY}
              shape={<Curve type={RADAR_LINE_TYPE} />}
            />
          );
        })}
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default ConnectedRoseOneDay;
