import React, { useState, useEffect } from "react";
import useGetStatusData from "src/store/useGetStatusData";
import { Box, Typography, styled } from "@mui/material";
import {
  Colors,
  DeviceStatus,
  SensorStatus,
  DevicesIds,
  DEFAULT_DATE_FORMAT,
} from "src/system/constants";
import { DataTypeEnum, ZoneProps } from "src/types/dashboardTypes";
import ZoneData from "./ZoneData";
import { DeviceName } from "src/system/constants";
import { postApi } from "src/state/reducers/postApi";
import { DeviceTypes, UnitTypes } from "src/system/constants";
import dayjs from "dayjs";

const Zone: React.FC<ZoneProps> = (props: ZoneProps) => {
  const { type } = props;
  const baseDate = dayjs(
    dayjs().add(1, "day").format(DEFAULT_DATE_FORMAT),
    DEFAULT_DATE_FORMAT
  );

  const { data } = postApi.useGetChartDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.DAY,
    dateStart: dayjs(baseDate).subtract(1, "day").unix(),
    dateEnd: baseDate.unix(),
  });

  const allDevices = useGetStatusData();
  const deviceStatus = allDevices.data.device?.[0]?.device_status;

  const [value, setValue] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (deviceStatus && value === undefined) {
      setValue(deviceStatus === DeviceStatus.ON);
    }
  }, [deviceStatus, value]);
  const model = allDevices.data.device?.[2]?.model;
  const typeEnergy =
    type === DataTypeEnum.ENERGY
      ? DeviceName.RENOGY_ONE_HUNDRED_WATT
      : DeviceName.AIRTHING_WAVE_PLUS;

  return (
    <HumidityZoneStyled>
      <Typography className="humidityZone">
        <TitleZone>Latest values</TitleZone>
        <Typography
          className={`humidityZone__block ${
            deviceStatus === DeviceStatus.ON
              ? SensorStatus.ACTIVE
              : SensorStatus.INACTIVE
          }`}
        >
          <Typography className="humidityZone__section">
            <Box>
              <Typography className="humidityZone__section-title">
                {props.image}
                {props.name}
              </Typography>
              <ZoneData type={props.type} data={data} value={value} />
              {!value && (
                <Typography
                  data-testid={`${props.type}Value`}
                  className="inactive__na"
                >
                  0
                </Typography>
              )}
            </Box>
          </Typography>
        </Typography>
        <Typography className="zoneEnergy">
          <Typography className="zoneEnergy__section">
            <Typography className="zoneEnergy__section-text">
              {typeEnergy}
            </Typography>
          </Typography>
        </Typography>
      </Typography>
    </HumidityZoneStyled>
  );
};

const HumidityZoneStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "266px",
  marginRight: "20px",
  height: "404px",
  marginTop: "15px",
  ".humidityZone": {
    boxShadow: `0 0 3px ${Colors.lightGray}`,
    maxWidth: "266px",
    width: "266px",
    marginRight: "20px",
    padding: "0",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "266px",
    },
  },
  ".humidityZone h2": {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "21px",
    paddingLeft: "0",
    paddingBottom: "0",
    marginBottom: "21px",
  },
  ".humidityZone__block": {
    display: "flex",
    background: "var(--main-background)",
    margin: "2px 0 0 0",
    height: "285px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      height: "116px",
    },
  },

  ".humidityZone__section": {
    width: "100%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".humidityZone__section .active": {
    color: Colors.white,
  },
  ".humidityZone__block .humidityZone__section": {
    background: Colors.lightWhite,
  },
  ".humidityZone__block.active .humidityZone__section": {
    background: "var(--main-background)",
  },
  ".humidityZone__block .humidityZone__section svg": {
    marginRight: "8px",
  },
  ".humidityZone__block .humidityZone__section svg.poluttion rect": {
    stroke: Colors.transparent,
  },
  ".humidityZone__block .humidityZone__section svg.poluttion path": {
    fill: Colors.transparent,
  },
  ".humidityZone__block .humidityZone__section svg rect": {
    stroke: Colors.silver,
  },
  ".humidityZone__block.active .humidityZone__section svg rect": {
    stroke: Colors.white,
  },
  ".humidityZone__block .humidityZone__section svg path": {
    fill: Colors.silver,
  },
  ".humidityZone__block.active .humidityZone__section svg path": {
    fill: Colors.transparent,
  },
  ".humidityZone__block.active .humidityZone__section svg path.radon": {
    fill: Colors.white,
  },
  ".humidityZone__block .humidityZone__section-title": {
    color: Colors.whiteGray,
  },
  ".humidityZone__block.active .humidityZone__section-title": {
    color: Colors.white,
  },
  ".humidityZone__block ul li": {
    color: Colors.whiteGray,
  },
  ".humidityZone__block.active ul li": {
    color: Colors.white,
  },
  ".humidityZone__block .humidityZone__section-text": {
    color: Colors.whiteGray,
  },
  ".humidityZone__block.active .humidityZone__section-text": {
    color: Colors.white,
  },
  ".humidityZone__section-title": {
    fontSize: "24px",
    lineHeight: "normal",
    color: Colors.white,
    fontWeight: 400,
    display: ["flex", "flex"],
    alignItems: "center",
    height: "24px",
    paddingLeft: "0",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  ".humidityZone__section-title img": {
    margin: "0 7px 0 0",
    padding: "0 0 0 0",
    width: "24px",
  },
  ".humidityZone__section-text": {
    fontSize: "14px",
    lineHeight: "18px",
    color: Colors.white,
    padding: "0 0 20px 10px",
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      padding: "0 0 20px 0",
    },
  },
  ".humidityZone__arrows": { flex: "0 0 59px", textAlign: "center" },
  ".humidityZone__arrows-top": {
    borderBottom: `1px solid ${Colors.white}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".humidityZone__arrows-top button": {
    background: "none",
    border: "none",
    cursor: "pointer",
    height: "96px",
    width: "100%",
    transition: "all 0.3s linear",
  },
  ".humidityZone__arrows-top button:hover": { background: "var(--bg-hover)" },
  ".humidityZone__arrows-bottom": {
    height: "96px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".humidityZone__arrows-bottom button": {
    background: "none",
    border: "none",
    cursor: "pointer",
    height: "96px",
    width: "100%",
    transition: "all 0.3s linear",
  },
  ".humidityZone__arrows-bottom button:hover": {
    background: "var(--bg-hover)",
  },
  ".zoneEnergy": {
    display: "flex",
    height: "56px",
    padding: "0 16px",
    alignItems: "center",
    ".zoneEnergy__section": {
      width: "100%",
    },
  },
  ".zoneEnergy__pic": { padding: "0 14px 0 0" },
  ".zoneEnergy__section-title": {
    fontSize: "11px",
    lineHeight: "14px",
    color: Colors.dimGray,
  },
  ".zoneEnergy__section-text": {
    fontSize: "18px",
    lineHeight: "20px",
    padding: "0",
  },
  ".zoneCheck": {
    width: "108px",
    margin: "30px 0 0 18px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ".zoneCheck p": {
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  ".zoneCheck b": {
    position: "absolute",
    top: "2px",
    left: "57px",
    fontSize: "14px",
    lineHeight: "16px",
    color: Colors.dimGray,
    fontWeight: "normal",
  },
  ".zoneCheck label": {
    width: "100%",
    height: "20px",
    borderRadius: "5px",
    display: "block",
    cursor: "pointer",
    WebkitTransition: "all 0.25s ease",
    OTransition: "all 0.25s ease",
    transition: "all 0.25s ease",
    margin: "0 0 0 0",
    background: Colors.white,
    position: "relative",
  },
  ".zoneCheck input#even": { display: "none" },
  ".zoneCheck span.del_label,\n.zoneCheck span.sam_label": {
    background: Colors.darkGray,
    color: Colors.white,
    display: "block",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "normal",
    paddingTop: "9px",
    paddingBottom: "9px",
    paddingLeft: "10px",
    borderRadius: "25px",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  ".zoneCheck span.del_label": {
    display: "none",
    paddingRight: "10px",
    paddingLeft: "0px",
    background: "var(--main-background)",
    color: Colors.white,
    textAlign: "right",
  },
  ".zoneCheck label:before": {
    content: '""',
    display: ["block", "none"],
    width: "16px",
    height: "16px",
    background: Colors.tomato,
    borderRadius: "50%",
    position: "absolute",
    marginLeft: "0",
    marginTop: "0px",
    right: "-1px",
    top: "1px",
    WebkitTransition: "all 0.25s ease",
    OTransition: "all 0.25s ease",
    transition: "all 0.25s ease",
  },
  ".zoneCheck label:after": {
    content: '""',
    display: "block",
    width: "16px",
    height: "16px",
    background: Colors.grayWhite,
    borderRadius: "50%",
    position: "absolute",
    marginLeft: "1px",
    marginTop: "0px",
    top: "1px",
    left: "1px",
    WebkitTransition: "all 0.25s ease",
    OTransition: "all 0.25s ease",
    transition: "all 0.25s ease",
  },
  ".zoneCheck input:checked+label:before": {
    right: "1px",
    background: Colors.white,
    display: "block",
    zIndex: 10,
  },
  ".zoneCheck input:checked+label:after": {
    display: ["block", "none"],
    background: Colors.white,
  },
  ".zoneCheck input:checked~label .del_label": { display: "block" },
  ".zoneCheck input:checked~label .sam_label": { display: "none" },
  "ul.pollution__zone": {
    margin: ["0", "0 auto"],
    listStyle: "none",
    textAlign: "left",
    width: "100%",
    maxWidth: ["115px", "98px"],
    padding: "0",
    marginTop: "16px",
  },
  ".humidityZone__block.active ul.pollution__zone li": { color: Colors.silver },
  "ul.pollution__zone li": {
    fontSize: "24px",
    lineHeight: "28px",
    color: Colors.white,
    marginBottom: "10px",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "2px 0 0 14px",
    whiteSpace: "nowrap",
    margin: "0 auto",
    textAlign: "center",
  },
  "ul.pollution__zone li:nth-of-type(2)": { paddingTop: "10px" },
  "ul.pollution__zone li.radon_elem": {
    display: "flex",
    justifyContent: "center",
    marginTop: "33px",
    padding: "10px 0 0 3px",
  },
  "ul.humidity__zone": { maxWidth: "125px" },
  "ul.pollution__zone li span": { fontWeight: "normal" },
  ".Pressure__text": {
    fontSize: "24px",
    lineHeight: "28px",
    color: Colors.white,
    textAlign: "center",
    fontWeight: 400,
    padding: "2px 6px 0 0",
    marginTop: "26px",
  },
  ".Pressure__text span": { display: "block" },
  ".humidityZone__block.active .Pressure__text": { color: Colors.silver },
  "ul.energy__zone": {
    paddingTop: "34px",
    paddingLeft: "0",
    margin: "0 auto",
    maxWidth: "101px",
  },
  "ul.energy__zone li": {
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "26px",
    margin: "0",
  },
  ".humidityZone .MuiSwitch-root": {
    width: "42px",
    height: "22px",
    padding: "0",
  },
  ".inactive__na": {
    color: Colors.white,
    fontWeight: 400,
    fontSize: "52px",
    lineHeight: "40px",
    marginTop: "32px",
  },
  ".humidityZone__block.active .inactive__na": { display: "block" },
  ".humidityZone__block.active ul.energy__zone,\n.humidityZone__block.active ul.pollution__zone,\n.humidityZone__block.active .Pressure__text,\n.humidityZone__block.active .humidityZone__section-interest":
    {
      display: "none",
    },
  [theme.breakpoints.down("md")]: {
    "&": {
      maxWidth: "266px",
    },
    "& .humidityZone__arrows": {
      "& svg": {
        width: "20px",
      },
    },
    ".humidityZone__section-title": {
      fontSize: "13px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "&": {
      flex: "0 0 100%",
      maxWidth: "100%",
      margin: "0",
    },
    "& .humidityZone": {
      maxWidth: "100%",
    },
  },
}));

const TitleZone = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "21px",
  padding: "25px 0 15px 0",
  display: "flex",
  textAlign: "left",
  alignItems: "center",
  paddingLeft: "16px",
}));

export default Zone;
