import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import dayjs, { Dayjs } from "dayjs";
import {
  Colors,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN,
  DEFAULT_CONNECTED_STATIONS_WATT_DOMAIN,
  EnergyStatTypes,
  EnergyTypes,
  EnergyUnitTypes,
  TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE,
  DEFAULT_DEVICE_ID,
  DEFAULT_LOWER_BOUND,
  HOUR,
  UNIT_TYPE_DAY,
  MINUTE,
  TICK_INTERVAL_THREE_HOURS,
} from "src/system/constants";
import getTicksOfInterval from "src/utils/getTicksOfInterval";
import SystemLoader from "../../../components/SystemLoader";
import CustomTooltip from "./CustomTooltipWattChart";
import NoData from "../../../components/NoData";
import {
  Datatype,
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";

const SECONDS_TO_MILISECONDS_COEFFICIENT = 1000;

const ConnectedStationsWattChart: React.FC<{
  date: Dayjs | null;
}> = ({ date }) => {
  const { data: DataWatt, isLoading } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.MaximumPowerPointTracking,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const ticks = getTicksOfInterval(
    DataWatt?.[0]?.timestamp,
    DataWatt?.[DataWatt[0] ? DataWatt.length - 1 : 0]?.timestamp,
    TICK_INTERVAL_THREE_HOURS
  );

  const dataForChart = DataWatt?.map((dataItem) => ({
    in_watts: dataItem.in_watts,
    timestamp: dataItem.timestamp,
  })).filter((dataItem) => dataItem.in_watts !== null);

  const dataCount = dataForChart?.length;

  return (
    <>
      {isLoading && <SystemLoader />}
      {dataCount === 0 && <NoData />}
      {!isLoading && dataCount !== 0 && (
        <div className="current-chart">
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart
              data={DataWatt ?? []}
              margin={{
                top: 10,
                right: 0,
                left: -2,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorWAT" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={Colors.bicycleYellow}
                    stopOpacity={0.4}
                  />
                  <stop
                    offset="95%"
                    stopColor={Colors.bicycleYellow}
                    stopOpacity={0.07}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid
                stroke={Colors.brightGray}
                vertical={true}
                strokeDasharray="3"
              />
              <XAxis
                dataKey="timestamp"
                type="number"
                domain={["dataMin", "dataMax"]}
                stroke={Colors.lightWhite}
                strokeDasharray="3"
                tickMargin={10}
                tick={{
                  fontSize: 12,
                  fontWeight: 500,
                  fill: Colors.lightBlack,
                }}
                ticks={ticks}
                tickFormatter={(value: number, index: number) =>
                  dayjs(value * SECONDS_TO_MILISECONDS_COEFFICIENT).format(
                    TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE
                  )
                }
              />
              <YAxis
                type="number"
                domain={DEFAULT_CONNECTED_STATIONS_WATT_DOMAIN}
                axisLine={false}
                tickLine={false}
                tickCount={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT}
                tickMargin={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN}
                tick={{
                  fontSize: 12,
                  fontWeight: 500,
                  fill: Colors.lightBlack,
                }}
                tickFormatter={(number) =>
                  `${number.toFixed(0)} ${EnergyUnitTypes.W}`
                }
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                name={EnergyStatTypes.PRODUCTION}
                dataKey={EnergyTypes.IN_WATTS}
                stroke={Colors.bicycleYellow}
                fillOpacity={1}
                fill="url(#colorWAT)"
                activeDot={{
                  r: 6,
                  strokeWidth: 2,
                  stroke: Colors.bicycleYellow,
                  fill: Colors.white,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default ConnectedStationsWattChart;
