import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { systemVariables } from "../../system/constants";
import {
  NotificationData,
  NotificationType,
} from "../../routes/Notifications/components/assets/types/notificationsTypes";
import { EcoflowData } from "../../types/apiTypes";

export interface DeviceData {
  device_id: string;
  datatype: string;
  lower_bound: number;
  upper_bound: number;
}

export enum Datatype {
  PowerDelivery = "PowerDelivery",
  BatteryManagementSystem = "BatteryManagementSystem",
  EnergyManagementSystem = "EnergyManagementSystem",
  MaximumPowerPointTracking = "MaximumPowerPointTracking",
  Inverter = "Inverter",
}

const TAG_TYPE_USERS = "notifications";
const apiPath = systemVariables.apiBaseUrl;

interface ProducedConsumedEnergyData {
  device_id: string;
}

interface ProducedConsumedEnergyData {
  produced_power: number;
  consumed_power: number;
}

const baseQuery = fetchBaseQuery({ baseUrl: apiPath });

const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery,
  tagTypes: [TAG_TYPE_USERS],
  endpoints: (builder) => ({
    getNotifications: builder.query<NotificationType[], null>({
      query: () => ({
        url: "/notifications",
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
    deleteNotifications: builder.mutation<
      { status: number; message: string },
      { device_id: string; timestamp: number }
    >({
      query: ({ device_id, timestamp }) => ({
        url: `/notifications?device_id=${device_id}&timestamp=${timestamp}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG_TYPE_USERS],
    }),
    updateNotifications: builder.mutation<
      null,
      { value: NotificationType; id: number }
    >({
      query: ({ id, value }) => ({
        url: `/notifications/${id}`,
        method: "PUT",
        body: value,
      }),
      invalidatesTags: [TAG_TYPE_USERS],
    }),
    getNotificationsPaginate: builder.query<NotificationData, number>({
      query: (page) => ({
        url: `/notifications/paginate?&page=${page}`,
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
    updateNotificationsReadAll: builder.mutation<null, null>({
      query: () => ({
        url: `/notifications/reed-all`,
        method: "PUT",
      }),
    }),
    getDataEcoflow: builder.query<EcoflowData[], DeviceData>({
      query: ({ device_id, datatype, lower_bound, upper_bound }) => ({
        url: `data/ecoflow?device_id=${device_id}&datatype=${datatype}&lower_bound=${lower_bound}&upper_bound=${upper_bound}`,
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
    getProducedConsumedEnergy: builder.query<
      ProducedConsumedEnergyData | null,
      { device_id: string }
    >({
      query: ({ device_id }) => ({
        url: `/data/produced-consumed-energy?device_id=${device_id}`,
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useDeleteNotificationsMutation,
  useGetNotificationsPaginateQuery,
  useGetDataEcoflowQuery,
  useUpdateNotificationsReadAllMutation,
  useGetProducedConsumedEnergyQuery,
} = notificationsApi;

export default notificationsApi;
