import { Box, List, ListItem, styled } from "@mui/material";
import EnergyInfoStat from "./EnergyInfoStat";
import {
  Colors,
  DECIMAL_PLACES,
  DEFAULT_DEVICE_ID,
  EnergyStatTypes,
  EnergyTypes,
  MINUTE,
  StatPosition,
  thousand,
  UNIT_TYPE_DAY,
} from "src/system/constants";
import {
  Datatype,
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import dayjs from "dayjs";

const EnergyInfo: React.FC<{ type: EnergyStatTypes }> = ({ type }) => {
  const { data: DataEnergy } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.MaximumPowerPointTracking,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const inVolData = DataEnergy?.[DataEnergy?.length - 1];
  const inVol = Number(
    ((inVolData?.in_vol ?? 0) / thousand).toFixed(DECIMAL_PLACES)
  );

  const inWattsData = DataEnergy?.[DataEnergy?.length - 1];
  const inWatts = Number((inWattsData?.in_watts ?? 0).toFixed(DECIMAL_PLACES));

  return (
    <EnergyInfoBlock>
      <EnergyInfoBlockTopRow>
        <EnergyInfoBlockHeader>{type}</EnergyInfoBlockHeader>
      </EnergyInfoBlockTopRow>
      <EnergyInfoBlockStats>
        {type === EnergyStatTypes.PRODUCTION && (
          <>
            <EnergyInfoStat
              type={EnergyTypes.IN_WATTS}
              position={StatPosition.COLUMN_FIRST}
            />
            <EnergyInfoStat
              type={EnergyTypes.IN_VOL}
              position={StatPosition.COLUMN_SECOND}
              strokeColor={Colors.skyYellow}
              inVolProps
            />
            <EnergyInfoBlockParameters>
              <List>
                <ListItem>Generated watts for the current day</ListItem>
                <ListItem>Voltage energy for the current day</ListItem>
              </List>
            </EnergyInfoBlockParameters>
          </>
        )}
        {type === EnergyStatTypes.CONSUMPTION && (
          <EnergyInfoStat type={EnergyTypes.WATTS_OUT_SUM} power />
        )}
      </EnergyInfoBlockStats>
    </EnergyInfoBlock>
  );
};

const EnergyInfoBlock = styled(Box)(({ theme }) => ({
  boxShadow: `0 0 3px ${Colors.lightGray}`,
  padding: "18px 0 24px 0",
  width: "48.8%",
  marginBottom: "20px",
  position: "relative",
  paddingBottom: "0",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const EnergyInfoBlockTopRow = styled(Box)(({ theme }) => ({
  padding: "0 24px 15px 24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: `1px solid ${Colors.lightWhite}`,
  "& h2": {
    fontSize: "22px",
    lineHeight: "24px",
    fontWeight: "500",
    padding: "0",
    margin: "0",
  },
}));

const EnergyInfoBlockHeader = styled(Box)(({ theme }) => ({
  fontSize: "22px",
  lineHeight: "24px",
  fontWeight: "500",
  padding: "0",
  margin: "0",
  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
    lineHeight: "22px",
    fontWeight: "500",
    padding: "0",
    margin: "0",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    lineHeight: "18px",
  },
}));

const EnergyInfoBlockStats = styled(Box)({
  width: "225px",
  margin: "21px auto 44px",
  position: "relative",
});

const EnergyInfoBlockParameters = styled(Box)(({ theme }) => ({
  padding: "0 0 0 0",
  "& ul": {
    padding: "10px 0 0 0",
    margin: "0px",
  },
  "& li": {
    padding: "0 0 5px 0",
    margin: "0px",
    fontSize: "13px",
  },
  "& li:before": {
    content: "''",
    width: "9px",
    height: "9px",
    borderRadius: "100%",
    margin: "0 5px 0 0",
  },
  "& li:nth-child(1)::before": {
    background: Colors.skyBlue,
  },
  "& li:nth-child(2)::before": {
    background: Colors.skyYellow,
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    "& li": {
      justifyContent: "center",
    },
  },
}));

export default EnergyInfo;
