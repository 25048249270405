export interface TitleProps {
  name: string;
}

export enum DataTypeKey {
  IN_WATTS = "in_watts",
  TEMPERATURE = "temp",
  IN_VOL = "in_vol",
}

export type TypeLabelProps = {
  [key in DataTypeKey]: {
    header: string;
    unit: string;
  };
};

export type SourcesConsumptionType = {
  usb_1_watts?: number;
  car_watts?: number;
  type_c_1_watts?: number;
  timestamp?: number;
};

export type lineVisibilityOptions = {
  [key: string]: boolean;
};
