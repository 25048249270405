import { Box, styled } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import { useCountUp } from "react-countup";
import { Colors } from "src/system/constants";

const Usege1Progress = (props: PropTypes) => {
  useCountUp({
    ref: props.name,
    start: 0,
    end: props.percent,
    duration: 1,
  });
  const isActive = props.percent !== 0;
  return (
    <AirQualityMonitorUsageUSBStyled propName={props.name}>
      <Typography className="progress__indent">
        <Typography className={`progress ${isActive ? "progress-true" : ""}`}>
          <Progress>
            <ProgressBarText isActive={isActive}>
              <span>{props.name}</span>
            </ProgressBarText>
            <ProgressBarSection>
              <Typography
                className={`progressBar ${
                  props.percent === 0 ? "progress-none" : ""
                }`}
                style={{ width: props.percent + "%" }}
              ></Typography>
              <WrapWolt isActive={isActive}>
                <span id={props.name}>{props.percent}</span>
                <span>W</span>
              </WrapWolt>
            </ProgressBarSection>
          </Progress>
        </Typography>
      </Typography>
    </AirQualityMonitorUsageUSBStyled>
  );
};

const AirQualityMonitorUsageUSBStyled = styled(Box)<{ propName: string }>(
  ({ propName }) => ({
    ".progress": {
      marginBottom: "17px !important",
      position: "relative",
      fontSize: "14px",
      background: Colors.white,
      boxSizing: "border-box",
      borderRadius: "6px",
      height: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    ".progress::before": {
      content: '""',
      position: "absolute",
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      backgroundColor: Colors.lightWhite,
      top: "50%",
      left: "0",
      transform: "translateY(-50%)",
    },
    "& .progress.progress-true::before": {
      backgroundColor: Colors.greenMana,
    },
    ".progress .wrap__wolt": {
      flex: "0 0 auto",
      color: Colors.dimGray,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14px",
    },
    ".progress .wrap__wolt em": { fontStyle: "initial" },
    ".progressBar": {
      width: "121px !important",
      flex: "0 1 auto",
      background: "none !important",
      display: "flex",
      alignItems: "center",
      height: ["16px", "12px"],
      transition: "width 0.6s ease-in-out",
      position: "relative",
      marginRight: "8px",
      minWidth: "30px",
    },
    ".progressBar:before": {
      content: '""',
      position: "absolute",
      top: "0px",
      left: "0px",
      height: "100%",
      background:
        "linear-gradient(270deg, #85C7DE 0%, rgba(160, 196, 226, 0.12) 109.02%)",
      borderRadius: "6px",
      width: "121px",
      animation: "animation1 1s forwards",
    },
    ".progressBar.progress-none:before": {
      background: Colors.lightWhite,
    },
    "@keyframes animation1": {
      "0%": { width: "0" },
      "100%": { width: "100%" },
    },
    ".progressBlock": { padding: "56px 25px 0 25px" },
    ".progressBarText": {
      flex: "0 1 auto",
      display: "flex",
      margin: "0 8px 0 14px",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16px",
      color: Colors.dimGray,
    },
    ".progressBarText span": {
      display: "block",
      whiteSpace: "nowrap",
      fontWeight: "500",
    },
    ".progressBarSection": {
      flex: "0 1 168px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  })
);

const Progress = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

const ProgressBarText = styled(Box)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "14px",
    color: isActive ? Colors.dimGray : Colors.silver,
    width: "50%",
    padding: "0 9px 0 14px",
    "& span": {
      display: "block",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  })
);

const ProgressBarSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const WrapWolt = styled(Box)<{ isActive: boolean }>(({ isActive }) => ({
  display: "flex",
  justifyContent: "flex-end",
  minWidth: "25px",
  fontSize: "12px",
  lineHeight: "14px",
  color: isActive ? Colors.dimGray : Colors.silver,
  fontWeight: "400",

  "& em": {
    fontStyle: "normal",
  },
}));

interface PropTypes {
  percent: number;
  name: string;
}

export default Usege1Progress;
