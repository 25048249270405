import Header from "./components/Header/Header";
import Content from "./components/Content";
import { BrowserRouter as Router } from "react-router-dom";
import { ReportingFrequencyProvider } from "./utils/reportingFrequencyContext";
import { Box, styled, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Colors } from "src/system/constants";
import { Provider } from "react-redux";
import { store } from "./state/state";
import MobileHeader from "./components/Header/MobileHeader";

const App = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Provider store={store}>
      <Main>
        <ReportingFrequencyProvider>
          <Router>
            {isDesktop ? <Header /> : <MobileHeader />}
            <Content />
          </Router>
        </ReportingFrequencyProvider>
      </Main>
    </Provider>
  );
}

const Main = styled(Box)(({ theme }) => ({
  '@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,700;1,700&family=Roboto:wght@400;500;700;900&display=swap");':
    true,
  maxWidth: "100%",
  position: "relative",
  padding: "0 15px 25px 15px",
  fontFamily: "Roboto, sans-serif",
  fontWeight: "500",
  boxSizing: "border-box",
  "--blue": Colors.black,
  "--border": `1px solid ${Colors.cloudy}`,
  "--main-background": Colors.cloudy,
  "--main-color": Colors.cloudy,
  "--main-gradient":
    "linear-gradient(\n    90deg,\n    rgba(135, 200, 222, 1) 0%,\n    rgba(135, 200, 222, 1) 51%,\n    rgba(228, 243, 248, 1) 100%\n  )",
  "--bg-hover": Colors.blueHover,
  "--bg-menu":
    "linear-gradient(\n    90deg,\n    rgba(230, 244, 247, 1) 0%,\n    rgba(244, 249, 252, 1) 51%,\n    rgba(253, 255, 254, 1) 100%\n  )",
  "--tabs-border": `2px solid ${Colors.cloudy}`,
  "--field-border": `1px solid ${Colors.cloudy}`,
  ".main-color": { color: "var(--main-color)" },
  ".border-color": { border: "var(--border)" },
  ".main-bg": { background: "var(--main-background)" },
  ".main-gradient": { background: "var(--main-gradient)" },
  ".bg-hover": { background: "var(--bg-hover)" },
  ".bg-menu": { background: "var(--bg-menu)" },
  ".tabs-border": { borderBottom: "var(--tabs-border)" },
  ".field-border": { border: "var(--field-border)" },
  body: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: "13px",
    lineHeight: "15px",
    color: Colors.black,
    background: Colors.white,
  },
  "html,body": { height: "100%" },
  a: { textDecoration: "none" },
  "*": {
    WebkitBoxSizing: "border-box",
    MozBoxSizing: "border-box",
    boxSizing: "border-box",
  },
  ".Main": {
    maxWidth: "1420px",
    position: "relative",
    padding: "0 15px 25px 15px",
    fontFamily: '"Roboto", sans-serif',
  },
  ".Main a": { textDecoration: "none" },
  h1: {
    fontSize: "24px",
    lineHeight: "28px",
    color: Colors.lightBlack,
    padding: "0 0 20px 0",
    margin: "0",
    fontWeight: 500,
  },
  h2: {
    padding: "0 0 20px 0",
    margin: "0",
    fontSize: "18px",
    lineHeight: "21px",
    color: Colors.lightBlack,
    fontWeight: 500,
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0",
  },
}));

export default App;
