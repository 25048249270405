export interface UserType {
  id: number;
  name: string;
  email: string;
}

export type lineVisibilityOptions = {
  [key: string]: boolean;
};

export enum ConnectedChartAxisYTickCount {
  DEFAULT = 20,
  HIDDEN = 1
};

export interface PairedString {
    highest: string,
    lowest: string
};

export interface MyObject {
  [key: string]: number | null;
};
