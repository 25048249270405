import { TimeSplit } from "../types/dashboardTypes";
import { MINUTES_IN_DAY, MINUTES_IN_HOUR } from "../system/constants";

export const splitMinutes = (minutes: number): TimeSplit => {
  const days = Math.floor(minutes / MINUTES_IN_DAY);
  const hours = Math.floor((minutes % MINUTES_IN_DAY) / MINUTES_IN_HOUR);
  const minutesLeft = minutes % MINUTES_IN_HOUR;

  return {
    days: days,
    hours: hours,
    minutes: minutesLeft,
  };
};
