import AirQualityMonitorUsageUSB from "./AirQualityMonitorUsageUSB";
import AirQualityMonitorUsageEnergy from "./AirQualityMonitorUsageEnergy";
import { UsageAcIcon, UsageUsbIcon } from "src/assets/icons";
import { Box, styled, Typography } from "@mui/material";
import {
  Colors,
  DEFAULT_DEVICE_ID,
  HOUR,
  HOURS_TO_SUBTRACT,
  MINUTE,
} from "src/system/constants";
import dayjs from "dayjs";
import {
  Datatype,
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";

const AirQualityMonitor = () => {
  const { data: DataUsage } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.PowerDelivery,
    lower_bound: dayjs()
      .subtract(HOURS_TO_SUBTRACT, HOUR)
      .startOf(MINUTE)
      .unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const lastDataUsage = DataUsage?.[DataUsage.length - 1] as {
    usb_1_watts?: number;
    type_c_1_watts?: number;
  };
  const usbA = (lastDataUsage?.usb_1_watts ?? 0).toFixed(0);
  const usbANumber = parseFloat(usbA);

  const usbC = (lastDataUsage?.type_c_1_watts ?? 0).toFixed(0);
  const usbCNumber = parseFloat(usbC);

  const carWatts = (
    (DataUsage?.[DataUsage.length - 1] as { car_watts?: number })?.car_watts ??
    0
  ).toFixed(0);
  const carWattsNumber = parseFloat(carWatts);

  return (
    <AirQualityMonitorBlockStyled className="device-block">
      <AirQualityMonitorBlock className="AirQualityMonitorBlock">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleUsageTypography>Usage</TitleUsageTypography>
          <Box
            sx={{
              display: "flex",
              width: "164px",
              height: "36px",
              background: Colors.grayLight,
              borderRadius: "4px",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                background: Colors.skyBlue,
                borderRadius: "4px",
                width: "72px",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UsageUsbIcon />
              </Box>
              <UsageTitlePseudoButtonsTypography
                sx={{
                  color: Colors.white,
                }}
              >
                USB
              </UsageTitlePseudoButtonsTypography>
            </Box>
            <Box
              sx={{
                background: Colors.grayLight,
                width: "72px",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UsageAcIcon />
              </Box>
              <UsageTitlePseudoButtonsTypography>
                AC
              </UsageTitlePseudoButtonsTypography>
            </Box>
          </Box>
        </Box>
        <Typography className="Usage__table">
          <Typography className="wrap__all-usb">
            <AirQualityMonitorUsageUSB
              key={`usba-${usbANumber}`}
              name="USB-A"
              percent={usbANumber}
            />
            <AirQualityMonitorUsageUSB
              key={`usbc-${usbCNumber}`}
              name="USB-C 100W"
              percent={usbCNumber}
            />
            <AirQualityMonitorUsageEnergy
              key={`watts-${carWattsNumber}`}
              name="AC 250V"
              percent={carWattsNumber}
            />
          </Typography>
        </Typography>
      </AirQualityMonitorBlock>
    </AirQualityMonitorBlockStyled>
  );
};

const UsageTitlePseudoButtonsTypography = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: Colors.gray,
});
const TitleUsageTypography = styled(Typography)({
  fontWeight: "500",
  fontSize: "18px",
  height: "18px",
  lineHeight: "unset",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const AirQualityMonitorBlockStyled = styled(Box)(({ theme }) => ({
  width: "29%",
  maxHeight: "385px",
  paddingTop: "19px !important",
  ".airQualityMonitor": {
    boxShadow: `0 0 3px ${Colors.lightGray}`,
    marginTop: "19px",
    width: "100%",
    padding: "24px 24px 34px",
    maxHeight: "385px",
  },
  ".airQualityMonitor h2": {
    marginBottom: "16px",
  },
  ".AirQualityMonitorBlock .monitor__title": {
    padding: "0",
    marginBottom: "14px",
  },
  ".monitor__title": {
    padding: "0",
    marginBottom: "16px",
  },
  ".recharts-responsive-container": { margin: "0 auto" },
  ".Usage__table": {
    marginTop: "46px",
  },
  ".Usage__table .wrap__usage-icon": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
    margin: "0 0 34px 0",
  },
  ".Usage__table .wrap__plug, .Usage__table .wrap__usb": {
    display: "flex",
    alignItems: "center",
    margin: "0",
  },
  ".Usage__table .wrap__usb": { width: "50%" },
  ".Usage__table .wrap__plug": { width: "50%" },
  ".Usage__table .wrap__svg": {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${Colors.lightWhite}`,
    backgroundColor: Colors.brightLightWhite,
    marginRight: "16px",
    position: "relative",
  },
  ".Usage__table .wrap__svg.usb::before, .Usage__table .wrap__svg.plug::before":
    {
      position: "absolute",
      content: '""',
      width: "12px",
      height: "12px",
      top: "-6px",
      right: "-6px",
      borderRadius: "50%",
      backgroundColor: Colors.highlighterRed,
      border: `2.5px solid ${Colors.lightPink}`,
    },
  ".Usage__table .wrap__usb.active .wrap__svg.usb::before, .Usage__table .wrap__plug.active .wrap__svg.plug::before":
    {
      backgroundColor: Colors.greenMana,
      borderColor: Colors.lightSalt,
    },
  ".Usage__table .wrap__svg.plug::before": {
    backgroundColor: Colors.highlighterRed,
    borderColor: Colors.lightPink,
  },
  ".Switches .Usage__table .react-tabs__tab-list": {
    display: "flex",
    justifyContent: "flex-end",
    padding: "9px 0 0 0",
    margin: "0 16px 0 0",
  },
  ".Switches .Usage__table .react-tabs__tab": {
    padding: "0 0 0 0",
    width: "40px",
    height: "40px",
    margin: "0 0 0 10px",
    border: "none !important",
    fontSize: "18px",
    lineHeight: "20px",
    color: Colors.gray,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: "0 !important",
    textAlign: "center",
    borderRadius: "0",
    background: "none",
    outline: "none",
    position: "static",
  },
  ".Switches .Usage__table .react-tabs__tab.react-tabs__tab--selected": {
    background: "var(--main-background)",
  },
  ".Switches .react-tabs__tab-panel": { padding: "38px 0 0 0" },
  ".Switches .progress": {
    marginBottom: "16px",
    position: "relative",
  },
  ".progress::before": {
    content: '""',
    position: "absolute",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: Colors.highlighterRed,
    top: "50%",
    left: "0",
    transform: "translateY(-50%)",
  },
  ".progress.progress-true::before": {
    backgroundColor: Colors.greenMana,
  },
  ".progress": {
    fontSize: "16px",
    background: Colors.white,
    boxSizing: "border-box",
    borderRadius: "6px",
    height: "16px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginBottom: "17px",
  },
  ".progress .wrap__wolt": {
    width: "25px",
    display: "flex",
    justifyContent: "flex-end",
    flex: "0 0 auto",
    color: Colors.dimGray,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
  },
  ".progress .wrap__wolt em": { fontStyle: "initial" },
  ".progressBar": {
    flex: "0 1 auto",
    background: "none !important",
    display: "flex",
    alignItems: "center",
    height: ["16px", "12px"],
    transition: "width 0.6s ease-in-out",
    position: "relative",
    marginRight: "8px",
    minWidth: "30px",
  },
  ".progressBar:before": {
    content: '""',
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "100%",
    background:
      "linear-gradient(270deg, #85C7DE 0%, rgba(160, 196, 226, 0.12) 109.02%)",
    borderRadius: "6px",
    animation: "animation1 1s forwards",
  },
  ".progressBar.progress-none:before": { background: Colors.lightWhite },
  "@keyframes animation1": { "0%": { width: "0" }, "100%": { width: "100%" } },
  ".progressBlock": { padding: "56px 25px 0 25px" },
  ".progressBarText": {
    flex: "0 1 auto",
    display: "flex",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: Colors.dimGray,
    width: "50%",
  },
  ".progressBarText span": { display: "block", whiteSpace: "nowrap" },
  ".progressBarSection": {
    flex: "0 1 168px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& .wrap__all-usb": {
    padding: "1px 0 0 0",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const AirQualityMonitorBlock = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeight: "auto",
}));

export default AirQualityMonitor;
