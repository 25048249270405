import {
  DEFAULT_RADAR_HIGHEST_VALUE,
  DEFAULT_RADAR_LOWEST_VALUE,
  LabelName,
  UnitTypes,
  sensorTypeNames,
  units,
  DECIMAL_PLACES,
} from "src/system/constants";
import { GetRadarDataItem } from "src/types/apiTypes";
import {
  HighestLowestValue,
  InternalDataType,
  InternalDataTypeRoseMonth,
} from "src/types/connectedSensorsType";
import { useMemo } from "react";
import { PairedString } from "src/types/appTypes";

type SinglePairValue = {
  highestValue: number;
  lowestValue: number;
};

type MultiplePairValue = {
  [key: string]: SinglePairValue;
};

const getLocalData = (
  data: InternalDataType,
  key: keyof HighestLowestValue
): SinglePairValue => ({
  highestValue: data?.highest_value?.[key] ?? DEFAULT_RADAR_HIGHEST_VALUE,
  lowestValue: data?.lowest_value?.[key] ?? DEFAULT_RADAR_LOWEST_VALUE,
});

const getLocalMonthlyData = (
  data: InternalDataTypeRoseMonth,
  key: keyof HighestLowestValue
): MultiplePairValue => {
  const result: MultiplePairValue = Object.keys(data)?.reduce((init, item) => {
    return {
      ...init,
      [item]: {
        lowestValue: DEFAULT_RADAR_LOWEST_VALUE,
        highestValue: DEFAULT_RADAR_HIGHEST_VALUE,
      },
    };
  }, {});
  for (const week of Object.keys(result)) {
    const lowest =
      data?.[week as keyof typeof data]?.lowest_value?.[key] ??
      DEFAULT_RADAR_LOWEST_VALUE;
    const highest =
      data?.[week as keyof typeof data]?.highest_value?.[key] ??
      DEFAULT_RADAR_HIGHEST_VALUE;
    result[week as keyof MultiplePairValue] = {
      lowestValue: lowest,
      highestValue: highest,
    };
  }

  return result;
};

const useChartNameToTypeString = (
  unitType: UnitTypes,
  data?: GetRadarDataItem,
  label?: LabelName
) => {
  return useMemo(() => {
    const returnVar: PairedString[] = [];
    if (data) {
      const paramType = sensorTypeNames.find(
        ({ name }) => name === label
      )?.type;
      if (paramType) {
        // Condition when we will display only one pair of lowest-highest values (specific for daily and weekly roses)
        if (unitType === UnitTypes.DAY || unitType === UnitTypes.WEEK) {
          // retrieving data of a specific format for daily/weekly roses, if it's present
          const dataSet = getLocalData(
            data,
            paramType as keyof HighestLowestValue
          );
          returnVar.push({
            highest: `Highest ${label}: ${dataSet.highestValue.toFixed(
              DECIMAL_PLACES
            )} ${typeof label === "string" ? units[label] : ""} `,
            lowest: `Lowest ${label}: ${dataSet.lowestValue.toFixed(
              DECIMAL_PLACES
            )} ${typeof label === "string" ? units[label] : ""}`,
          });
        } else {
          // retrieving data of a specific format for monthly rose, if it's present
          const dataSet = getLocalMonthlyData(
            data,
            paramType as keyof HighestLowestValue
          );
          // Iterate over each week in the dataSet
          for (const week of Object.keys(dataSet)) {
            // Extract highest and lowest values for the current week
            const highestValue =
              dataSet[week as keyof MultiplePairValue].highestValue.toFixed(
                DECIMAL_PLACES
              );
            const lowestValue =
              dataSet[week as keyof MultiplePairValue].lowestValue.toFixed(
                DECIMAL_PLACES
              );

            // Construct strings for highest and lowest values with appropriate units
            const highestString = `Highest ${label}: ${highestValue} ${
              typeof label === "string" ? units[label] : ""
            }`;
            const lowestString = `Lowest ${label}: ${lowestValue} ${
              typeof label === "string" ? units[label] : ""
            }`;

            // Push the constructed strings into the returnVar array
            returnVar.push({
              highest: highestString,
              lowest: lowestString,
            });
          }
        }
      }
    }
    return returnVar;
  }, [label, data, unitType]);
};

export default useChartNameToTypeString;
